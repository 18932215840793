import React, { Suspense, useLayoutEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { UserContextProvider } from "../pages/modules/user-manage/UserContext";
import { SubscriptionContextProvider } from "../pages/modules/subscription-manage/SubscriptionContext";
import { PaidTestContextProvider } from "../pages/modules/paid-test-manage/PaidTestContext";
import {PracticesContextProvider} from "../pages/modules/practices/PracticesContext";
import {DrivingContextProvider} from "../pages/modules/driving-school/DrivingContext";
import {WebpageContextProvider} from "../pages/modules/webpages/WebpageContext";
import Dashboard from "../pages/dashboard/index";
import { RedirectAs404 } from "../utils/Utils";
import UserListRegularPage from "../pages/modules/user-manage/UserListRegular";
import SubscriptionListRegular from "../pages/modules/subscription-manage/SubscriptionListRegular";
import PaidTestListRegular from "../pages/modules/paid-test-manage/PaidTestListRegular";
import ChaptersList from "../pages/modules/practices/ChaptersList";
import TopicsList from "../pages/modules/practices/TopicsList";
import QuestionLists from "../pages/modules/practices/QuestionLists";
import States from '../pages/modules/driving-school/States';
import Cities from "../pages/modules/driving-school/Cities";
import Tags from "../pages/modules/driving-school/Tags"
import ContactUs from "../pages/modules/user-manage/ContactUs";
import DrivingSchool from "../pages/modules/driving-school/DrivingSchool";
import Faqs from "../pages/modules/webpages/Faq";
import  Languages from "../pages/modules/webpages/Language";
import Plans from "../pages/modules/webpages/Plans";
import LearningChaptersList from "../pages/modules/learning/ChaptersList";
import LearningTopicsList from "../pages/modules/learning/TopicsList";
import LearningWords from "../pages/modules/learning/LearningWord";
import TestFormat from '../pages/modules/webpages/TestFormat';
import Testimonials from '../pages/modules/webpages/Testnomial';
const Pages = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Suspense fallback={<div />}>
      <Switch>
        <Route exact path={`${process.env.PUBLIC_URL}/dashboard`} component={Dashboard}></Route>
        <Route exact
          path={`${process.env.PUBLIC_URL}/users`}
          render={() => (
            <UserContextProvider>
              <UserListRegularPage />
            </UserContextProvider>
          )}
        ></Route>
     <Route exact
          path={`${process.env.PUBLIC_URL}/contact-us`}
          render={() => (
            <UserContextProvider>
              <ContactUs />
            </UserContextProvider>
          )}
        ></Route>

        <Route exact
          path={`${process.env.PUBLIC_URL}/subscriptions`}
          render={() => (
            <SubscriptionContextProvider>
              <SubscriptionListRegular />
            </SubscriptionContextProvider>
          )}
        ></Route>
        <Route exact
          path={`${process.env.PUBLIC_URL}/paid-tests`}
          render={() => (
            <PaidTestContextProvider>
              <PaidTestListRegular />
            </PaidTestContextProvider>
          )}
        ></Route>
        <Route exact
          path={`${process.env.PUBLIC_URL}/chapters`}
          render={() => (
            <PracticesContextProvider>
              <ChaptersList />
            </PracticesContextProvider>
          )}
        ></Route>
         <Route exact
          path={`${process.env.PUBLIC_URL}/topics`}
          render={() => (
            <PracticesContextProvider>
              <TopicsList />
            </PracticesContextProvider>
          )}
        ></Route>

<Route exact
          path={`${process.env.PUBLIC_URL}/questions`}
          render={() => (
            <PracticesContextProvider>
              <QuestionLists />
            </PracticesContextProvider>
          )}
        ></Route>


<Route exact
          path={`${process.env.PUBLIC_URL}/states`}
          render={() => (
            <DrivingContextProvider>
              <States />
            </DrivingContextProvider>
          )}
        ></Route>

<Route exact
          path={`${process.env.PUBLIC_URL}/cities`}
          render={() => (
            <DrivingContextProvider>
              <Cities />
            </DrivingContextProvider>
          )}
        ></Route>
        
<Route exact
          path={`${process.env.PUBLIC_URL}/tags`}
          render={() => (
            <DrivingContextProvider>
              <Tags />
            </DrivingContextProvider>
          )}
        ></Route>

<Route exact
          path={`${process.env.PUBLIC_URL}/driving-schools`}
          render={() => (
            <DrivingContextProvider>
              <DrivingSchool />
            </DrivingContextProvider>
          )}
        ></Route>

<Route exact
          path={`${process.env.PUBLIC_URL}/faqs`}
          render={() => (
            <WebpageContextProvider>
              <Faqs />
            </WebpageContextProvider>
          )}
        ></Route>
<Route exact
          path={`${process.env.PUBLIC_URL}/testimonials`}
          render={() => (
            <WebpageContextProvider>
              <Testimonials />
            </WebpageContextProvider>
          )}
        ></Route>

        <Route exact
          path={`${process.env.PUBLIC_URL}/languages`}
          render={() => (
            <WebpageContextProvider>
              <Languages />
            </WebpageContextProvider>
          )}
        ></Route>
         <Route exact
          path={`${process.env.PUBLIC_URL}/test-formats`}
          render={() => (
            <WebpageContextProvider>
              <TestFormat />
            </WebpageContextProvider>
          )}
        ></Route>
         <Route exact
          path={`${process.env.PUBLIC_URL}/plans`}
          render={() => (
            <WebpageContextProvider>
              <Plans />
            </WebpageContextProvider>
          )}
        ></Route>

<Route exact
          path={`${process.env.PUBLIC_URL}/learning-chapters`}
          render={() => (
            <PracticesContextProvider>
              <LearningChaptersList />
            </PracticesContextProvider>
          )}
        ></Route>
        <Route exact
          path={`${process.env.PUBLIC_URL}/learning-topics`}
          render={() => (
            <PracticesContextProvider>
              <LearningTopicsList />
            </PracticesContextProvider>
          )}
        ></Route>
         <Route exact
          path={`${process.env.PUBLIC_URL}/learning-words`}
          render={() => (
            <PracticesContextProvider>
              <LearningWords />
            </PracticesContextProvider>
          )}
        ></Route>
        <Route component={RedirectAs404}></Route>
      </Switch>
    </Suspense>
  );
};
export default Pages;

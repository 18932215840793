import React, { useContext, useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import toast from 'react-hot-toast';
import {
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    UncontrolledDropdown,
    Modal,
    ModalBody,
    DropdownItem,
    Form,
} from "reactstrap";
import {
    Block,
    BlockBetween,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Icon,
    Row,
    Col,
    UserAvatar,
    PaginationComponent,
    Button,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableRow,
    DataTableItem,
} from "../../../components/Component";

import { deleteMethod, getMethod, postMethod, putMethod, setAuthorizationToken } from "../../../utils/apiServices";
import moment from "moment";
import MultiSelect from 'react-multiple-select-dropdown-lite'
import 'react-multiple-select-dropdown-lite/dist/index.css'
import { MultilineInput } from 'react-input-multiline';
const Plans = () => {
    const [sm, updateSm] = useState(false)
    const [data, setData] = useState([]);
    const [totals, setTotals] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [paramsHistory, setParamsHistory] = useState({});
    const [editId, setEditedId] = useState("");
    const [languageList, setLanguageList] = useState([]);
    const [formData, setFormData] = useState({
        price: "",
        duration: "",
        mostBought: "",
        isVisible:"",
        planList: [{
            planTranslation: "",
            label: "",
            currency: "",
            language: "",
            inclusions: [],
        }, {
            planTranslation: "",
            label: "",
            currency: "",
            language: "",
            inclusions: [],
        }],
    });


    const [modal, setModal] = useState({
        edit: false,
        add: false,
    });


    const resetForm = () => {
        setEditedId("");
        setFormData({
            price: "",
            duration: "",
            mostBought: "",
            isVisible:"",
            planList: [{
                planTranslation: "",
                label: "",
                currency: "",
                language: "",
                inclusions: [],
            }, {
                planTranslation: "",
                label: "",
                currency: "",
                language: "",
                inclusions: [],
            }],
        });
    };


    const onFormCancel = () => {
        setModal({ edit: false, add: false });
        resetForm();
    };


    const paginate = async (pageNumber) => {
        setPage(pageNumber);
        var updateParams = paramsHistory;
        updateParams = Object.assign(updateParams, { page: pageNumber });
        await fetchUserList(updateParams);
    };





    const deleteUser = async (id, index) => {
        await deleteMethod('admin/plans/' + id).then((response) => {
            if (response?.success) {
                toast.success(response.message)
                data.splice(index, 1);
                setData([...data])
            } else {
                toast.error(response.message)
            }
        })
    }


    const fetchUserList = async (params) => {
        setParamsHistory(params);

        setData([]);
        await getMethod('admin/plans', params).then((response) => {
            if (response?.success) {
                setTotals(response?.total ? response?.total : 0)
                setData(response.data);
            }
        })
    }


    const onEditSubmit = async (e, submitData) => {
        e.preventDefault();
        
        var modifiedObj = submitData;
        modifiedObj = Object.assign(modifiedObj, {isVisible: modifiedObj?.isVisible === 'true'?true:modifiedObj?.isVisible})
        if (!editId) {
            toast.error("Edit Id required!");
        }
        if (modifiedObj) {
            await putMethod('admin/plans/' + editId, modifiedObj).then(async (response) => {
                if (response?.success) {
                    setModal({ edit: false, add: false });
                    resetForm();
                    toast.success(response.message);
                    await fetchUserList(paramsHistory);
                } else {
                    toast.error(response.message)
                }
            })
        } else {
            toast.error("Required Feild missing!")
        }
    };

    const onAddSubmit = async (e, submitData) => {
        e.preventDefault();

        var modifiedObj = submitData;
        modifiedObj = Object.assign(modifiedObj, {isVisible: modifiedObj?.isVisible === 'true'?true:modifiedObj?.isVisible})
        if (modifiedObj) {
            await postMethod('admin/plans', modifiedObj).then(async (response) => {
                if (response?.success) {
                    setModal({ edit: false, add: false });
                    resetForm();
                    toast.success(response.message);
                    await fetchUserList(paramsHistory);
                } else {
                    toast.error(response.message)
                }
            })
        } else {
            toast.error("Required Feild missing!")
        }
    };
    const handleUpdateDefault = (array, checkId, key) => {
        let findIndex = array?.findIndex((n) => n?.language === checkId);
        return array[findIndex]?.[key] ? array[findIndex]?.[key] : ""
    }

    const handleUpdateDefaultIndex = (array, index, key) => {
        let findIndex = array?.findIndex((n, i) => i === index);
        return array[findIndex]?.[key] ? array[findIndex]?.[key] : ""
    }

    useEffect(() => {
        setAuthorizationToken(true)
        fetchUserList({ page: page, limit: limit });

        getMethod('admin/languages').then((response) => {
            if (response?.success) {
                setLanguageList(response?.data?.length ? response?.data : [])
            }
        })
    }, [page, limit]);


    return (
        <React.Fragment>
            <Head title="States"></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                Plans
                            </BlockTitle>
                            <BlockDes className="text-soft">
                                <p>You have total {totals} plans.</p>
                            </BlockDes>
                        </BlockHeadContent>

                        <BlockHeadContent>
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <Button
                                    className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                                    onClick={() => updateSm(!sm)}
                                >
                                    <Icon name="menu-alt-r"></Icon>
                                </Button>
                                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                                    <ul className="nk-block-tools g-3">
                                        <li className="nk-block-tools-opt">
                                            <Button color="primary" className="btn-icon" onClick={() => setModal({ add: true })}>
                                                <Icon name="plus"></Icon>
                                            </Button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                <Block>
                    <DataTable className="card-stretch">

                        <DataTableBody>
                            <DataTableHead>

                                <DataTableRow size="mb" className="nk-tb-col-check">
                                    <span className="sub-text">S.No</span>
                                </DataTableRow>
                                <DataTableRow size="mb">
                                    <span className="sub-text text-left">Label</span>
                                </DataTableRow>
                                <DataTableRow size="lg">
                                    <span className="sub-text text-left">Currency</span>
                                </DataTableRow>
                                <DataTableRow size="lg">
                                    <span className="sub-text text-left">Price</span>
                                </DataTableRow>
                                
                                <DataTableRow size="lg">
                                    <span className="sub-text text-left">Visible</span>
                                </DataTableRow>
                                <DataTableRow size="lg">
                                    <span className="sub-text text-left">Duration</span>
                                </DataTableRow>
                                <DataTableRow size="md">
                                    <span className="sub-text text-center">Created Date</span>
                                </DataTableRow>

                                <DataTableRow size='mb' className="nk-tb-col-tools text-right">
                                    <span className="sub-text text-right">More</span>
                                </DataTableRow>
                            </DataTableHead>

                            {data.length > 0
                                ? data.map((item, indexm) => {
                                    return (
                                        <DataTableItem key={item?._id}>

                                            <DataTableRow size="mb" className="nk-tb-col-check">
                                                <div className="user-card">
                                                    <span className="sub-text">{indexm + 1}</span>

                                                </div>
                                            </DataTableRow>

                                            <DataTableRow size="mb" className="text-left">
                                                {
                                                    item?.translations?.length ?
                                                        item?.translations?.map((n, i) => <span key={i} className={`text-left`} >
                                                            {n?.label ? n?.label : "NA"}
                                                            <br />
                                                        </span>)
                                                        : "NA"

                                                }
                                            </DataTableRow>





                                            <DataTableRow size="lg" className="text-left">
                                                {
                                                    item?.translations?.length ?
                                                        item?.translations?.map((n, i) => <span key={i} className={`text-left`} >
                                                            {n?.currency ? n?.currency : "NA"}
                                                            <br />
                                                        </span>)
                                                        : "NA"

                                                }
                                            </DataTableRow>

                                            <DataTableRow size="lg" className="text-left">
                                                {
                                                    <span className={`text-left`}>
                                                        {item?.price ? item?.price : "0"}

                                                    </span>
                                                }
                                            </DataTableRow>
                                            
                                            <DataTableRow size="lg" className="text-left">
                                                {
                                                    <span className={`text-left`}>
                                                        {item?.isVisible ? 'YES' : "NO"}

                                                    </span>
                                                }
                                            </DataTableRow>
                                            <DataTableRow size="lg" className="text-left">
                                                {
                                                    <span className={`text-left`}>
                                                        {
                                                            [{ value: "", label: "Select" }, { value: 'week', label: "1 Week" }, { value: 'month', label: "1 month" }, { value: '3months', label: "3 months" }, { value: '6months', label: "6 months" }, { value: 'year', label: "1 Year" }]?.find((nc)=> nc?.value === item?.duration)?
                                                            [{ value: "", label: "Select" }, { value: 'week', label: "1 Week" }, { value: 'month', label: "1 month" }, { value: '3months', label: "3 months" }, { value: '6months', label: "6 months" }, { value: 'year', label: "1 Year" }]?.find((nc)=> nc?.value === item?.duration)?.label
                                                            :"NA"
                                                        }

                                                    </span>
                                                }
                                            </DataTableRow>
                                            <DataTableRow className="text-center" size="md">
                                                <span>{item.createdAt ? moment(item?.createdAt).format("YYYY-MM-DD, HH:mm A") : "NA"}</span>
                                            </DataTableRow>

                                            <DataTableRow size='mb' className="nk-tb-col-tools">
                                                <ul className="nk-tb-actions gx-1">

                                                    <li>
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                                                <Icon name="more-h"></Icon>
                                                            </DropdownToggle>
                                                            <DropdownMenu right>
                                                                <ul className="link-list-opt no-bdr">
                                                                    <li onClick={() => {
                                                                        setEditedId(item?._id ? item?._id : "");

                                                                        var updateDataMap = ({
                                                                            price: item?.price ? item?.price : 0,
                                                                            duration: item?.duration ? item?.duration : "",
                                                                            mostBought: item?.mostBought ? item?.mostBought : false,
                                                                            isVisible:item?.isVisible ? item?.isVisible : false,
                                                                            planList:
                                                                                item?.translations?.length ?
                                                                                    item?.translations?.map((n, i) => ({
                                                                                        planTranslation: n?._id ? n?._id : "",
                                                                                        label: n?.label ? n?.label : "",
                                                                                        currency: n?.currency ? n?.currency : "",
                                                                                        language: n?.language ? n?.language : "",
                                                                                        inclusions: n?.inclusions?.length ? n?.inclusions : '',
                                                                                    }))
                                                                                    :
                                                                                    [{
                                                                                        planTranslation: "",
                                                                                        label: "",
                                                                                        currency: "",
                                                                                        language: "",
                                                                                        inclusions: [],
                                                                                    }, {
                                                                                        planTranslation: "",
                                                                                        label: "",
                                                                                        currency: "",
                                                                                        language: "",
                                                                                        inclusions: [],
                                                                                    }]
                                                                        })

                                                                        setFormData(updateDataMap);
                                                                        setModal({ edit: true })
                                                                    }}>
                                                                        <DropdownItem
                                                                            tag="a"
                                                                            href="#edit"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                            }}
                                                                        >
                                                                            <Icon name="edit"></Icon>
                                                                            <span>Edit</span>
                                                                        </DropdownItem>
                                                                    </li>
                                                                    <li onClick={() => deleteUser(item?._id ? item?._id : "", indexm)}>
                                                                        <DropdownItem
                                                                            tag="a"
                                                                            href="#suspend"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                            }}
                                                                        >
                                                                            <Icon name="delete"></Icon>
                                                                            <span>Delete</span>
                                                                        </DropdownItem>
                                                                    </li>

                                                                </ul>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </li>
                                                </ul>
                                            </DataTableRow>
                                        </DataTableItem>
                                    );
                                })
                                : null}
                        </DataTableBody>
                        <div className="card-inner">
                            {totals > 0 ? (
                                <PaginationComponent
                                    itemPerPage={limit}
                                    totalItems={totals}
                                    paginate={async (n) => await paginate(n)}
                                    currentPage={page}
                                />
                            ) : (
                                <div className="text-center">
                                    <span className="text-silent">No data found</span>
                                </div>
                            )}
                        </div>
                    </DataTable>
                </Block>





                <Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
                    <ModalBody>
                        <a
                            href="#cancel"
                            onClick={(ev) => {
                                ev.preventDefault();
                                onFormCancel();
                            }}
                            className="close"
                        >
                            <Icon name="cross-sm"></Icon>
                        </a>
                        <div className="p-2">
                            <h5 className="title">Update Plan</h5>
                            <div className="mt-4">
                                <Form className="row gy-4" onSubmit={(e) => onEditSubmit(e, formData)}>

                                    <Col md="6">
                                        <FormGroup >
                                            <label className="form-label">Price</label>

                                            <input
                                                className="form-control"
                                                type='number'
                                                name="price"
                                                value={formData?.price}
                                                onChange={async (e) => {
                                                    setFormData({ ...formData, price: e.target.value });
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md="6">
                                        <FormGroup >
                                            <label className="form-label">Duration</label>

                                            <select
                                                className="form-control"

                                                name="default"
                                                value={formData?.duration}
                                                onChange={async (e) => {
                                                    setFormData({ ...formData, duration: e.target.value });
                                                }}
                                            >
                                                {
                                                    [{ value: "", label: "Select" }, { value: 'week', label: "1 Week" }, { value: 'month', label: "1 month" }, { value: '3months', label: "3 months" }, { value: '6months', label: "6 months" }, { value: 'year', label: "1 Year" }]?.map((fh, fi) => <option key={fi} value={fh.value}>{fh?.label}</option>)
                                                }
                                            </select>
                                        </FormGroup>
                                    </Col>


                                    <Col md="6">
                                        <FormGroup >
                                            <label className="form-label">Most Bought</label>

                                            <select
                                                className="form-control"

                                                name="rtl"
                                                value={formData?.mostBought}
                                                onChange={async (e) => {
                                                    setFormData({ ...formData, mostBought: e.target.value });
                                                }}
                                            >
                                                {
                                                    [{ value: "", label: "Select" }, { value: true, label: "Yes" }, { value: false, label: "No" }]?.map((fh, fi) => <option key={fi} value={fh.value}>{fh?.label}</option>)
                                                }
                                            </select>
                                        </FormGroup>
                                    </Col>


                                    <Col md="6">
                                        <FormGroup >
                                            <label className="form-label">Is Visible</label>

                                            <select
                                                className="form-control"

                                                name="rtl"
                                                value={formData?.isVisible}
                                                onChange={async (e) => {
                                                    setFormData({ ...formData, isVisible: e.target.value });
                                                }}
                                            >
                                                {
                                                    [{ value: "", label: "Select" }, { value: true, label: "Yes" }, { value: false, label: "No" }]?.map((fh, fi) => <option key={fi} value={fh.value}>{fh?.label}</option>)
                                                }
                                            </select>
                                        </FormGroup>
                                    </Col>

                                    <Col md="12">
                                        {
                                            languageList?.length ?
                                                languageList?.map((listLang, i) =>
                                                    <div className="row justify-content-center" key={listLang?._id} style={{ margin: "15px 0px" }}>
                                                        <legend style={{ fontSize: '18px', fontWeight: '600' }}>{(i + 1)}. {listLang?.name ? listLang?.name : ""}</legend>

                                                        <div className="col-md-6 col-12">
                                                            <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                                                <label className="form-label">Label</label>
                                                                <textarea
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="name"
                                                                    required
                                                                    value={handleUpdateDefault(formData?.planList, listLang?._id, "label")}
                                                                    onChange={(e) =>
                                                                        setFormData({
                                                                            ...formData,
                                                                            planList: formData?.planList?.map((dataUpdate) =>
                                                                                dataUpdate?.language === listLang?._id ?
                                                                                    ({ ...dataUpdate, label: e.target.value })
                                                                                    : dataUpdate
                                                                            )
                                                                        })
                                                                    }
                                                                    placeholder={"Write..."}

                                                                ></textarea>

                                                            </FormGroup>
                                                        </div>
                                                        <div className="col-md-6 col-12">
                                                            <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                                                <label className="form-label">Currency</label>
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="currency"
                                                                    required
                                                                    value={handleUpdateDefault(formData?.planList, listLang?._id, "currency")}
                                                                    onChange={(e) =>
                                                                        setFormData({
                                                                            ...formData,
                                                                            planList: formData?.planList?.map((dataUpdate) =>
                                                                                dataUpdate?.language === listLang?._id ?
                                                                                    ({ ...dataUpdate, currency: e.target.value })
                                                                                    : dataUpdate
                                                                            )
                                                                        })
                                                                    }
                                                                    placeholder={"Write..."}

                                                                />
                                                            </FormGroup>
                                                        </div>
                                                        <div className="col-md-6 col-12">
                                                            <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                                                <div style={{flexDirection:'column',display:'flex'}}>
                                                                <label className="form-label">Inclusions</label>

                                                                <span onClick={(e) => {
                                                                    setFormData({
                                                                        ...formData,
                                                                        planList: formData?.planList?.map((dataUpdate) =>
                                                                            dataUpdate?.language === listLang?._id ?
                                                                                ({ ...dataUpdate, inclusions: [...(dataUpdate?.inclusions), ""] })
                                                                                : dataUpdate
                                                                        )
                                                                    });
                                                                }} style={{ cursor: 'pointer', width: '100%', padding: '10px 15px',textAlign:'center', border: 'solid #ececec 1px', background: "#ececec", color: "red" }}>+Add</span>
                                                                </div>
                                                                {
                                                                    handleUpdateDefault(formData?.planList, listLang?._id, "inclusions")?.length ?
                                                                        handleUpdateDefault(formData?.planList, listLang?._id, "inclusions")?.map((opt, oi) =>
                                                                            <div className="row justify-content-between" key={oi} style={{ alignItems: 'center', margin: "15px 0px", width: '100%' }}>
                                                                                <div className="col-md-10 col-10">
                                                                                    <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>

                                                                                        {
                                                                                            <input
                                                                                                className="form-control"
                                                                                                name="label"
                                                                                                value={opt ? opt : ""} //formData?.practiceList?.length?  ((formData?.practiceList)?.find((n) => n?.language === listLang?._id))?.name ? ((formData?.practiceList)?.find((n) => n?.language === listLang?._id))?.name : "" : ""}
                                                                                                onChange={(e) => {
                                                                                                    var updateOptionsTrash = handleUpdateDefault(formData?.planList, listLang?._id, "inclusions")?.length ? handleUpdateDefault(formData?.planList, listLang?._id, "inclusions") : []
                                                                                                    const updateOptionsVAlue = [...updateOptionsTrash];
                                                                                                    updateOptionsVAlue[oi] = e.target.value
                                                                                                    setFormData({
                                                                                                        ...formData,
                                                                                                        planList: formData?.planList?.map((dataUpdate) =>
                                                                                                            dataUpdate?.language === listLang?._id ?
                                                                                                                ({ ...dataUpdate, inclusions: updateOptionsVAlue })
                                                                                                                : dataUpdate
                                                                                                        )
                                                                                                    });
                                                                                                }}
                                                                                            />
                                                                                        }

                                                                                    </FormGroup>
                                                                                </div>

                                                                                <div className="col-md-2 col-2" style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                                    <FormGroup style={{ width: '100%', padding: '5px', margin: '0px', padding: '0px', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                                        <span
                                                                                            onClick={(e) => {
                                                                                                var updateOptionsTrash = handleUpdateDefault(formData?.planList, listLang?._id, "inclusions")?.length ? handleUpdateDefault(formData?.planList, listLang?._id, "inclusions") : []
                                                                                                delete updateOptionsTrash[oi];
                                                                                                setFormData({
                                                                                                    ...formData,
                                                                                                    planList: formData?.planList?.map((dataUpdate) =>
                                                                                                        dataUpdate?.language === listLang?._id ?
                                                                                                            ({ ...dataUpdate, inclusions: updateOptionsTrash?.filter((n) => n) })
                                                                                                            : dataUpdate
                                                                                                    )
                                                                                                });
                                                                                            }}

                                                                                            style={{ padding: '10px', border: 'solid #ececec 1px', background: "#ececec", color: "red", cursor: 'pointer' }}><Icon name="trash"></Icon></span>
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                        : null
                                                                }


                                                            </FormGroup>
                                                        </div>
                                                        <div className="col-md-6 col-12">
                                                            <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                                                <label className="form-label">Language</label>
                                                                {
                                                                    <select
                                                                        className="form-control"
                                                                        name="language"
                                                                        required
                                                                        
                                                                        value={handleUpdateDefault(formData?.planList, listLang?._id, "language")}
                                                                        onChange={(e) =>
                                                                            setFormData({
                                                                                ...formData,
                                                                                planList: formData?.planList?.map((dataUpdate) =>
                                                                                    dataUpdate?.language === listLang?._id ?
                                                                                        ({ ...dataUpdate, language: e.target.value })
                                                                                        : dataUpdate
                                                                                )
                                                                            })
                                                                        }
                                                                    >
                                                                        <option value="">Select Language</option>
                                                                        {
                                                                            languageList?.map((n, it) => <option key={it} value={n?._id}>{n?.name ? n?.name : ""}</option>)
                                                                        }
                                                                    </select>
                                                                }
                                                                {/* {errors?.language[i] && <span className="invalid">{errors?.language[i]?.message}</span>} */}
                                                            </FormGroup>
                                                        </div>

                                                    </div>
                                                )
                                                : null
                                        }
                                    </Col>







                                    <Col size="12">
                                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                            <li>
                                                <Button color="primary" size="md" type="submit">
                                                    Update
                                                </Button>
                                            </li>
                                            <li>
                                                <a
                                                    href="#cancel"
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        onFormCancel();
                                                    }}
                                                    className="link link-light"
                                                >
                                                    Cancel
                                                </a>
                                            </li>
                                        </ul>
                                    </Col>
                                </Form>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>



                <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">

                    <ModalBody>
                        <a
                            href="#cancel"
                            onClick={(ev) => {
                                ev.preventDefault();
                                onFormCancel();
                            }}
                            className="close"
                        >
                            <Icon name="cross-sm"></Icon>
                        </a>
                        <div className="p-2">
                            <h5 className="title">Create Plan</h5>
                            <div className="mt-4">
                                <Form className="row gy-4" onSubmit={(e) => onAddSubmit(e, formData)}>

                                    <Col md="6">
                                        <FormGroup >
                                            <label className="form-label">Price</label>

                                            <input
                                                className="form-control"
                                                type='number'
                                                name="price"
                                                value={formData?.price}
                                                onChange={async (e) => {
                                                    setFormData({ ...formData, price: e.target.value });
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md="6">
                                        <FormGroup >
                                            <label className="form-label">Duration</label>

                                            <select
                                                className="form-control"

                                                name="default"
                                                value={formData?.duration}
                                                onChange={async (e) => {
                                                    setFormData({ ...formData, duration: e.target.value });
                                                }}
                                            >
                                                {
                                                    [{ value: "", label: "Select" }, { value: 'week', label: "1 Week" }, { value: 'month', label: "1 month" }, { value: '3months', label: "3 months" }, { value: '6months', label: "6 months" }, { value: 'year', label: "1 Year" }]?.map((fh, fi) => <option key={fi} value={fh.value}>{fh?.label}</option>)
                                                }
                                            </select>
                                        </FormGroup>
                                    </Col>


                                    <Col md="6">
                                        <FormGroup >
                                            <label className="form-label">Most Bought</label>

                                            <select
                                                className="form-control"

                                                name="rtl"
                                                value={formData?.mostBought}
                                                onChange={async (e) => {
                                                    setFormData({ ...formData, mostBought: e.target.value });
                                                }}
                                            >
                                                {
                                                    [{ value: "", label: "Select" }, { value: true, label: "Yes" }, { value: false, label: "No" }]?.map((fh, fi) => <option key={fi} value={fh.value}>{fh?.label}</option>)
                                                }
                                            </select>
                                        </FormGroup>
                                    </Col>

                                    <Col md="12">
                                        {
                                            languageList?.length ?
                                                languageList?.map((listLang, i) =>
                                                    <div className="row justify-content-center" key={listLang?._id} style={{ margin: "15px 0px" }}>
                                                        <legend style={{ fontSize: '18px', fontWeight: '600' }}>{(i + 1)}. {listLang?.name ? listLang?.name : ""}</legend>

                                                        <div className="col-md-6 col-12">
                                                            <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                                                <label className="form-label">Label</label>
                                                                <textarea
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="name"
                                                                    required
                                                                    value={handleUpdateDefaultIndex(formData?.planList, i, "label")}
                                                                    onChange={(e) =>
                                                                        setFormData({
                                                                            ...formData,
                                                                            planList: formData?.planList?.map((dataUpdate, sid) =>
                                                                                sid === i ?
                                                                                    ({ ...dataUpdate, label: e.target.value })
                                                                                    : dataUpdate
                                                                            )
                                                                        })
                                                                    }
                                                                    placeholder={"Write..."}

                                                                ></textarea>

                                                            </FormGroup>
                                                        </div>
                                                        <div className="col-md-6 col-12">
                                                            <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                                                <label className="form-label">Currency</label>
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="currency"
                                                                    required
                                                                    value={handleUpdateDefaultIndex(formData?.planList, i, "currency")}
                                                                    onChange={(e) =>
                                                                        setFormData({
                                                                            ...formData,
                                                                            planList: formData?.planList?.map((dataUpdate, sid) =>
                                                                                sid === i ?
                                                                                    ({ ...dataUpdate, currency: e.target.value })
                                                                                    : dataUpdate
                                                                            )
                                                                        })
                                                                    }
                                                                    placeholder={"Write..."}

                                                                />
                                                            </FormGroup>
                                                        </div>
                                                        <div className="col-md-6 col-12">
                                                            <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                                                <div style={{flexDirection:'column',display:'flex'}}>
                                                                <label className="form-label">Inclusions</label>

                                                                <span onClick={(e) => {
                                                                    setFormData({
                                                                        ...formData,
                                                                        planList: formData?.planList?.map((dataUpdate, sid) =>
                                                                            sid === i?
                                                                                ({ ...dataUpdate, inclusions: [...(dataUpdate?.inclusions), ""] })
                                                                                : dataUpdate
                                                                        )
                                                                    });
                                                                }} style={{ cursor: 'pointer', width: '100%', padding: '10px 15px',textAlign:'center', border: 'solid #ececec 1px', background: "#ececec", color: "red" }}>+Add</span>
                                                                </div>
                                                                {
                                                                    handleUpdateDefaultIndex(formData?.planList, i, "inclusions")?.length ?
                                                                        handleUpdateDefaultIndex(formData?.planList, i, "inclusions")?.map((opt, oi) =>
                                                                            <div className="row justify-content-between" key={oi} style={{ alignItems: 'center', margin: "15px 0px", width: '100%' }}>
                                                                                <div className="col-md-10 col-10">
                                                                                    <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>

                                                                                        {
                                                                                            <input
                                                                                                className="form-control"
                                                                                                name="label"
                                                                                                value={opt ? opt : ""} //formData?.practiceList?.length?  ((formData?.practiceList)?.find((n) => n?.language === listLang?._id))?.name ? ((formData?.practiceList)?.find((n) => n?.language === listLang?._id))?.name : "" : ""}
                                                                                                onChange={(e) => {
                                                                                                    var updateOptionsTrash = handleUpdateDefaultIndex(formData?.planList, i, "inclusions")?.length ? handleUpdateDefaultIndex(formData?.planList, i, "inclusions") : []
                                                                                                    const updateOptionsVAlue = [...updateOptionsTrash];
                                                                                                    updateOptionsVAlue[oi] = e.target.value
                                                                                                    setFormData({
                                                                                                        ...formData,
                                                                                                        planList: formData?.planList?.map((dataUpdate, sid) =>
                                                                                                        sid === i?
                                                                                                                ({ ...dataUpdate, inclusions: updateOptionsVAlue })
                                                                                                                : dataUpdate
                                                                                                        )
                                                                                                    });
                                                                                                }}
                                                                                            />
                                                                                        }

                                                                                    </FormGroup>
                                                                                </div>

                                                                                <div className="col-md-2 col-2" style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                                    <FormGroup style={{ width: '100%', padding: '5px', margin: '0px', padding: '0px', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                                        <span
                                                                                            onClick={(e) => {
                                                                                                var updateOptionsTrash = handleUpdateDefaultIndex(formData?.planList, i, "inclusions")?.length ? handleUpdateDefaultIndex(formData?.planList, i, "inclusions") : []
                                                                                                delete updateOptionsTrash[oi];
                                                                                                setFormData({
                                                                                                    ...formData,
                                                                                                    planList: formData?.planList?.map((dataUpdate, sid) =>
                                                                                                    sid === i?
                                                                                                            ({ ...dataUpdate, inclusions: updateOptionsTrash?.filter((n) => n) })
                                                                                                            : dataUpdate
                                                                                                    )
                                                                                                });
                                                                                            }}

                                                                                            style={{ padding: '10px', border: 'solid #ececec 1px', background: "#ececec", color: "red", cursor: 'pointer' }}><Icon name="trash"></Icon></span>
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                        : null
                                                                }


                                                            </FormGroup>
                                                        </div>
                                                        <div className="col-md-6 col-12">
                                                            <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                                                <label className="form-label">Language</label>
                                                                {
                                                                    <select
                                                                        className="form-control"
                                                                        name="language"
                                                                        required
                                                                       
                                                                        value={handleUpdateDefaultIndex(formData?.planList, i, "language")}
                                                                        onChange={(e) =>
                                                                            setFormData({
                                                                                ...formData,
                                                                                planList: formData?.planList?.map((dataUpdate, sid) =>
                                                                                    sid === i ?
                                                                                        ({ ...dataUpdate, language: e.target.value })
                                                                                        : dataUpdate
                                                                                )
                                                                            })
                                                                        }
                                                                    >
                                                                        <option value="">Select Language</option>
                                                                        {
                                                                            languageList?.map((n, it) => <option key={it} value={n?._id}>{n?.name ? n?.name : ""}</option>)
                                                                        }
                                                                    </select>
                                                                }
                                                                {/* {errors?.language[i] && <span className="invalid">{errors?.language[i]?.message}</span>} */}
                                                            </FormGroup>
                                                        </div>

                                                    </div>
                                                )
                                                : null
                                        }
                                    </Col>
                                    <Col size="12">
                                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                            <li>
                                                <Button color="primary" size="md" type="submit">
                                                    Create
                                                </Button>
                                            </li>
                                            <li>
                                                <a
                                                    href="#cancel"
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        onFormCancel();
                                                    }}
                                                    className="link link-light"
                                                >
                                                    Cancel
                                                </a>
                                            </li>
                                        </ul>
                                    </Col>
                                </Form>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </Content>
        </React.Fragment>
    );
};
export default Plans;

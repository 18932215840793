import React, { useContext, useEffect, useRef, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import toast from 'react-hot-toast';
import {
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  DropdownItem,
  Form,
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  UserAvatar,
  PaginationComponent,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
} from "../../../components/Component";

import { deleteMethod, getMethod, postMethod, putMethod, setAuthorizationToken } from "../../../utils/apiServices";
import moment from "moment";
import { uploadDataAWS } from "../../../utils/action";

const Testnomial = () => {
  const [sm, updateSm] = useState(false)
  const [data, setData] = useState([]);
  const [totals, setTotals] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [paramsHistory, setParamsHistory] = useState({});
  const [editId, setEditedId] = useState("");
  const [languageList, setLanguageList] = useState([]);
  const imageRef1 = useRef();

  const [formData, setFormData] = useState({
    title: '',
    description: '',
    name: '',
    designation: '',
    image: '',
    language: ''
  });


  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });


  const resetForm = () => {
    setEditedId("");
    setFormData({
      title: '',
      description: '',
      name: '',
      designation: '',
      image: '',
      language: ''
    });
    if (imageRef1.current) {
      imageRef1.current.value = "";
    }
  };


  const onFormCancel = () => {
    setModal({ edit: false, add: false });
    resetForm();
  };


  const paginate = async (pageNumber) => {
    setPage(pageNumber);
    var updateParams = paramsHistory;
    updateParams = Object.assign(updateParams, { page: pageNumber });
    await fetchUserList(updateParams);
  };





  const deleteUser = async (id, index) => {
    await deleteMethod('admin/testnomial/' + id + '/delete').then((response) => {
      if (response?.success) {
        toast.success(response.message)
        data.splice(index, 1);
        setData([...data])
      } else {
        toast.error(response.message)
      }
    })
  }


  const fetchUserList = async (params) => {
    setParamsHistory(params);

    setData([]);
    await getMethod('admin/testnomial/list', params).then((response) => {
      if (response?.success) {
        setTotals(response?.total ? response?.total : 0)
        setData(response.data);
      }
    })
  }


  const onEditSubmit = async (e, submitData) => {
    e.preventDefault();
    if (!editId) {
      toast.error("Edit Id required!");
    }
    if (submitData) {
      await putMethod('admin/testnomial/' + editId + '/edit', submitData).then(async (response) => {
        if (response?.success) {
          setModal({ edit: false, add: false });
          resetForm();
          toast.success(response.message);
          await fetchUserList(paramsHistory);
        } else {
          toast.error(response.message)
        }
      })
    } else {
      toast.error("Required Feild missing!")
    }
  };

  const onAddSubmit = async (e, submitData) => {
    e.preventDefault();
    if (submitData) {
      await postMethod('admin/testnomial/add', submitData).then(async (response) => {
        if (response?.success) {
          setModal({ edit: false, add: false });
          resetForm();
          toast.success(response.message);
          await fetchUserList(paramsHistory);
        } else {
          toast.error(response.message)
        }
      })
    } else {
      toast.error("Required Feild missing!")
    }
  };

  useEffect(() => {
    setAuthorizationToken(true)
    fetchUserList({ page: page, limit: limit });
    getMethod('admin/languages').then((response) => {
      if (response?.success) {
        setLanguageList(response?.data?.length ? response?.data : [])
      }
    })
  }, [page, limit]);


  return (
    <React.Fragment>
      <Head title="States"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Testimonials
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {totals} testimonials.</p>
              </BlockDes>
            </BlockHeadContent>

            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <Button color="primary" className="btn-icon" onClick={() => setModal({ add: true })}>
                        <Icon name="plus"></Icon>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <DataTable className="card-stretch">

            <DataTableBody>
              <DataTableHead>

                <DataTableRow size="mb" className="nk-tb-col-check">
                  <span className="sub-text">Avatar</span>
                </DataTableRow>
                <DataTableRow size="mb">
                  <span className="sub-text text-left">Title</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text text-left">Description</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text text-left">Name</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text text-left">Language</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text text-center">Created Date</span>
                </DataTableRow>

                <DataTableRow size='mb' className="nk-tb-col-tools text-right">
                  <span className="sub-text text-right">More</span>
                </DataTableRow>
              </DataTableHead>

              {data.length > 0
                ? data.map((item, indexm) => {
                  return (
                    <DataTableItem key={item?._id}>

                      <DataTableRow size="mb" className="nk-tb-col-check">
                        <div className="user-card">
                          {
                            item?.image ?
                              <UserAvatar size="md" theme="light" text={''} image={process.env.REACT_APP_IMG_URL + item?.image}></UserAvatar>

                              : <span className="sub-text">NA</span>
                          }
                        </div>
                      </DataTableRow>

                      <DataTableRow size="mb" className="text-left">
                        {
                          item?.title ?
                            <span className={`text-left`}>
                              {item?.title ? item?.title : "NA"}
                              <br />
                            </span>
                            : null
                        }

                      </DataTableRow>





                      <DataTableRow size="lg" className="text-left">
                        {
                          item?.description ?
                            <span className={`text-left`}>
                              {item?.description ? item?.description : "NA"}
                              <br />
                            </span>
                            : null
                        }
                      </DataTableRow>


                      <DataTableRow size="lg" className="text-left">
                        {
                          item?.name ?
                            <span className={`text-left`}>
                              {item?.name ? item?.name : "NA"}{item?.designation?`(${item?.designation})`:""}
                              <br />
                            </span>
                            : null
                        }
                      </DataTableRow>
                      <DataTableRow size="lg" className="text-left">
                        {
                          item?.language?._id ?
                            <span className={`text-left`}>
                              {item?.language?.name ? item?.language?.name : "NA"}
                              <br />
                            </span>
                            : null
                        }
                      </DataTableRow>
                      <DataTableRow className="text-center" size="md">
                        <span>{item.createdAt ? moment(item?.createdAt).format("YYYY-MM-DD, HH:mm A") : "NA"}</span>
                      </DataTableRow>

                      <DataTableRow size='mb' className="nk-tb-col-tools">
                        <ul className="nk-tb-actions gx-1">

                          <li>
                            <UncontrolledDropdown>
                              <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                <Icon name="more-h"></Icon>
                              </DropdownToggle>
                              <DropdownMenu right>
                                <ul className="link-list-opt no-bdr">
                                  <li onClick={() => {
                                    setEditedId(item?._id ? item?._id : "");

                                    var updateDataMap = (item?._id ? {
                                      title: item?.title ? item?.title : '',
                                      description: item?.description ? item?.description : '',
                                      name: item?.name ? item?.name : '',
                                      designation: item?.designation ? item?.designation : '',
                                      image: item?.image ? item?.image : '',
                                      language: item?.language?._id ? item?.language?._id : ''
                                    } : {
                                      title: '',
                                      description: '',
                                      name: '',
                                      designation: '',
                                      image: '',
                                      language: ''
                                    })

                                    setFormData(updateDataMap);
                                    setModal({ edit: true })
                                  }}>
                                    <DropdownItem
                                      tag="a"
                                      href="#edit"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      <Icon name="edit"></Icon>
                                      <span>Edit</span>
                                    </DropdownItem>
                                  </li>
                                  <li onClick={() => deleteUser(item?._id ? item?._id : "", indexm)}>
                                    <DropdownItem
                                      tag="a"
                                      href="#suspend"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      <Icon name="delete"></Icon>
                                      <span>Delete</span>
                                    </DropdownItem>
                                  </li>

                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </li>
                        </ul>
                      </DataTableRow>
                    </DataTableItem>
                  );
                })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {totals > 0 ? (
                <PaginationComponent
                  itemPerPage={limit}
                  totalItems={totals}
                  paginate={async (n) => await paginate(n)}
                  currentPage={page}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>





        <Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Update Testimonials</h5>
              <div className="mt-4">
                <Form className="row gy-4" onSubmit={(e) => onEditSubmit(e, formData)}>

                  <Col md="12">
                    <div className="row justify-content-center" style={{ margin: "15px 0px" }}>
                      <div className="col-md-6 col-12">
                        <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                          <label className="form-label">Title</label>
                          <input
                            className="form-control"
                            type="text"
                            name="name"
                            required
                            value={formData?.title ? formData?.title : ''}
                            onChange={(e) =>
                              setFormData((n) => ({ ...n, title: e.target.value }))
                            }
                            placeholder={"Title"}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-6 col-12">
                        <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                          <label className="form-label">Description</label>
                          <textarea
                            className="form-control"
                            type="text"
                            name="description"
                            required
                            value={formData?.description ? formData?.description : ''}
                            onChange={(e) =>
                              setFormData((n) => ({ ...n, description: e.target.value }))
                            }
                            placeholder={"Write..."}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-6 col-12">
                        <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                          <label className="form-label">Full Name</label>
                          <input
                            className="form-control"
                            type="text"
                            name="name"
                            required
                            value={formData?.name ? formData?.name : ''}
                            onChange={(e) =>
                              setFormData((n) => ({ ...n, name: e.target.value }))
                            }
                            placeholder={"Full Name"}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-6 col-12">
                        <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                          <label className="form-label">Designation</label>
                          <input
                            className="form-control"
                            type="text"
                            name="designation"
                            required
                            value={formData?.designation ? formData?.designation : ''}
                            onChange={(e) =>
                              setFormData((n) => ({ ...n, designation: e.target.value }))
                            }
                            placeholder={"Designation"}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-6 col-12">
                        <FormGroup style={{ width: '100%', padding: '5px', margin: '0px', border: 'solid #ececec 1px',background:'#ececec',position:'relative'}}>
                          <label className="form-label">Image</label>
                          {
                            formData?.image ?
                              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '15px' }}>
                                <img src={process.env.REACT_APP_IMG_URL + formData?.image} className="img-fluid" width={100} height={100} />
                                <button title='Delete' style={{ border: 'solid #df4b0e 1px', width: '45px', height: '45px', position: 'absolute', borderRadius: '100%', background: '#df4b0e', color: '#fff',right:'5px',top:'5px'}} onClick={(e) => {
                                  if (imageRef1?.current) {
                                    imageRef1.current.value = "";
                                  }
                                  setFormData((n) => ({ ...n, image: '' }))
                                }}>
                                  <Icon name="trash"></Icon>
                                </button>
                              </div>

                              : null
                          }
                          <br />
                          <input
                            className="form-control"
                            type="file"
                            name="icon"
                            ref={imageRef1}
                            onChange={async (e) => {

                              let chooseFile = e?.target?.files[0] ? e?.target?.files[0] : false;
                              if (!chooseFile) {
                                toast.error("Please choose file!")
                              }
                              const formDataUpload = new FormData();
                              formDataUpload.append("file", chooseFile);
                              await uploadDataAWS(formDataUpload).then((response) => {
                                if (response?.success) {
                                  setFormData((n) => ({ ...n, image: response?.path }))
                                  if (imageRef1.current) {
                                    imageRef1.current.value = "";
                                  }
                                } else {
                                  if (imageRef1.current) {
                                    imageRef1.current.value = "";
                                  }
                                  toast.error(response?.message);
                                }

                              })
                            }}
                          />

                        </FormGroup>
                      </div>
                      <div className="col-md-6 col-12">
                        <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                          <label className="form-label">Language</label>
                          {
                            <select
                              className="form-control"
                              name="language"
                              required

                              value={formData?.language ? formData?.language : ''}
                              onChange={(e) =>
                                setFormData((n) => ({ ...n, language: e.target.value }))
                              }
                            >
                              <option value="">Select Language</option>
                              {
                                languageList?.map((n, it) => <option key={it} value={n?._id}>{n?.name ? n?.name : ""}</option>)
                              }
                            </select>
                          }

                        </FormGroup>
                      </div>
                    </div>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                          Update
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">

          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Create Testimonials</h5>
              <div className="mt-4">
                <Form className="row gy-4" onSubmit={(e) => onAddSubmit(e, formData)}>


                  <Col md="12">
                    <div className="row justify-content-center" style={{ margin: "15px 0px" }}>
                      <div className="col-md-6 col-12">
                        <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                          <label className="form-label">Title</label>
                          <input
                            className="form-control"
                            type="text"
                            name="name"
                            required
                            value={formData?.title ? formData?.title : ''}
                            onChange={(e) =>
                              setFormData((n) => ({ ...n, title: e.target.value }))
                            }
                            placeholder={"Title"}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-6 col-12">
                        <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                          <label className="form-label">Description</label>
                          <textarea
                            className="form-control"
                            type="text"
                            name="description"
                            required
                            value={formData?.description ? formData?.description : ''}
                            onChange={(e) =>
                              setFormData((n) => ({ ...n, description: e.target.value }))
                            }
                            placeholder={"Write..."}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-6 col-12">
                        <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                          <label className="form-label">Full Name</label>
                          <input
                            className="form-control"
                            type="text"
                            name="name"
                            required
                            value={formData?.name ? formData?.name : ''}
                            onChange={(e) =>
                              setFormData((n) => ({ ...n, name: e.target.value }))
                            }
                            placeholder={"Full Name"}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-6 col-12">
                        <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                          <label className="form-label">Designation</label>
                          <input
                            className="form-control"
                            type="text"
                            name="designation"
                            required
                            value={formData?.designation ? formData?.designation : ''}
                            onChange={(e) =>
                              setFormData((n) => ({ ...n, designation: e.target.value }))
                            }
                            placeholder={"Designation"}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-6 col-12">
                      <FormGroup style={{ width: '100%', padding: '5px', margin: '0px', border: 'solid #ececec 1px',background:'#ececec',position:'relative'}}>
                          <label className="form-label">Image</label>
                          {
                            formData?.image ?
                              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '15px' }}>
                                <img src={process.env.REACT_APP_IMG_URL + formData?.image} className="img-fluid" width={100} height={100} />
                                <button title='Delete' style={{ border: 'solid #df4b0e 1px', width: '45px', height: '45px', position: 'absolute', borderRadius: '100%', background: '#df4b0e', color: '#fff',right:'5px',top:'5px'}} onClick={(e) => {
                                  if (imageRef1?.current) {
                                    imageRef1.current.value = "";
                                  }
                                  setFormData((n) => ({ ...n, image: '' }))
                                }}>
                                  <Icon name="trash"></Icon>
                                </button>
                              </div>

                              : null
                          }
                          <br />
                          <input
                            className="form-control"
                            type="file"
                            name="icon"
                            ref={imageRef1}
                            onChange={async (e) => {

                              let chooseFile = e?.target?.files[0] ? e?.target?.files[0] : false;
                              if (!chooseFile) {
                                toast.error("Please choose file!")
                              }
                              const formDataUpload = new FormData();
                              formDataUpload.append("file", chooseFile);
                              await uploadDataAWS(formDataUpload).then((response) => {
                                if (response?.success) {
                                  setFormData((n) => ({ ...n, image: response?.path }))
                                  if (imageRef1.current) {
                                    imageRef1.current.value = "";
                                  }
                                } else {
                                  if (imageRef1.current) {
                                    imageRef1.current.value = "";
                                  }
                                  toast.error(response?.message);
                                }

                              })
                            }}
                          />

                        </FormGroup>
                      </div>
                      <div className="col-md-6 col-12">
                        <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                          <label className="form-label">Language</label>
                          {
                            <select
                              className="form-control"
                              name="language"
                              required

                              value={formData?.language ? formData?.language : ''}
                              onChange={(e) =>
                                setFormData((n) => ({ ...n, language: e.target.value }))
                              }
                            >
                              <option value="">Select Language</option>
                              {
                                languageList?.map((n, it) => <option key={it} value={n?._id}>{n?.name ? n?.name : ""}</option>)
                              }
                            </select>
                          }

                        </FormGroup>
                      </div>
                    </div>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                          Create
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};
export default Testnomial;

import { getMethod, postMethod } from "./apiServices"

export const checkuser=async()=>{
    return new Promise(async (resolve, reject)=>{
        await getMethod('auth/admin/check').then((response)=>{
              if(response?.success){
                   localStorage.setItem('_jwt_admin', true);
                   resolve({success:true, data:response.data});
              }else{
                  localStorage.removeItem('_jwt_admin');
                  resolve({success:false, message: response.message});
              }
        })
    })
}

export const uploadDataAWS=async(data)=>{
      return new Promise(async (resolve, reject)=>{
        await postMethod('aws/upload-image', data).then((response)=>{
            if(response?.success){
                 resolve({success: response?.data?.key?true:false,path:response?.data?.key?response?.data?.key:""})
            }else{
                resolve({success:false, message: response?.message});
            }
        })
      })
}

export const bulkCreateData=async(data)=>{
    return new Promise(async (resolve, reject)=>{
      await postMethod('v2/bulk/questions', data).then((response)=>{
          if(response?.success){
               resolve(response)
          }else{
              resolve({success:false, message: response?.message});
          }
      })
    })
}

export const bulkCreateDataDriving=async(data)=>{
    return new Promise(async (resolve, reject)=>{
      await postMethod('v2/bulk/driving-schools', data).then((response)=>{
          if(response?.success){
               resolve(response)
          }else{
              resolve({success:false, message: response?.message});
          }
      })
    })
}
export const bulkCreateLearningWord=async(data)=>{
    return new Promise(async (resolve, reject)=>{
      await postMethod('v2/bulk/gloassary', data).then((response)=>{
          if(response?.success){
               resolve(response)
          }else{
              resolve({success:false, message: response?.message});
          }
      })
    })
}

import React, { useContext, useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import toast from 'react-hot-toast';
import {
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  DropdownItem,
  Form,
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  UserAvatar,
  PaginationComponent,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
} from "../../../components/Component";
import {  bulkCreateLearningWord } from "../../../utils/action";
import { deleteMethod, getMethod, postMethod, putMethod, setAuthorizationToken } from "../../../utils/apiServices";
import moment from "moment";
import { useLocation } from "react-router";
import { FullTextEditor } from "../../Editor/Editor";
import { slugify } from "../../../utils/Utils";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const LearningWord = () => {
  const router = useQuery();
  const [sm, updateSm] = useState(false)
  const [data, setData] = useState([]);
  const [totals, setTotals] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [paramsHistory, setParamsHistory] = useState({});
  const [editId, setEditedId] = useState("");
  const [languageList, setLanguageList] = useState([]);
  const [chapterList, setChapterList] = useState([]);
  const [isBulkModel, setBulkModel] = useState(false);
  const [formData, setFormData] = useState({
    learningWordList: [

      {
        LearningWordTranslation: "",
        name: "",
        content: "",
        slug:"",
        language: "",
      },
      {
        LearningWordTranslation: "",
        name: "",
        content: "",
        slug:"",
        language: "",
      }
    ]
  });


  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });


  const resetForm = () => {
    setEditedId("");
    setFormData({
     
        learningWordList: [
        {
            LearningWordTranslation: "",
          name: "",
          content: "",
          slug:"",
          language: "",
        },
        {
            LearningWordTranslation: "",
          name: "",
          content: "",
          slug:"",
          language: "",
        }
      ]
    });
  };


  const onFormCancel = () => {
    setModal({ edit: false, add: false });
    resetForm();
  };


  const paginate = async (pageNumber) => {
    setPage(pageNumber);
    var updateParams = paramsHistory;
    updateParams = Object.assign(updateParams, { page: pageNumber });
    await fetchUserList(updateParams);
  };





  const deleteUser = async (id, index) => {
    await deleteMethod('admin/learning/words/' + id).then((response) => {
      if (response?.success) {
        toast.success(response.message)
        data.splice(index, 1);
        setData([...data])
      } else {
        toast.error(response.message)
      }
    })
  }


  const fetchUserList = async (params) => {
    setParamsHistory(params);
    let updateParams = params;

    setData([]);
    await getMethod('admin/learning/words', updateParams).then((response) => {
      if (response?.success) {
        setTotals(response?.total ? response?.total : 0)
        setData(response.data);
      }
    })
  }


  const onEditSubmit = async (e, submitData) => {
    e.preventDefault();
    if (!editId) {
      toast.error("Edit Id required!");
    }
    if (submitData) {
      await putMethod('admin/learning/words/' + editId, submitData).then(async (response) => {
        if (response?.success) {
          setModal({ edit: false, add: false });
          resetForm();
          toast.success(response.message);
          await fetchUserList(paramsHistory);
        } else {
          toast.error(response.message)
        }
      })
    } else {
      toast.error("Required Feild missing!")
    }
  };

  const onAddSubmit = async (e, submitData) => {
    e.preventDefault();
    if (submitData) {
      await postMethod('admin/learning/words', submitData).then(async (response) => {
        if (response?.success) {
          setModal({ edit: false, add: false });
          resetForm();
          toast.success(response.message);
          await fetchUserList(paramsHistory);
        } else {
          toast.error(response.message)
        }
      })
    } else {
      toast.error("Required Feild missing!")
    }
  };
  const handleUpdateDefault = (array, checkId, key) => {
    let findIndex = array?.findIndex((n) => n?.language === checkId);
    return array[findIndex]?.[key] ? array[findIndex]?.[key] : ""
  }

  const handleUpdateDefaultIndex = (array, index, key) => {
    let findIndex = array?.findIndex((n, i) => i === index);
    return array[findIndex]?.[key] ? array[findIndex]?.[key] : ""
  }

  useEffect(() => {
    setAuthorizationToken(true)
    fetchUserList({ page: page, limit: limit });
    getMethod('admin/languages').then((response) => {
      if (response?.success) {
        setLanguageList(response?.data?.length ? response?.data : [])
      }
    })

   
  }, [page, limit]);


  return (
    <React.Fragment>
      <Head title="Learning Words"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Learning Words
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {totals} words.</p>
              </BlockDes>
            </BlockHeadContent>

            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <Button color="primary" className="btn-icon" onClick={() => setModal({ add: true })}>
                        <Icon name="plus"></Icon>
                      </Button>
                    </li>
                    <li className="nk-block-tools-opt">
                                            <Button color="primary" className="btn-icon" onClick={() => setBulkModel(isBulkModel ? false : true)}>
                                                <Icon name="plus"></Icon> Bulk Import&nbsp;&nbsp;
                                            </Button>
                                        </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <DataTable className="card-stretch">

            <DataTableBody>
              <DataTableHead>

                <DataTableRow size="mb" className="nk-tb-col-check">
                  <span className="sub-text">&nbsp;</span>
                </DataTableRow>
                <DataTableRow size="mb">
                  <span className="sub-text text-left">Name</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text text-left">Slug</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text text-center">Created Date</span>
                </DataTableRow>

                <DataTableRow size='mb' className="nk-tb-col-tools text-right">
                  <span className="sub-text text-right">More</span>
                </DataTableRow>
              </DataTableHead>

              {data.length > 0
                ? data.map((item, indexm) => {
                  return (
                    <DataTableItem key={item?._id}>

                      <DataTableRow size="mb" className="nk-tb-col-check">
                        <div className="user-card">
                          <span className="sub-text">{indexm + 1}</span>

                        </div>
                      </DataTableRow>

                      <DataTableRow size="mb" className="text-left">
                        {
                          item?.translations?.length ?
                            item?.translations?.map((n, i) =>
                              <span className={`text-left`} key={i}>
                                {n?.name ? n?.name : "NA"}
                                <br />
                              </span>
                            )
                            : null
                        }

                      </DataTableRow>





                      <DataTableRow size="lg" className="text-left">
                        {
                          item?.translations?.length ?
                            item?.translations?.map((n, i) =>
                              <span className={`text-left`} key={i}>
                                {n?.slug ? n?.slug : "NA"}
                                <br />
                              </span>
                            )
                            : null
                        }
                      </DataTableRow>


                      <DataTableRow className="text-center" size="md">
                        <span>{item.createdAt ? moment(item?.createdAt).format("YYYY-MM-DD, HH:mm A") : "NA"}</span>
                      </DataTableRow>

                      <DataTableRow size='mb' className="nk-tb-col-tools">
                        <ul className="nk-tb-actions gx-1">

                          <li>
                            <UncontrolledDropdown>
                              <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                <Icon name="more-h"></Icon>
                              </DropdownToggle>
                              <DropdownMenu right>
                                <ul className="link-list-opt no-bdr">
                                  <li onClick={() => {
                                    setEditedId(item?._id ? item?._id : "");
                                    var updateDataMap = ({
                                    
                                      learningWordList: item?.translations?.length ?
                                        item?.translations?.map((n) => ({ LearningWordTranslation: n?._id ? n?._id : "",slug:n?.slug, name: n?.name ? n?.name : "", content: n?.content ? n?.content : "", language: n?.language ? n?.language : "" }))
                                        : [
                                          {
                                            LearningWordTranslation: "",
                                            name: "",
                                            language: "",
                                            slug:"",
                                            content: "",
                                          },
                                          {
                                            LearningWordTranslation: "",
                                            name: "",
                                            content: "",
                                            slug:"",
                                            language: "",
                                          }
                                        ]
                                    })
                                    setFormData(updateDataMap);
                                    setModal({ edit: true })
                                  }}>
                                    <DropdownItem
                                      tag="a"
                                      href="#edit"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      <Icon name="edit"></Icon>
                                      <span>Edit</span>
                                    </DropdownItem>
                                  </li>
                                  <li onClick={() => deleteUser(item?._id ? item?._id : "", indexm)}>
                                    <DropdownItem
                                      tag="a"
                                      href="#suspend"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      <Icon name="delete"></Icon>
                                      <span>Delete</span>
                                    </DropdownItem>
                                  </li>
                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </li>
                        </ul>
                      </DataTableRow>
                    </DataTableItem>
                  );
                })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {totals > 0 ? (
                <PaginationComponent
                  itemPerPage={limit}
                  totalItems={totals}
                  paginate={async (n) => await paginate(n)}
                  currentPage={page}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>





        <Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Update Word</h5>
              <div className="mt-4">
                <Form className="row gy-4" onSubmit={(e) => onEditSubmit(e, formData)}>


                
                <Col md="12">
                    <FormGroup >
                      <label className="form-label">Slug</label>
                      <br />
                      <input
                        className="form-control"
                        type="text"
                        name="slug"
                        required
                        value={formData?.learningWordList[0]?.slug?formData?.learningWordList[0]?.slug:""}
                        onChange={async (e) => {
                          setFormData({
                            ...formData,
                            learningWordList: formData?.learningWordList?.map((nd)=>({...nd, slug:slugify(e.target.value)}))   //updateAllSlug(slugify(e.target.value), 'slug', formData?.practiceList)
                          })
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col md="12">
                    {
                      languageList?.length ?
                        languageList?.map((listLang, i) =>
                          <fieldset key={listLang?._id} style={{ margin: "15px 0px" }}>
                            <legend style={{ fontSize: '18px', fontWeight: '600' }}>{(i + 1)}. {listLang?.name ? listLang?.name : ""}</legend>

                            <div className="row justify-content-center">
                            <div className="col-md-6 col-12">
                              <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                <label className="form-label">Name</label>
                                <textarea
                                  className="form-control"
                                  type="text"
                                  name="name"
                                  required
                                  value={handleUpdateDefault(formData?.learningWordList, listLang?._id, "name")} //formData?.practiceList?.length?  ((formData?.practiceList)?.find((n) => n?.language === listLang?._id))?.name ? ((formData?.practiceList)?.find((n) => n?.language === listLang?._id))?.name : "" : ""}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      learningWordList: formData?.learningWordList?.map((dataUpdate) =>
                                        dataUpdate?.language === listLang?._id ?
                                          ({ ...dataUpdate, name: e.target.value })
                                          : dataUpdate
                                      )
                                    })
                                  }
                                  placeholder={"Write..."}

                                ></textarea>

                              </FormGroup>
                            </div>

                            <div className="col-md-6 col-12">
                              <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                <label className="form-label">Language</label>
                                {
                                  <select
                                    className="form-control"
                                    name="language"
                                    required
                                    
                                    value={handleUpdateDefault(formData?.learningWordList, listLang?._id, "language")} //formData?.practiceList?.length?  ((formData?.practiceList)?.find((n) => n?.language === listLang?._id))?.name ? ((formData?.practiceList)?.find((n) => n?.language === listLang?._id))?.name : "" : ""}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        learningWordList: formData?.learningWordList?.map((dataUpdate) =>
                                          dataUpdate?.language === listLang?._id ?
                                            ({ ...dataUpdate, language: e.target.value })
                                            : dataUpdate
                                        )
                                      })
                                    }
                                  >
                                    <option value="">Select Language</option>
                                    {
                                      languageList?.map((n, it) => <option key={it} value={n?._id}>{n?.name ? n?.name : ""}</option>)
                                    }
                                  </select>
                                }
                                {/* {errors?.language[i] && <span className="invalid">{errors?.language[i]?.message}</span>} */}
                              </FormGroup>
                            </div>

                            <div className="col-12">
                              <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                <label className="form-label">Content</label>
                                <FullTextEditor editorValue={handleUpdateDefault(formData?.learningWordList, listLang?._id, "content")} onChangeValue={(e) => {
                                  setFormData({
                                    ...formData,
                                    learningWordList: formData?.learningWordList?.map((dataUpdate, sid) =>
                                      dataUpdate?.language === listLang?._id ?
                                        ({ ...dataUpdate, content: e })
                                        : dataUpdate
                                    )
                                  });
                                }} />
                              </FormGroup>
                            </div>
                            </div>


                         
                          </fieldset>
                        )
                        : null
                    }
                  </Col>

                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                          Update
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>



        <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">

          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Create Topic</h5>
              <div className="mt-4">
                <Form className="row gy-4" onSubmit={(e) => onAddSubmit(e, formData)}>
                <Col md="12">
                    <FormGroup >
                      <label className="form-label">Slug</label>
                      <br />
                      <input
                        className="form-control"
                        type="text"
                        name="slug"
                        required
                        value={formData?.learningWordList[0]?.slug?formData?.learningWordList[0]?.slug:""}
                        onChange={async (e) => {
                          setFormData({
                            ...formData,
                            learningWordList: formData?.learningWordList?.map((nd)=>({...nd, slug:slugify(e.target.value)}))   //updateAllSlug(slugify(e.target.value), 'slug', formData?.practiceList)
                          })
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    {
                      languageList?.length ?
                        languageList?.map((listLang, i) =>
                          <fieldset key={listLang?._id} style={{ margin: "15px 0px", }}>
                            <legend style={{ fontSize: '18px', fontWeight: '600' }}>{(i + 1)}. {listLang?.name ? listLang?.name : ""}</legend>
                            <div className="row justify-content-center">
                            <div className="col-md-6 col-12">
                              <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                <label className="form-label">Name</label>
                                <textarea
                                  className="form-control"
                                  type="text"
                                  name="name"
                                  required
                                  value={handleUpdateDefaultIndex(formData?.learningWordList, i, "name")} //formData?.practiceList?.length?  ((formData?.practiceList)?.find((n) => n?.language === listLang?._id))?.name ? ((formData?.practiceList)?.find((n) => n?.language === listLang?._id))?.name : "" : ""}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      learningWordList: formData?.learningWordList?.map((dataUpdate, pracIndex) =>
                                        pracIndex === i ?
                                          ({ ...dataUpdate, name: e.target.value })
                                          : dataUpdate
                                      )
                                    })
                                  }
                                  placeholder={"Write..."}

                                ></textarea>

                              </FormGroup>
                            </div>
                            <div className="col-md-6 col-12">
                              <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                <label className="form-label">Language</label>
                                {
                                  <select
                                    className="form-control"
                                    name="language"
                                    required

                                    value={handleUpdateDefaultIndex(formData?.learningWordList, i, "language")} //formData?.practiceList?.length?  ((formData?.practiceList)?.find((n) => n?.language === listLang?._id))?.name ? ((formData?.practiceList)?.find((n) => n?.language === listLang?._id))?.name : "" : ""}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        learningWordList: formData?.learningWordList?.map((dataUpdate, pracIndex) =>
                                          pracIndex === i ?
                                            ({ ...dataUpdate, language: e.target.value })
                                            : dataUpdate
                                        )
                                      })
                                    }
                                  >
                                    <option value="">Select Language</option>
                                    {
                                      languageList?.map((n, it) => <option key={it} value={n?._id}>{n?.name ? n?.name : ""}</option>)
                                    }
                                  </select>
                                }
                                {/* {errors?.language[i] && <span className="invalid">{errors?.language[i]?.message}</span>} */}
                              </FormGroup>
                            </div>
                            <div className="col-12">
                              <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                <label className="form-label">Content</label>
                                <FullTextEditor editorValue={handleUpdateDefaultIndex(formData?.learningWordList, i, "content")} onChangeValue={(e) => {
                                  setFormData({
                                    ...formData,
                                    learningWordList: formData?.learningWordList?.map((dataUpdate, sid) =>
                                      sid === i ?
                                        ({ ...dataUpdate, content: e })
                                        : dataUpdate
                                    )
                                  });
                                }} />
                              </FormGroup>
                            </div>
                            </div>
                            
                           
                          </fieldset>
                        )
                        : null
                    }
                  </Col>

                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                          Create
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>


        <Modal isOpen={isBulkModel} toggle={() => setBulkModel(isBulkModel ? false : true)} className="modal-dialog-centered" size="lg">
                    <ModalBody>
                        <a
                            href="#cancel"
                            onClick={(ev) => {
                                ev.preventDefault();
                                document.getElementById("resetForm2").reset();
                                setBulkModel(isBulkModel ? false : true)
                            }}
                            className="close"
                        >
                            <Icon name="cross-sm"></Icon>
                        </a>
                        <div className="p-2">
                            <h5 className="title">Create Bulk Driving Schools</h5>
                            <div className="mt-4">
                                <Form className="row gy-4" id='resetForm2' onSubmit={async(e)=>{
                                    e.preventDefault();
                                    const {file} = e.target.elements;
                                    if(!file?.files?.length){
                                        toast.error("File Required!");
                                        return false;
                                    }
                                    const formDataUpload1 = new FormData();
                                    formDataUpload1.append("file", file.files[0]);
                                    if(document?.querySelector("#resetForm2 button")){
                                        document.querySelector("#resetForm2 button").disabled = true
                                    }
                                    await bulkCreateLearningWord(formDataUpload1).then(async(response) => {
                                        if (response?.success) {
                                            toast.success("Successfully Data Saved!");
                                            document.getElementById("resetForm2").reset();
                                            if(document?.querySelector("#resetForm2 button")){
                                                document.querySelector("#resetForm2 button").disabled = false
                                            }
                                            setBulkModel(isBulkModel ? false : true)

                                            await fetchUserList(paramsHistory);
                                        } else {
                                            toast.error(response?.message);
                                            if(document?.querySelector("#resetForm2 button")){
                                                document.querySelector("#resetForm2 button").disabled = false
                                            }
                                        }

                                    })
                                }}>
                                  
                                    <Col md="12">
                                        <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                            <label className="form-label">Choose Excel FIle</label>
                                            <br/>
                                            <input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"  className="form-control" type='file' required name='file' />
                                        </FormGroup>
                                    </Col>
                                    <Col size="12">
                                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                            <li>
                                                <Button color="primary" size="md" type="submit">
                                                    Create
                                                </Button>
                                            </li>
                                            <li>
                                                <a
                                                    href="#cancel"
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        setBulkModel(isBulkModel ? false : true)
                                                    }}
                                                    className="link link-light"
                                                >
                                                    Cancel
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="Glossary-Sample-Sheet.xlsx"
                                                    download
                                                    className="link link-light"
                                                >
                                                    Download Sample File
                                                </a>
                                            </li>
                                        </ul>
                                    </Col>
                                </Form>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
      </Content>
    </React.Fragment>
  );
};
export default LearningWord;

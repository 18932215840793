import React, { useContext, useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import toast from 'react-hot-toast';
import {
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  DropdownItem,
  Form,
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  UserAvatar,
  PaginationComponent,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
} from "../../../components/Component";

import { deleteMethod, getMethod, postMethod, putMethod, setAuthorizationToken } from "../../../utils/apiServices";
import moment from "moment";

const Faq = () => {
  const [sm, updateSm] = useState(false)
  const [data, setData] = useState([]);
  const [totals, setTotals] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [paramsHistory, setParamsHistory] = useState({});
  const [editId, setEditedId] = useState("");
 
  const [formData, setFormData] = useState({
    name:"",
    symbol:"",
    default:"",
    rtl:"",
  });


  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });


  const resetForm = () => {
    setEditedId("");
    setFormData({
        name:"",
        symbol:"",
        default:"",
        rtl:"",
    });
  };


  const onFormCancel = () => {
    setModal({ edit: false, add: false });
    resetForm();
  };


  const paginate = async (pageNumber) => {
    setPage(pageNumber);
    var updateParams = paramsHistory;
    updateParams = Object.assign(updateParams, { page: pageNumber });
    await fetchUserList(updateParams);
  };





  const deleteUser = async (id, index) => {
    await deleteMethod('admin/languages/' + id).then((response) => {
      if (response?.success) {
        toast.success(response.message)
        data.splice(index, 1);
        setData([...data])
      } else {
        toast.error(response.message)
      }
    })
  }


  const fetchUserList = async (params) => {
    setParamsHistory(params);

    setData([]);
    await getMethod('admin/languages', params).then((response) => {
      if (response?.success) {
        setTotals(response?.total ? response?.total : 0)
        setData(response.data);
      }
    })
  }


  const onEditSubmit = async (e, submitData) => {
    e.preventDefault();
    if (!editId) {
      toast.error("Edit Id required!");
    }
    if (submitData) {
      await putMethod('admin/languages/' + editId, submitData).then(async (response) => {
        if (response?.success) {
          setModal({ edit: false, add: false });
          resetForm();
          toast.success(response.message);
          await fetchUserList(paramsHistory);
        } else {
          toast.error(response.message)
        }
      })
    } else {
      toast.error("Required Feild missing!")
    }
  };

  const onAddSubmit = async (e, submitData) => {
    e.preventDefault();
    if (submitData) {
      await postMethod('admin/languages', submitData).then(async (response) => {
        if (response?.success) {
          setModal({ edit: false, add: false });
          resetForm();
          toast.success(response.message);
          await fetchUserList(paramsHistory);
        } else {
          toast.error(response.message)
        }
      })
    } else {
      toast.error("Required Feild missing!")
    }
  };
  const handleUpdateDefault = (array, checkId, key) => {
    let findIndex = array?.findIndex((n) => n?.language === checkId);
    return array[findIndex]?.[key] ? array[findIndex]?.[key] : ""
  }

  const handleUpdateDefaultIndex = (array, index, key) => {
    let findIndex = array?.findIndex((n,i) => i === index);
    return array[findIndex]?.[key] ? array[findIndex]?.[key] : ""
  }
  
  useEffect(() => {
    setAuthorizationToken(true)
    fetchUserList({ page: page, limit: limit });
  }, [page, limit]);


  return (
    <React.Fragment>
      <Head title="States"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Languages
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {totals} languages.</p>
              </BlockDes>
            </BlockHeadContent>

            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <Button color="primary" className="btn-icon" onClick={() => setModal({ add: true })}>
                        <Icon name="plus"></Icon>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <DataTable className="card-stretch">

            <DataTableBody>
              <DataTableHead>

                <DataTableRow size="mb" className="nk-tb-col-check">
                  <span className="sub-text">S.No</span>
                </DataTableRow>
                <DataTableRow size="mb">
                  <span className="sub-text text-left">Name</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text text-left">Symbol</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text text-left">Default</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text text-left">Is RTL</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text text-center">Created Date</span>
                </DataTableRow>

                <DataTableRow size='mb' className="nk-tb-col-tools text-right">
                  <span className="sub-text text-right">More</span>
                </DataTableRow>
              </DataTableHead>

              {data.length > 0
                ? data.map((item, indexm) => {
                  return (
                    <DataTableItem key={item?._id}>

                      <DataTableRow size="mb" className="nk-tb-col-check">
                        <div className="user-card">
                        <span className="sub-text">{indexm+1}</span>
        
                        </div>
                      </DataTableRow>

                      <DataTableRow size="mb" className="text-left">
                        {
                       <span className={`text-left`} >
                       {item?.name ? item?.name : "NA"}
                       
                     </span>
                        }

                      </DataTableRow>





                      <DataTableRow size="lg" className="text-left">
                      {
                       <span className={`text-left`}>
                       {item?.symbol ? item?.symbol : "NA"}
                       
                     </span>
                        }
                      </DataTableRow>

                      <DataTableRow size="lg" className="text-left">
                      {
                       <span className={`text-left`}>
                       {item?.default ? 'YES' : "NO"}
                       
                     </span>
                        }
                      </DataTableRow>  
                       <DataTableRow size="lg" className="text-left">
                      {
                       <span className={`text-left`}>
                       {item?.rtl ?'YES' : 'NO'}
                       
                     </span>
                        }
                      </DataTableRow>
                      <DataTableRow className="text-center" size="md">
                        <span>{item.createdAt ? moment(item?.createdAt).format("YYYY-MM-DD, HH:mm A") : "NA"}</span>
                      </DataTableRow>

                      <DataTableRow size='mb' className="nk-tb-col-tools">
                        <ul className="nk-tb-actions gx-1">

                          <li>
                            <UncontrolledDropdown>
                              <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                <Icon name="more-h"></Icon>
                              </DropdownToggle>
                              <DropdownMenu right>
                                <ul className="link-list-opt no-bdr">
                                  <li onClick={() => {
                                    setEditedId(item?._id ? item?._id : "");
                                    
                                    var updateDataMap = ({
                                      
                                        name:item?.name?item?.name:"",
                                        symbol:item?.symbol?item?.symbol:"",
                                        default:item?.default?item?.default:false,
                                        rtl:item?.rtl?item?.rtl:false,
                                    })
                                    
                                    setFormData(updateDataMap);
                                    setModal({ edit: true })
                                  }}>
                                    <DropdownItem
                                      tag="a"
                                      href="#edit"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      <Icon name="edit"></Icon>
                                      <span>Edit</span>
                                    </DropdownItem>
                                  </li>
                                  <li onClick={() => deleteUser(item?._id ? item?._id : "", indexm)}>
                                    <DropdownItem
                                      tag="a"
                                      href="#suspend"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      <Icon name="delete"></Icon>
                                      <span>Delete</span>
                                    </DropdownItem>
                                  </li>
                                 
                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </li>
                        </ul>
                      </DataTableRow>
                    </DataTableItem>
                  );
                })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {totals > 0 ? (
                <PaginationComponent
                  itemPerPage={limit}
                  totalItems={totals}
                  paginate={async (n) => await paginate(n)}
                  currentPage={page}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>





        <Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Update Language</h5>
              <div className="mt-4">
                <Form className="row gy-4" onSubmit={(e)=>onEditSubmit(e, formData)}>
                 
                <Col md="6">
                    <FormGroup >
                      <label className="form-label">Name</label>
                     
                      <input
                        className="form-control"
                         type='text'
                        name="name"
                        value={formData?.name}
                        onChange={async (e) => {
                          setFormData({ ...formData, name: e.target.value});
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup >
                      <label className="form-label">Symbol</label>
                     
                      <input
                        className="form-control"
                        name="symbol"
                        value={formData?.symbol}
                        onChange={async (e) => {
                          setFormData({ ...formData, symbol: (e.target.value).toLowerCase()});
                        }}
                      />
                    </FormGroup>
                  </Col>


                  <Col md="6">
                    <FormGroup >
                      <label className="form-label">Default</label>
                     
                      <select
                        className="form-control"
                      
                        name="default"
                        value={formData?.default}
                        onChange={async (e) => {
                          setFormData({ ...formData, default: e.target.value});
                        }}
                      >
                        {
                          [{value:"",label:"Select"},{ value:true, label:"Yes"},{value:false,label:"No"}]?.map((fh, fi)=><option key={fi} value={fh.value}>{fh?.label}</option>)
                        }
                      </select>
                    </FormGroup>
                  </Col>


                  <Col md="6">
                    <FormGroup >
                      <label className="form-label">Is RTL</label>
                     
                      <select
                        className="form-control"
                      
                        name="rtl"
                        value={formData?.rtl}
                        onChange={async (e) => {
                          setFormData({ ...formData, rtl: e.target.value});
                        }}
                      >
                        {
                          [{value:"",label:"Select"},{ value:true, label:"Yes"},{value:false,label:"No"}]?.map((fh, fi)=><option key={fi} value={fh.value}>{fh?.label}</option>)
                        }
                      </select>
                    </FormGroup>
                  </Col>












                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                          Update
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>



        <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
        
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Create Faq</h5>
              <div className="mt-4">
                <Form className="row gy-4" onSubmit={(e)=>onAddSubmit(e, formData)}>
                 
                <Col md="6">
                    <FormGroup >
                      <label className="form-label">Name</label>
                     
                      <input
                        className="form-control"
                         type='text'
                        name="name"
                        value={formData?.name}
                        onChange={async (e) => {
                          setFormData({ ...formData, name: e.target.value});
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup >
                      <label className="form-label">Symbol</label>
                     
                      <input
                        className="form-control"
                        name="symbol"
                        value={formData?.symbol}
                        onChange={async (e) => {
                          setFormData({ ...formData, symbol: (e.target.value).toLowerCase()});
                        }}
                      />
                    </FormGroup>
                  </Col>


                  <Col md="6">
                    <FormGroup >
                      <label className="form-label">Default</label>
                     
                      <select
                        className="form-control"
                      
                        name="default"
                        value={formData?.default}
                        onChange={async (e) => {
                          setFormData({ ...formData, default: e.target.value});
                        }}
                      >
                        {
                          [{value:"",label:"Select"},{ value:true, label:"Yes"},{value:false,label:"No"}]?.map((fh, fi)=><option key={fi} value={fh.value}>{fh?.label}</option>)
                        }
                      </select>
                    </FormGroup>
                  </Col>


                  <Col md="6">
                    <FormGroup >
                      <label className="form-label">Is RTL</label>
                     
                      <select
                        className="form-control"
                      
                        name="rtl"
                        value={formData?.rtl}
                        onChange={async (e) => {
                          setFormData({ ...formData, rtl: e.target.value});
                        }}
                      >
                        {
                          [{value:"",label:"Select"},{ value:true, label:"Yes"},{value:false,label:"No"}]?.map((fh, fi)=><option key={fi} value={fh.value}>{fh?.label}</option>)
                        }
                      </select>
                    </FormGroup>
                  </Col>


                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                          Create
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};
export default Faq;

import React from "react";
import { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { checkuser } from "../utils/action";
import { setAuthorizationToken } from "../utils/apiServices";


const PrivateRoute = (({ exact, component: Component, user:user, ...rest}) => {
   useEffect(()=>{
    setAuthorizationToken(true);
    checkuser();
   },[])
   const auth = localStorage.getItem("_jwt_admin");
   return (
    <Route
    exact={exact ? true : false}
    rest
    render={(props) =>
      auth ? (
        <Component {...props} {...rest}></Component>
      ) : (
        <Redirect to={`${process.env.PUBLIC_URL}/`}></Redirect>
      )
    }
  ></Route>
   )
})

export default PrivateRoute;

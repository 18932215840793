import React, { useState } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import RecentInvest from "../../components/partials/invest/recent-investment/RecentInvest";
import { Card } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  PreviewAltCard,
} from "../../components/Component";
import { getMethod, setAuthorizationToken } from '../../utils/apiServices';
import { useEffect } from "react";
import ContactUs from "../modules/user-manage/ContactUs";
const index = () => {
  const [sm, updateSm] = useState(false);
  const [topInsights, setTopInsights] = useState('');
  const fetchInsights = async () => {
    await getMethod('admin/insights').then((response) => {
      if (response?.success) {
        setTopInsights(response?.data);
      }
    })
  }

  useEffect(() => {
    setAuthorizationToken(true);
    fetchInsights();
  }, [])
  return (
    <React.Fragment>
      <Head title="Dashboard" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Dashboard</BlockTitle>

            </BlockHeadContent>
            <BlockHeadContent>

            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <Row className="g-gs">
            <Col md="4">
              <PreviewAltCard className="card-full">
                <div className="card-title-group align-start mb-0">
                  <div className="card-title">
                    <h6 className="subtitle">Total Earning</h6>
                  </div>
                  <div className="card-tools">

                  </div>
                </div>
                <div className="card-amount">
                  <span className="amount">
                    {topInsights?.totalAmount ? topInsights?.totalAmount : 0} <span className="currency currency-usd">SEK</span>
                  </span>

                </div>
                <div className="invest-data">
                  <div className="invest-data-amount g-2">
                    <div className="invest-data-history">
                      <div className="title">This Month</div>
                      <span className="amount">
                        {topInsights?.totalAmountMonth ? topInsights?.totalAmountMonth : 0} <span className="currency currency-usd"> SEK</span>
                      </span>
                    </div>
                    <div className="invest-data-history">
                      <div className="title">This Week</div>
                      <span className="amount">
                        {topInsights?.totlaAmountWeek ? topInsights?.totlaAmountWeek : 0} <span className="currency currency-usd"> SEK</span>
                      </span>
                    </div>
                  </div>
                </div>
              </PreviewAltCard>
            </Col>

            <Col md="4">
              <PreviewAltCard className="card-full">
                <div className="card-title-group align-start mb-0">
                  <div className="card-title">
                    <h6 className="subtitle">Total Users</h6>
                  </div>
                  <div className="card-tools">

                  </div>
                </div>
                <div className="card-amount">
                  <span className="amount">
                    {topInsights?.totalUsers ? topInsights?.totalUsers : 0}
                  </span>

                </div>
                <div className="invest-data">
                  <div className="invest-data-amount g-2">
                    <div className="invest-data-history">
                      <div className="title">Total Active Users</div>
                      <span className="amount">
                        {topInsights?.totalActiveUser ? topInsights?.totalActiveUser : 0}
                      </span>
                    </div>
                    <div className="invest-data-history">
                      <div className="title">Total Deactive Users</div>
                      <span className="amount">
                        {topInsights?.totalDeactiveUser ? topInsights?.totalDeactiveUser : 0}
                      </span>
                    </div>
                  </div>
                </div>
              </PreviewAltCard>
            </Col>

            <Col md="4">
              <PreviewAltCard className="card-full">
                <div className="card-title-group align-start mb-0">
                  <div className="card-title">
                    <h6 className="subtitle">Total Questions</h6>
                  </div>
                </div>
                <div className="card-amount">
                  <span className="amount">
                    {topInsights?.totalQuestions ? topInsights?.totalQuestions : 0}
                  </span>
                </div>
                <div className="invest-data">
                  <div className="invest-data-amount g-2">
                    <div className="invest-data-history">
                      <div className="title">Total Paid</div>
                      <span className="amount">
                        {topInsights?.totalPaidQuestion ? topInsights?.totalPaidQuestion : 0}
                      </span>
                    </div>
                    <div className="invest-data-history">
                      <div className="title">Total Free</div>
                      <span className="amount">
                        {topInsights?.totalFreeQuestion ? topInsights?.totalFreeQuestion : 0}
                      </span>
                    </div>
                    <div className="invest-data-history">
                      <div className="title">Total Practice</div>
                      <span className="amount">
                        {topInsights?.totalPracticeQuestion ? topInsights?.totalPracticeQuestion : 0}
                      </span>
                    </div>
                  </div>
                </div>
              </PreviewAltCard>
            </Col>




            <Col xl="12" xxl="6">
              <Card className="card-bordered card-full">
                <RecentInvest investData={topInsights?.fetchAllSubscriptionLatest?.length ? topInsights?.fetchAllSubscriptionLatest : []} />
              </Card>
            </Col>

            <Col xl="12" xxl="6">
              <Card>
                <div className="card-inner border-bottom">
                  <div className="card-title-group">
                    <div className="card-title">
                      <h6 className="title">Contact Us</h6>
                    </div>
                    <div className="card-tools">
                      <a
                        href="/contact-us"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                        className="link"
                      >
                        View All
                      </a>
                    </div>
                  </div>
                </div>
                <ContactUs isHide={true} />
              </Card>
            </Col>
          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default index;

import React from "react";

const Footer = () => {
  return (
    <div className="nk-footer nk-footer-fluid bg-lighter">
      <div className="container-xl">
        <div className="nk-footer-wrap">
          <div className="nk-footer-copyright" style={{width:"100%",textAlign:"center"}}>
            {" "}
            &copy; {new Date().getFullYear()} <a href="/">Korteori</a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;

import React, { useContext, useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import toast from 'react-hot-toast';
import jsPDF from 'jspdf';
import {
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledDropdown,
  DropdownItem,
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  UserAvatar,
  PaginationComponent,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
} from "../../../components/Component";

import { deleteMethod, getMethod, setAuthorizationToken } from "../../../utils/apiServices";
import moment from "moment";
import { useLocation } from "react-router";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const PaidTestListRegular = () => {
  const router = useQuery();
  const [data, setData] = useState([]);
  const [totals, setTotals] = useState(0);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(false);
  const [onSearchText, setSearchText] = useState("");

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [startDate, setStartDate] = useState(moment().startOf("month").format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [paramsHistory, setParamsHistory] = useState({});
  const toggle = () => setonSearch(!onSearch);








  const paginate = async (pageNumber) => {
    setPage(pageNumber);
    var updateParams = paramsHistory;
    updateParams = Object.assign(updateParams, { page: pageNumber });
    await fetchUserList(updateParams);
  };


  const onFilterChange = async (e) => {
    if (e?.target.value) {
      setSearchText(e.target.value);
      await fetchUserList({ search: (e.target.value).toLowerCase(), page: page, limit: limit })
    } else {
      setSearchText("");
      await fetchUserList({ page: page, limit: limit });
    }
  };


  const deleteUser = async (id, index) => {
    await deleteMethod('admin//users/paid-tests/' + id + '/delete').then((response) => {
      if (response?.success) {
        toast.success(response.message)
        data.splice(index, 1);
        setData([...data])
      } else {
        toast.error(response.message)
      }
    })
  }


  const fetchUserList = async (params) => {
    setParamsHistory(params);

    let updateParams = params;

    if (router?.get("user")) {
      updateParams.user = router.get('user');
    }
    await getMethod('admin//users/paid-tests', updateParams).then((response) => {
      if (response?.success) {
        setTotals(response?.total ? response?.total : 0)
        setData(response.data);
      }
    })
  }





  useEffect(() => {
    setAuthorizationToken(true)
    let params = { page: page, limit: limit }
    if (router?.get("user")) {
      params.user = router.get('user');
    }
    fetchUserList(params);
  }, [page, limit]);


  return (
    <React.Fragment>
      <Head title="Paid Tests"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Paid Tests
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {totals} tests.</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <DataTable className="card-stretch">
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
                <div className="card-tools">

                </div>
                <div className="card-tools mr-n1">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <li>
                      <div className="toggle-wrap">
                        <Button
                          className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                          onClick={() => updateTableSm(true)}
                        >
                          <Icon name="menu-right"></Icon>
                        </Button>
                        <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                          <ul className="btn-toolbar gx-1">
                            <li className="toggle-close">
                              <Button className="btn-icon btn-trigger toggle" onClick={() => updateTableSm(false)}>
                                <Icon name="arrow-left"></Icon>
                              </Button>
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <div className="dot dot-primary"></div>
                                  <Icon name="filter-alt"></Icon>
                                </DropdownToggle>
                                <DropdownMenu
                                  right
                                  className="filter-wg dropdown-menu-xl"
                                  style={{ overflow: "visible" }}
                                >
                                  <div className="dropdown-head">
                                    <span className="sub-title dropdown-title">Filter</span>
                                    <div className="dropdown">
                                      <a
                                        href="#more"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                        className="btn btn-sm btn-icon"
                                      >
                                        <Icon name="more-h"></Icon>
                                      </a>
                                    </div>
                                  </div>
                                  <div className="dropdown-body dropdown-body-rg">
                                    <label>According Created</label>
                                    <Row className="gx-6 gy-3">
                                      <Col size="6">
                                        <FormGroup>
                                          <label className="overline-title overline-title-alt">Start Date</label>
                                          <input type='date' value={startDate} onChange={(e) => { setStartDate(e.target.value) }} placeholder="DD/MM/YYYY" />
                                        </FormGroup>
                                      </Col>
                                      <Col size="6">
                                        <FormGroup>
                                          <label className="overline-title overline-title-alt">End Date</label>
                                          <input type='date' value={endDate} onChange={(e) => { setEndDate(e.target.value) }} placeholder="DD/MM/YYYY" />
                                        </FormGroup>
                                      </Col>
                                      <Col size="12">
                                        <FormGroup className="form-group" style={{ justifyContent: 'space-between', alignItems: 'center', display: 'flex' }}>
                                          <button type="button" onClick={async (e) => {
                                            await fetchUserList({ startDate: startDate, endDate: endDate, page: page, limit: limit });
                                          }} className="btn btn-secondary">
                                            Filter
                                          </button>

                                          <a
                                            href="#reset"
                                            onClick={async (ev) => {
                                              ev.preventDefault();
                                              setSearchText("");

                                              setLimit(10);
                                              setPage(1);
                                              setStartDate("");
                                              setEndDate("");
                                              await fetchUserList({ search: "", page: 1, limit: 10, startDate: "", endDate: "" });
                                            }}
                                            className="clickable"
                                          >
                                            Reset Filter
                                          </a>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </div>

                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle color="tranparent" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <Icon name="setting"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right className="dropdown-menu-xs">
                                  <ul className="link-check">
                                    <li>
                                      <span>Show</span>
                                    </li>
                                    {[10, 50, 100, 500, 1000]?.map((n, index) =>
                                      <li key={index} className={limit === n ? "active" : ""}>
                                        <DropdownItem
                                          tag="a"
                                          href="#dropdownitem"
                                          onClick={async (ev) => {
                                            ev.preventDefault();
                                            setLimit(n);
                                            await fetchUserList({ page: page, limit: n })
                                          }}
                                        >
                                          {n}
                                        </DropdownItem>
                                      </li>
                                    )}
                                  </ul>

                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active"
                      onClick={() => {
                        setSearchText("");
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search by User name/Email"
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <DataTableBody>
              <DataTableHead>

                <DataTableRow size="mb">
                  <span className="sub-text">User</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text text-left">Total Questions</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text text-center">Test Type</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text text-center">Score</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text text-center">Passed</span>
                </DataTableRow>


                <DataTableRow size="lg">
                  <span className="sub-text text-center">Current Status</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text text-center">Completed Date</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text text-center">Created Date</span>
                </DataTableRow>
                <DataTableRow size='mb' className="nk-tb-col-tools text-right">
                  <span className="sub-text text-right">More</span>
                </DataTableRow>



              </DataTableHead>

              {data.length > 0
                ? data.map((item, indexm) => {
                  return (
                    <DataTableItem key={item?._id}>

                      <DataTableRow size="mb">
                        <div className="user-card">
                          <UserAvatar size="md" theme="light" text={(item?.user?.name) ? (item?.user?.name)[0] : ""}></UserAvatar>

                          <div className="user-info">
                            <span className="tb-lead">
                              {item?.user?.name}
                            </span>
                            {item?.user?.email ? <span className="currency">{item?.user?.email}</span> : "NA"}
                          </div>
                        </div>
                      </DataTableRow>
                      <DataTableRow size="lg" className="text-left">
                        <div className="user-card">
                            <span>{item?.testFormat?.noOfQuestions? item?.testFormat?.noOfQuestions: "0"}</span>
                        </div>
                      </DataTableRow>
                      <DataTableRow size="lg">
                        <span className="sub-text text-center">{item?.testFormat?.timed ? 'Timed ('+(item?.testFormat?.time ? item?.testFormat?.time+")" :"") : "Not Timed"} </span>
                      </DataTableRow>
                      <DataTableRow size="lg">
                        <span className="sub-text text-center">{item?.score ? Math.round(((parseInt(item?.score)*parseInt(item?.testFormat?.noOfQuestions?item?.testFormat?.noOfQuestions:0)))/100)+'%' :"NA"} </span>
                      </DataTableRow>
                      <DataTableRow size="lg">
                        <span className={"sub-text text-center "+(item?.isComplete?  item?.passed?"text-success": "text-danger":"")} >{item?.isComplete? item?.passed ? 'Passed' :"Fail" : "NA"} </span>
                      </DataTableRow>


                    

                      <DataTableRow size="lg">
                        <span className={"sub-text text-center "+(item?.isComplete? "text-success" : "text-warning")} >{item?.isComplete? "Completed": item?.paused? "Paused" : "Ongoing"} </span>
                      </DataTableRow>

                

                      <DataTableRow className="text-center" size="lg">
                        <span className="sub-text">
                          <span
                            className={`text-center`}
                          >{item?.completionDate ? moment(item?.completionDate).format("YYYY-MM-DD HH:mm A") : "NA"}</span>
                        </span>
                      </DataTableRow>

                      <DataTableRow className="text-center" size="md">
                        <span>{item.createdAt ? moment(item?.createdAt).format("YYYY-MM-DD, HH:mm A") : "NA"}</span>
                      </DataTableRow>

                      <DataTableRow size='mb' className="nk-tb-col-tools">
                        <ul className="nk-tb-actions gx-1">

                          <li>
                            <UncontrolledDropdown>
                              <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                <Icon name="more-h"></Icon>
                              </DropdownToggle>
                              <DropdownMenu right>
                                <ul className="link-list-opt no-bdr">
                                  <li onClick={() => deleteUser(item?._id ? item?._id : "", indexm)}>
                                    <DropdownItem
                                      tag="a"
                                      href="#suspend"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      <Icon name="delete"></Icon>
                                      <span>Delete</span>
                                    </DropdownItem>
                                  </li>

                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </li>
                        </ul>
                      </DataTableRow>
                    </DataTableItem>
                  );
                })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {totals > 0 ? (
                <PaginationComponent
                  itemPerPage={limit}
                  totalItems={totals}
                  paginate={async (n) => await paginate(n)}
                  currentPage={page}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>

      </Content>
    </React.Fragment>
  );
};
export default PaidTestListRegular;

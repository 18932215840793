import React, { useContext, useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import toast from 'react-hot-toast';
import {
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    UncontrolledDropdown,
    Modal,
    ModalBody,
    DropdownItem,
    Form,
} from "reactstrap";
import {
    Block,
    BlockBetween,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Icon,
    Row,
    Col,
    UserAvatar,
    PaginationComponent,
    Button,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableRow,
    DataTableItem,
} from "../../../components/Component";
import MultiSelect from 'react-multiple-select-dropdown-lite'
import 'react-multiple-select-dropdown-lite/dist/index.css'
import { deleteMethod, getMethod, postMethod, putMethod, setAuthorizationToken } from "../../../utils/apiServices";
import moment from "moment";
import { useLocation } from "react-router";
import { slugify } from "../../../utils/Utils";
import { bulkCreateDataDriving } from "../../../utils/action";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const DrivingSchool = () => {
    const router = useQuery();
    const [sm, updateSm] = useState(false)
    const [data, setData] = useState([]);
    const [totals, setTotals] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [paramsHistory, setParamsHistory] = useState({});
    const [editId, setEditedId] = useState("");
    const [languageList, setLanguageList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [tagsList, setTagsList] = useState([]);
    const [tablesm, updateTableSm] = useState(false);
    const [filtersTags, setFiltersTags] = useState([])
    const [onSearch, setonSearch] = useState(false);
    const [onSearchText, setSearchText] = useState("");
    const [startDate, setStartDate] = useState(moment().startOf("month").format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
    const [isBulkModel, setBulkModel] = useState(false);
    const [isBulkCity, setIsBulkCity] = useState("");
    const [isBulkState, setIsBulkState] = useState("");
    const [isBulkTags, setIsBulkTags] = useState([]);

    const toggle = () => setonSearch(!onSearch);
    const [formData, setFormData] = useState({
        phone: "",
        email: "",
        website: "",
        zipcode: "",
        city: "",
        state: "",
        tags: [],
        nameList: [
            {
                DrivingSchoolTranslation: "",
                name: "",
                meta: "",
                slug: "",
                address: "",
                language: "",
            },
            {
                DrivingSchoolTranslation: "",
                name: "",
                slug: "",
                meta: "",
                address: "",
                language: "",
            }
        ]
    });


    const [modal, setModal] = useState({
        edit: false,
        add: false,
    });


    const resetForm = () => {
        setEditedId("");
        setFormData({
            phone: "",
            email: "",
            website: "",
            zipcode: "",
            city: "",
            state: "",
            tags: [],
            nameList: [
                {
                    DrivingSchoolTranslation: "",
                    name: "",
                    slug: "",
                    meta: "",
                    address: "",
                    language: "",
                },
                {
                    DrivingSchoolTranslation: "",
                    name: "",
                    slug: "",
                    meta: "",
                    address: "",
                    language: "",
                }
            ]
        });
    };


    const onFormCancel = () => {
        setModal({ edit: false, add: false });
        resetForm();
    };


    const paginate = async (pageNumber) => {
        setPage(pageNumber);
        var updateParams = paramsHistory;
        updateParams = Object.assign(updateParams, { page: pageNumber });
        await fetchUserList(updateParams);
    };





    const deleteUser = async (id, index) => {
        await deleteMethod('admin/driving-schools/' + id).then((response) => {
            if (response?.success) {
                toast.success(response.message)
                data.splice(index, 1);
                setData([...data])
            } else {
                toast.error(response.message)
            }
        })
    }


    const fetchUserList = async (params) => {
        setParamsHistory(params);
        let updateParams = params;
        if (router?.get("city")) {
            updateParams.city = router.get('city');
        }
        if (router?.get("state")) {
            updateParams.state = router.get('state');
        }
        setData([]);
        //console.log(updateParams)
        await getMethod('admin/driving-schools', updateParams).then((response) => {
            if (response?.success) {
                setTotals(response?.total ? response?.total : 0)
                setData(response.data);
            }
        })
    }

    const onFilterChange = async (e) => {
        if (e?.target.value) {
            setSearchText(e.target.value);
            await fetchUserList({ search: (e.target.value).toLowerCase(), page: page, limit: limit })
        } else {
            setSearchText("");
            await fetchUserList({ page: page, limit: limit });
        }
    };

    const onEditSubmit = async (e, submitData) => {
        e.preventDefault();
        if (!editId) {
            toast.error("Edit Id required!");
        }
        if (submitData) {
            await putMethod('admin/driving-schools/' + editId, submitData).then(async (response) => {
                if (response?.success) {
                    setModal({ edit: false, add: false });
                    resetForm();
                    toast.success(response.message);
                    await fetchUserList(paramsHistory);
                } else {
                    toast.error(response.message)
                }
            })
        } else {
            toast.error("Required Feild missing!")
        }
    };

    const onAddSubmit = async (e, submitData) => {
        e.preventDefault();
        if (submitData) {
            await postMethod('admin/driving-schools', submitData).then(async (response) => {
                if (response?.success) {
                    setModal({ edit: false, add: false });
                    resetForm();
                    toast.success(response.message);
                    await fetchUserList(paramsHistory);
                } else {
                    toast.error(response.message)
                }
            })
        } else {
            toast.error("Required Feild missing!")
        }
    };
    const handleUpdateDefault = (array, checkId, key) => {
        let findIndex = array?.findIndex((n) => n?.language === checkId);
        return array[findIndex]?.[key] ? array[findIndex]?.[key] : ""
    }

    const handleUpdateDefaultIndex = (array, index, key) => {
        let findIndex = array?.findIndex((n, i) => i === index);
        return array[findIndex]?.[key] ? array[findIndex]?.[key] : ""
    }

    useEffect(() => {
        setAuthorizationToken(true)
        fetchUserList({ page: page, limit: limit });
        getMethod('admin/languages').then((response) => {
            if (response?.success) {
                setLanguageList(response?.data?.length ? response?.data : [])
            }
        })
        getMethod('admin/states').then((response) => {
            if (response?.success) {

                setStateList(response.data);
            }
        })
        getMethod('admin/cities').then((response) => {
            if (response?.success) {

                setCityList(response.data);
            }
        })
        getMethod('admin/tags').then((response) => {
            if (response?.success) {

                setTagsList(response.data?.length ? (response?.data)?.map((n) => ({ label: n?.translations[0]?.name ? n?.translations[0]?.name : "", value: n?._id })) : []);
            }
        })
    }, [page, limit]);


    return (
        <React.Fragment>
            <Head title="States"></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                Driving Schools
                            </BlockTitle>
                            <BlockDes className="text-soft">
                                <p>You have total {totals} driving schools.</p>
                            </BlockDes>
                        </BlockHeadContent>
                      
                        <BlockHeadContent>
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <Button
                                    className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                                    onClick={() => updateSm(!sm)}
                                >
                                    <Icon name="menu-alt-r"></Icon>
                                </Button>
                                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                                    <ul className="nk-block-tools g-3">
                                        <li className="nk-block-tools-opt">
                                            <Button color="primary" className="btn-icon" onClick={() => setModal({ add: true })}>
                                                <Icon name="plus"></Icon>
                                            </Button>
                                        </li>

                                        <li className="nk-block-tools-opt">
                                            <Button color="primary" className="btn-icon" onClick={() => {
                                                setBulkModel(isBulkModel ? false : true)
                                                setIsBulkCity("");
                                                setIsBulkState("");
                                                setIsBulkTags([]);
                                            }}>
                                                <Icon name="plus"></Icon> Bulk Import&nbsp;&nbsp;
                                            </Button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                <Block>
                    <DataTable className="card-stretch">


                        <div className="card-inner position-relative card-tools-toggle">
                            <div className="card-title-group">
                                <div className="card-tools">

                                </div>
                                <div className="card-tools mr-n1">
                                    <ul className="btn-toolbar gx-1">
                                        <li>
                                            <a
                                                href="#search"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    toggle();
                                                }}
                                                className="btn btn-icon search-toggle toggle-search"
                                            >
                                                <Icon name="search"></Icon>
                                            </a>
                                        </li>
                                        <li className="btn-toolbar-sep"></li>
                                        <li>
                                            <div className="toggle-wrap">
                                                <Button
                                                    className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                                                    onClick={() => updateTableSm(true)}
                                                >
                                                    <Icon name="menu-right"></Icon>
                                                </Button>
                                                <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                                                    <ul className="btn-toolbar gx-1">
                                                        <li className="toggle-close">
                                                            <Button className="btn-icon btn-trigger toggle" onClick={() => updateTableSm(false)}>
                                                                <Icon name="arrow-left"></Icon>
                                                            </Button>
                                                        </li>
                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                                                    <div className="dot dot-primary"></div>
                                                                    <Icon name="filter-alt"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu
                                                                    right
                                                                    className="filter-wg dropdown-menu-xl"
                                                                    style={{ overflow: "visible" }}
                                                                >
                                                                    <div className="dropdown-head">
                                                                        <span className="sub-title dropdown-title">Filter</span>
                                                                        <div className="dropdown">
                                                                            <a
                                                                                href="#more"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                }}
                                                                                className="btn btn-sm btn-icon"
                                                                            >
                                                                                <Icon name="more-h"></Icon>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="dropdown-body dropdown-body-rg">
                                                                        <Row className="gx-6 gy-3">
                                                                            <Col size="6">
                                                                                <FormGroup>
                                                                                    <label className="overline-title overline-title-alt">Start Date</label>
                                                                                    <input type='date' value={startDate} onChange={(e) => { setStartDate(e.target.value) }} placeholder="DD/MM/YYYY" />
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col size="6">
                                                                                <FormGroup>
                                                                                    <label className="overline-title overline-title-alt">End Date</label>
                                                                                    <input type='date' value={endDate} onChange={(e) => { setEndDate(e.target.value) }} placeholder="DD/MM/YYYY" />
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col size="12">
                                                                                <FormGroup className="form-group" style={{ justifyContent: 'space-between', alignItems: 'center', display: 'flex' }}>
                                                                                    <button type="button" onClick={async (e) => {
                                                                                        await fetchUserList({ startDate: startDate, endDate: endDate, page: page, limit: limit });
                                                                                    }} className="btn btn-secondary">
                                                                                        Filter
                                                                                    </button>

                                                                                    <a
                                                                                        href="#reset"
                                                                                        onClick={async (ev) => {
                                                                                            ev.preventDefault();
                                                                                            setSearchText("");
                                                                                            setLimit(10);
                                                                                            setPage(1);
                                                                                            setStartDate("");
                                                                                            setFiltersTags([])
                                                                                            setEndDate("");
                                                                                            await fetchUserList({ search: "", page: 1, limit: 10, startDate: "", endDate: "" });
                                                                                        }}
                                                                                        className="clickable"
                                                                                    >
                                                                                        Reset Filter
                                                                                    </a>
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>

                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle color="tranparent" className="btn btn-trigger btn-icon dropdown-toggle">
                                                                    <Icon name="setting"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu right className="dropdown-menu-xs">
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Show</span>
                                                                        </li>
                                                                        {[10, 50, 100, 500, 1000]?.map((n, index) =>
                                                                            <li key={index} className={limit === n ? "active" : ""}>
                                                                                <DropdownItem
                                                                                    tag="a"
                                                                                    href="#dropdownitem"
                                                                                    onClick={async (ev) => {
                                                                                        ev.preventDefault();
                                                                                        setLimit(n);
                                                                                        await fetchUserList({ page: page, limit: n })
                                                                                    }}
                                                                                >
                                                                                    {n}
                                                                                </DropdownItem>
                                                                            </li>
                                                                        )}
                                                                    </ul>
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Tags</span>
                                                                        </li>
                                                                        {tagsList?.map((n, index) =>
                                                                            <li key={index} className={filtersTags?.includes(n?.value) ? "active" : ""}>
                                                                                <DropdownItem
                                                                                    tag="a"
                                                                                    href="#dropdownitem"
                                                                                    onClick={async (ev) => {
                                                                                        ev.preventDefault();
                                                                                        let fetchFiltersU = []

                                                                                        if (filtersTags?.includes(n?.value)) {
                                                                                            fetchFiltersU = (filtersTags?.filter((nf) => nf != n?.value));
                                                                                            setFiltersTags([...(filtersTags?.filter((nf) => nf != n?.value))])

                                                                                        } else {
                                                                                            fetchFiltersU = [...filtersTags, n?.value];
                                                                                            setFiltersTags([...filtersTags, n?.value])
                                                                                        }

                                                                                        await fetchUserList({ page: page, limit: limit, tag: fetchFiltersU })
                                                                                    }}
                                                                                >
                                                                                    {n?.label ? n?.label : "NA"}
                                                                                </DropdownItem>
                                                                            </li>
                                                                        )}
                                                                    </ul>

                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                                <div className="card-body">
                                    <div className="search-content">
                                        <Button
                                            className="search-back btn-icon toggle-search active"
                                            onClick={() => {
                                                setSearchText("");
                                                toggle();
                                            }}
                                        >
                                            <Icon name="arrow-left"></Icon>
                                        </Button>
                                        <input
                                            type="text"
                                            className="border-transparent form-focus-none form-control"
                                            placeholder="Search by Phone , Email, or Website"
                                            value={onSearchText}
                                            onChange={(e) => onFilterChange(e)}
                                        />
                                        <Button className="search-submit btn-icon">
                                            <Icon name="search"></Icon>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <DataTableBody>
                            <DataTableHead>

                                <DataTableRow size="mb" className="nk-tb-col-check">
                                    <span className="sub-text">S.No</span>
                                </DataTableRow>
                                <DataTableRow size="mb">
                                    <span className="sub-text text-left">Name</span>
                                </DataTableRow>
                                <DataTableRow size="lg">
                                    <span className="sub-text text-left">Email/Phone/Website</span>
                                </DataTableRow>
                                <DataTableRow size="lg">
                                    <span className="sub-text text-left">Tags</span>
                                </DataTableRow>

                                <DataTableRow size="lg">
                                    <span className="sub-text text-left">Address</span>
                                </DataTableRow>
                                <DataTableRow size="lg">
                                    <span className="sub-text text-left">City</span>
                                </DataTableRow>
                                <DataTableRow size="lg">
                                    <span className="sub-text text-left">State</span>
                                </DataTableRow>
                                <DataTableRow size="md">
                                    <span className="sub-text text-center">Created Date</span>
                                </DataTableRow>

                                <DataTableRow size='mb' className="nk-tb-col-tools text-right">
                                    <span className="sub-text text-right">More</span>
                                </DataTableRow>
                            </DataTableHead>

                           
                            {data.length > 0 ?
                                data?.map((item, indexm) =>
                                    <DataTableItem key={item?._id}>
                                        <DataTableRow size="mb" className="nk-tb-col-check">
                                            <div className="user-card">
                                                <span className="sub-text">{indexm + 1}</span>

                                            </div>
                                        </DataTableRow>
                                        <DataTableRow size="mb" className="text-left">
                                            {
                                                item?.translations?.length ?
                                                    item?.translations?.map((n, i) =>
                                                        i === 0 ?
                                                            <span className={`text-left`} key={i}>
                                                                {n?.DrivingSchoolTranslation?.name ? n?.DrivingSchoolTranslation?.name : "NA"}
                                                                <br />
                                                            </span>
                                                            : null
                                                    )
                                                    : null
                                            }
                                        </DataTableRow>
                                        <DataTableRow size="lg" className="text-left">
                                            {
                                                <span className={`text-left`}>
                                                    {item?.email ? item?.email : "NA"}
                                                </span>
                                            }
                                            <br />
                                            {
                                                <span className={`text-left`}>
                                                    {item?.phone ? item?.phone : "NA"}
                                                </span>
                                            }
                                            <br />
                                            {
                                                <span className={`text-left`}>
                                                    {item?.website ? item?.website : "NA"}
                                                </span>
                                            }
                                        </DataTableRow>

                                        <DataTableRow size="lg" className="text-left">
                                            {
                                                item?.tags?.length ?
                                                    item?.tags?.map((n, i) =>
                                                        n?.translations?.map((nmd, mbi) =>
                                                            mbi === 0 ?
                                                                <span className={`text-left text-info`} key={mbi}>
                                                                    {nmd?.TagTranslation?.name ? nmd?.TagTranslation?.name : "NA"}
                                                                    <br />
                                                                </span>
                                                                : null
                                                        )

                                                    )
                                                    : <span className={`text-left`} >NA</span>
                                            }
                                        </DataTableRow>
                                        <DataTableRow size="lg" className="text-left">
                                            {
                                                item?.translations?.length ?
                                                    item?.translations?.map((n, i) =>
                                                        i === 0 ?
                                                            <span className={`text-left`} key={i}>
                                                                {n?.DrivingSchoolTranslation?.address ? n?.DrivingSchoolTranslation?.address : "NA"}
                                                                <br />
                                                            </span>
                                                            : null
                                                    )
                                                    : <span className={`text-left`}>NA</span>
                                            }
                                        </DataTableRow>
                                        <DataTableRow size="lg" className="text-left">
                                            {
                                                item?.city?.translations?.length ?
                                                    item?.city?.translations?.map((n, i) =>
                                                        i === 0 ?
                                                            <span className={`text-left`} key={i}>
                                                                {n?.CityTranslation?.name ? n?.CityTranslation?.name : "NA"}
                                                                <br />
                                                            </span>
                                                            : null
                                                    )
                                                    : <span className={`text-left`}>NA</span>
                                            }
                                        </DataTableRow>
                                        <DataTableRow size="lg" className="text-left">
                                            {
                                                item?.state?.translations?.length ?
                                                    item?.state?.translations?.map((n, i) =>
                                                        i === 0 ?
                                                            <span className={`text-left`} key={i}>
                                                                {n?.StateTranslation?.name ? n?.StateTranslation?.name : "NA"}
                                                                <br />
                                                            </span>
                                                            : null
                                                    )
                                                    : <span className={`text-left`}>NA</span>
                                            }
                                        </DataTableRow>
                                        <DataTableRow className="text-center" size="md">
                                            <span>{item.createdAt ? moment(item?.createdAt).format("YYYY-MM-DD, HH:mm A") : "NA"}</span>
                                        </DataTableRow>

                                        <DataTableRow size='mb' className="nk-tb-col-tools">
                                            <ul className="nk-tb-actions gx-1">

                                                <li>
                                                    <UncontrolledDropdown>
                                                        <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                                            <Icon name="more-h"></Icon>
                                                        </DropdownToggle>
                                                        <DropdownMenu right>
                                                            <ul className="link-list-opt no-bdr">
                                                                <li onClick={() => {
                                                                    setEditedId(item?._id ? item?._id : "");

                                                                    var updateDataMap = ({
                                                                        phone: item?.phone ? item?.phone : "",
                                                                        email: item?.email ? item?.email : "",
                                                                        website: item?.website ? item?.website : "",
                                                                        zipcode: item?.zipcode ? item?.zipcode : "",
                                                                        city: item?.city?._id ? item?.city?._id : "",
                                                                        state: item?.state?._id ? item?.state?._id : "",
                                                                        tags: item?.tags?.length ? item?.tags?.map((n) => n?._id) : [],
                                                                        nameList: item?.translations?.length ? item?.translations?.map((bn, bi) => ({ DrivingSchoolTranslation: bn?.DrivingSchoolTranslation?._id ? bn?.DrivingSchoolTranslation?._id : "", slug: bn?.DrivingSchoolTranslation?.slug, name: bn?.DrivingSchoolTranslation?.name ? bn?.DrivingSchoolTranslation?.name : "", meta: bn?.DrivingSchoolTranslation?.meta ? bn?.DrivingSchoolTranslation?.meta : "", address: bn?.DrivingSchoolTranslation?.address ? bn?.DrivingSchoolTranslation?.address : "", language: bn?.DrivingSchoolTranslation?.language ? bn?.DrivingSchoolTranslation?.language : "" }))
                                                                            : [
                                                                                {
                                                                                    DrivingSchoolTranslation: "",
                                                                                    name: "",
                                                                                    meta: "",
                                                                                    slug: "",
                                                                                    address: "",
                                                                                    language: "",
                                                                                },
                                                                                {
                                                                                    DrivingSchoolTranslation: "",
                                                                                    name: "",
                                                                                    meta: "",
                                                                                    slug: "",
                                                                                    address: "",
                                                                                    language: "",
                                                                                }
                                                                            ]
                                                                    })

                                                                    setFormData(updateDataMap);
                                                                    setModal({ edit: true })
                                                                }}>
                                                                    <DropdownItem
                                                                        tag="a"
                                                                        href="#edit"
                                                                        onClick={(ev) => {
                                                                            ev.preventDefault();
                                                                        }}
                                                                    >
                                                                        <Icon name="edit"></Icon>
                                                                        <span>Edit</span>
                                                                    </DropdownItem>
                                                                </li>
                                                                <li onClick={() => deleteUser(item?._id ? item?._id : "", indexm)}>
                                                                    <DropdownItem
                                                                        tag="a"
                                                                        href="#suspend"
                                                                        onClick={(ev) => {
                                                                            ev.preventDefault();
                                                                        }}
                                                                    >
                                                                        <Icon name="delete"></Icon>
                                                                        <span>Delete</span>
                                                                    </DropdownItem>
                                                                </li>


                                                            </ul>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </li>
                                            </ul>
                                        </DataTableRow>
                                    </DataTableItem>
                                )
                                : null}
                        </DataTableBody>
                        <div className="card-inner">
                            {totals > 0 ? (
                                <PaginationComponent
                                    itemPerPage={limit}
                                    totalItems={totals}
                                    paginate={async (n) => await paginate(n)}
                                    currentPage={page}
                                />
                            ) : (
                                <div className="text-center">
                                    <span className="text-silent">No data found</span>
                                </div>
                            )}
                        </div>
                    </DataTable>
                </Block>

                <Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
                    <ModalBody>
                        <a
                            href="#cancel"
                            onClick={(ev) => {
                                ev.preventDefault();
                                onFormCancel();
                            }}
                            className="close"
                        >
                            <Icon name="cross-sm"></Icon>
                        </a>
                        <div className="p-2">
                            <h5 className="title">Update Driving School</h5>
                            <div className="mt-4">
                                <Form className="row gy-4" onSubmit={(e) => onEditSubmit(e, formData)}>
                                    <Col md="6">
                                        <FormGroup >
                                            <label className="form-label">Phone</label>

                                            <input
                                                className="form-control"
                                                type='text'
                                                name="statesactive"
                                                value={formData?.phone}
                                                onChange={async (e) => {
                                                    setFormData({ ...formData, phone: e.target.value });
                                                }}
                                            />

                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup >
                                            <label className="form-label">Email</label>

                                            <input
                                                className="form-control"
                                                type='text'
                                                name="statesactive"
                                                value={formData?.email}
                                                onChange={async (e) => {
                                                    setFormData({ ...formData, email: e.target.value });
                                                }}
                                            />

                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup >
                                            <label className="form-label">Website</label>

                                            <input
                                                className="form-control"
                                                type='text'
                                                name="statesactive"
                                                value={formData?.website}
                                                onChange={async (e) => {
                                                    setFormData({ ...formData, website: e.target.value });
                                                }}
                                            />

                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup >
                                            <label className="form-label">ZIP Code</label>

                                            <input
                                                className="form-control"
                                                type='text'
                                                name="statesactive"
                                                value={formData?.zipcode}
                                                onChange={async (e) => {
                                                    setFormData({ ...formData, zipcode: e.target.value });
                                                }}
                                            />

                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup >
                                            <label className="form-label">State</label>

                                            <select
                                                className="form-control"

                                                name="states"
                                                value={formData?.state}
                                                onChange={async (e) => {
                                                    setFormData({ ...formData, state: e.target.value });
                                                }}
                                            >
                                                <option value="">Select</option>
                                                {
                                                    stateList?.map((n, it) => <option key={it} value={n?._id}>{n?.translations?.map((npf, nit) => nit === 0 ? npf?.name : "")}</option>)
                                                }
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup >
                                            <label className="form-label">City</label>

                                            <select
                                                className="form-control"

                                                name="states"
                                                value={formData?.city}
                                                onChange={async (e) => {
                                                    setFormData({ ...formData, city: e.target.value });
                                                }}
                                            >
                                                <option value="">Select</option>
                                                {
                                                    cityList?.map((n, it) => <option key={it} value={n?._id}>{n?.translations?.map((npf, nit) => nit === 0 ? npf?.name : "")}</option>)
                                                }
                                            </select>
                                        </FormGroup>
                                    </Col>

                                    <Col md="12">
                                        <FormGroup >
                                            <label className="form-label">Slug</label>
                                            <br />
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="slug"
                                                required
                                                value={formData?.nameList[0]?.slug ? formData?.nameList[0]?.slug : ""}
                                                onChange={async (e) => {
                                                    setFormData({
                                                        ...formData,
                                                        nameList: formData?.nameList?.map((nd) => ({ ...nd, slug: slugify(e.target.value) }))   //updateAllSlug(slugify(e.target.value), 'slug', formData?.practiceList)
                                                    })
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>


                                    <Col md="6">
                                        <FormGroup >
                                            <label className="form-label">Tags</label>
                                            <MultiSelect options={tagsList} defaultValue={formData?.tags?.length ? formData?.tags?.join(",") : ""} placeholder="Select Tags" largeData={true} customeValue={false} clearable={false} downArrow={true} singleSelect={false} onChange={(e) => {
                                                setFormData({ ...formData, tags: e ? e?.split(",") : [] });
                                            }} name='tags' style={{ width: '100%' }} />

                                        </FormGroup>
                                    </Col>
                                    <Col md="12">
                                        {
                                            languageList?.length ?
                                                languageList?.map((listLang, i) =>
                                                    <div className="row justify-content-center" key={listLang?._id} style={{ margin: "15px 0px", }}>
                                                        <legend style={{ fontSize: '18px', fontWeight: '600' }}>{(i + 1)}. {listLang?.name ? listLang?.name : ""}</legend>
                                                        <div className="col-md-6 col-12">
                                                            <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                                                <label className="form-label">Name</label>
                                                                <textarea
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="name"
                                                                    required
                                                                    value={handleUpdateDefault(formData?.nameList, listLang?._id, "name")}
                                                                    onChange={(e) =>
                                                                        setFormData({
                                                                            ...formData,
                                                                            nameList: formData?.nameList?.map((dataUpdate) =>
                                                                                dataUpdate?.language === listLang?._id ?
                                                                                    ({ ...dataUpdate, name: e.target.value })
                                                                                    : dataUpdate
                                                                            )
                                                                        })
                                                                    }
                                                                    placeholder={"Write..."}

                                                                ></textarea>

                                                            </FormGroup>
                                                        </div>

                                                        <div className="col-md-6 col-12">
                                                            <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                                                <label className="form-label">Address</label>
                                                                <textarea
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="address"
                                                                    required
                                                                    value={handleUpdateDefault(formData?.nameList, listLang?._id, "address")}
                                                                    onChange={(e) =>
                                                                        setFormData({
                                                                            ...formData,
                                                                            nameList: formData?.nameList?.map((dataUpdate) =>
                                                                                dataUpdate?.language === listLang?._id ?
                                                                                    ({ ...dataUpdate, address: e.target.value })
                                                                                    : dataUpdate
                                                                            )
                                                                        })
                                                                    }
                                                                    placeholder={"Write..."}

                                                                ></textarea>

                                                            </FormGroup>
                                                        </div>

                                                        <div className="col-md-6 col-12">
                                                            <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                                                <label className="form-label">Meta Title</label>
                                                                <textarea
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="meta"
                                                                    required
                                                                    value={handleUpdateDefault(formData?.nameList, listLang?._id, "meta")}
                                                                    onChange={(e) =>
                                                                        setFormData({
                                                                            ...formData,
                                                                            nameList: formData?.nameList?.map((dataUpdate) =>
                                                                                dataUpdate?.language === listLang?._id ?
                                                                                    ({ ...dataUpdate, meta: e.target.value })
                                                                                    : dataUpdate
                                                                            )
                                                                        })
                                                                    }
                                                                    placeholder={"Write..."}

                                                                ></textarea>

                                                            </FormGroup>
                                                        </div>

                                                        <div className="col-md-6 col-12">
                                                            <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                                                <label className="form-label">Language</label>
                                                                {
                                                                    <select
                                                                        className="form-control"
                                                                        name="language"
                                                                        required

                                                                        value={handleUpdateDefault(formData?.nameList, listLang?._id, "language")}
                                                                        onChange={(e) =>
                                                                            setFormData({
                                                                                ...formData,
                                                                                nameList: formData?.nameList?.map((dataUpdate) =>
                                                                                    dataUpdate?.language === listLang?._id ?
                                                                                        ({ ...dataUpdate, language: e.target.value })
                                                                                        : dataUpdate
                                                                                )
                                                                            })
                                                                        }
                                                                    >
                                                                        <option value="">Select Language</option>
                                                                        {
                                                                            languageList?.map((n, it) => <option key={it} value={n?._id}>{n?.name ? n?.name : ""}</option>)
                                                                        }
                                                                    </select>
                                                                }

                                                            </FormGroup>
                                                        </div>



                                                    </div>
                                                )
                                                : null
                                        }
                                    </Col>
                                    <Col size="12">
                                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                            <li>
                                                <Button color="primary" size="md" type="submit">
                                                    Update
                                                </Button>
                                            </li>
                                            <li>
                                                <a
                                                    href="#cancel"
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        onFormCancel();
                                                    }}
                                                    className="link link-light"
                                                >
                                                    Cancel
                                                </a>
                                            </li>
                                        </ul>
                                    </Col>
                                </Form>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
                    <ModalBody>
                        <a
                            href="#cancel"
                            onClick={(ev) => {
                                ev.preventDefault();
                                onFormCancel();
                            }}
                            className="close"
                        >
                            <Icon name="cross-sm"></Icon>
                        </a>
                        <div className="p-2">
                            <h5 className="title">Create Driving School</h5>
                            <div className="mt-4">
                                <Form className="row gy-4" onSubmit={(e) => onAddSubmit(e, formData)}>
                                    <Col md="6">
                                        <FormGroup >
                                            <label className="form-label">Phone</label>

                                            <input
                                                className="form-control"
                                                type='text'
                                                name="statesactive"
                                                value={formData?.phone}
                                                onChange={async (e) => {
                                                    setFormData({ ...formData, phone: e.target.value });
                                                }}
                                            />

                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup >
                                            <label className="form-label">Email</label>

                                            <input
                                                className="form-control"
                                                type='text'
                                                name="statesactive"
                                                value={formData?.email}
                                                onChange={async (e) => {
                                                    setFormData({ ...formData, email: e.target.value });
                                                }}
                                            />

                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup >
                                            <label className="form-label">Website</label>

                                            <input
                                                className="form-control"
                                                type='text'
                                                name="statesactive"
                                                value={formData?.website}
                                                onChange={async (e) => {
                                                    setFormData({ ...formData, website: e.target.value });
                                                }}
                                            />

                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup >
                                            <label className="form-label">ZIP Code</label>

                                            <input
                                                className="form-control"
                                                type='text'
                                                name="statesactive"
                                                value={formData?.zipcode}
                                                onChange={async (e) => {
                                                    setFormData({ ...formData, zipcode: e.target.value });
                                                }}
                                            />

                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup >
                                            <label className="form-label">State</label>

                                            <select
                                                className="form-control"

                                                name="states"
                                                value={formData?.state}
                                                onChange={async (e) => {
                                                    setFormData({ ...formData, state: e.target.value });
                                                }}
                                            >
                                                <option value="">Select</option>
                                                {
                                                    stateList?.map((n, it) => <option key={it} value={n?._id}>{n?.translations?.map((npf, nit) => nit === 0 ? npf?.name : "")}</option>)
                                                }
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup >
                                            <label className="form-label">City</label>

                                            <select
                                                className="form-control"

                                                name="states"
                                                value={formData?.city}
                                                onChange={async (e) => {
                                                    setFormData({ ...formData, city: e.target.value });
                                                }}
                                            >
                                                <option value="">Select</option>
                                                {
                                                    cityList?.map((n, it) => <option key={it} value={n?._id}>{n?.translations?.map((npf, nit) => nit === 0 ? npf?.name : "")}</option>)
                                                }
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup >
                                            <label className="form-label">Tags</label>
                                            <MultiSelect options={tagsList} defaultValue={formData?.tags?.length ? formData?.tags?.join(",") : ""} placeholder="Select Tags" largeData={true} customeValue={false} clearable={false} downArrow={true} singleSelect={false} onChange={(e) => {
                                                setFormData({ ...formData, tags: e ? e?.split(",") : [] });
                                            }} name='tags' style={{ width: '100%' }} />

                                        </FormGroup>
                                    </Col>
                                    <Col md="12">
                                        <FormGroup >
                                            <label className="form-label">Slug</label>
                                            <br />
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="slug"
                                                required
                                                value={formData?.nameList[0]?.slug ? formData?.nameList[0]?.slug : ""}
                                                onChange={async (e) => {
                                                    setFormData({
                                                        ...formData,
                                                        nameList: formData?.nameList?.map((nd) => ({ ...nd, slug: slugify(e.target.value) }))   //updateAllSlug(slugify(e.target.value), 'slug', formData?.practiceList)
                                                    })
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="12">
                                        {
                                            languageList?.length ?
                                                languageList?.map((listLang, i) =>
                                                    <div className="row justify-content-center" key={listLang?._id} style={{ margin: "15px 0px", }}>
                                                        <legend style={{ fontSize: '18px', fontWeight: '600' }}>{(i + 1)}. {listLang?.name ? listLang?.name : ""}</legend>
                                                        <div className="col-md-6 col-12">
                                                            <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                                                <label className="form-label">Name</label>
                                                                <textarea
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="name"
                                                                    required
                                                                    value={handleUpdateDefaultIndex(formData?.nameList, i, "name")}
                                                                    onChange={(e) =>
                                                                        setFormData({
                                                                            ...formData,
                                                                            nameList: formData?.nameList?.map((dataUpdate, sid) =>
                                                                                sid === i ?
                                                                                    ({ ...dataUpdate, name: e.target.value })
                                                                                    : dataUpdate
                                                                            )
                                                                        })
                                                                    }
                                                                    placeholder={"Write..."}

                                                                ></textarea>

                                                            </FormGroup>
                                                        </div>

                                                        <div className="col-md-6 col-12">
                                                            <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                                                <label className="form-label">Address</label>
                                                                <textarea
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="address"
                                                                    required
                                                                    value={handleUpdateDefaultIndex(formData?.nameList, i, "address")}
                                                                    onChange={(e) =>
                                                                        setFormData({
                                                                            ...formData,
                                                                            nameList: formData?.nameList?.map((dataUpdate, sid) =>
                                                                                sid === i ?
                                                                                    ({ ...dataUpdate, address: e.target.value })
                                                                                    : dataUpdate
                                                                            )
                                                                        })
                                                                    }
                                                                    placeholder={"Write..."}

                                                                ></textarea>

                                                            </FormGroup>
                                                        </div>

                                                        <div className="col-md-6 col-12">
                                                            <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                                                <label className="form-label">Meta Title</label>
                                                                <textarea
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="meta"
                                                                    required
                                                                    value={handleUpdateDefaultIndex(formData?.nameList, i, "meta")}
                                                                    onChange={(e) =>
                                                                        setFormData({
                                                                            ...formData,
                                                                            nameList: formData?.nameList?.map((dataUpdate, sid) =>
                                                                                sid === i ?
                                                                                    ({ ...dataUpdate, meta: e.target.value })
                                                                                    : dataUpdate
                                                                            )
                                                                        })
                                                                    }
                                                                    placeholder={"Write..."}

                                                                ></textarea>

                                                            </FormGroup>
                                                        </div>

                                                        <div className="col-md-6 col-12">
                                                            <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                                                <label className="form-label">Language</label>
                                                                {
                                                                    <select
                                                                        className="form-control"
                                                                        name="language"
                                                                        required

                                                                        value={handleUpdateDefaultIndex(formData?.nameList, i, "language")}
                                                                        onChange={(e) =>
                                                                            setFormData({
                                                                                ...formData,
                                                                                nameList: formData?.nameList?.map((dataUpdate, sid) =>
                                                                                    sid === i ?
                                                                                        ({ ...dataUpdate, language: e.target.value })
                                                                                        : dataUpdate
                                                                                )
                                                                            })
                                                                        }
                                                                    >
                                                                        <option value="">Select Language</option>
                                                                        {
                                                                            languageList?.map((n, it) => <option key={it} value={n?._id}>{n?.name ? n?.name : ""}</option>)
                                                                        }
                                                                    </select>
                                                                }

                                                            </FormGroup>
                                                        </div>



                                                    </div>
                                                )
                                                : null
                                        }
                                    </Col>
                                    <Col size="12">
                                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                            <li>
                                                <Button color="primary" size="md" type="submit">
                                                    Create
                                                </Button>
                                            </li>
                                            <li>
                                                <a
                                                    href="#cancel"
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        onFormCancel();
                                                    }}
                                                    className="link link-light"
                                                >
                                                    Cancel
                                                </a>
                                            </li>
                                        </ul>
                                    </Col>
                                </Form>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>


                <Modal isOpen={isBulkModel} toggle={() => setBulkModel(isBulkModel ? false : true)} className="modal-dialog-centered" size="lg">
                    <ModalBody>
                        <a
                            href="#cancel"
                            onClick={(ev) => {
                                ev.preventDefault();
                                document.getElementById("resetForm2").reset();
                                setBulkModel(isBulkModel ? false : true);
                                setIsBulkCity("");
                                setIsBulkState("");
                                setIsBulkTags([]);
                            }}
                            className="close"
                        >
                            <Icon name="cross-sm"></Icon>
                        </a>
                        <div className="p-2">
                            <h5 className="title">Create Bulk Driving Schools</h5>
                            <div className="mt-4">
                                <Form className="row gy-4" id='resetForm2' onSubmit={async (e) => {
                                    e.preventDefault();
                                    const { file } = e.target.elements;
                                    if (!file?.files?.length) {
                                        toast.error("File Required!");
                                        return false;
                                    }
                                    const formDataUpload1 = new FormData();
                                    formDataUpload1.append("file", file.files[0]);
                                    formDataUpload1.append("city", isBulkCity ? isBulkCity : '');
                                    formDataUpload1.append("state", isBulkState ? isBulkState : '');
                                    // formDataUpload1.append("tags", isBulkTags);
                                    if (document?.querySelector("#resetForm2 button")) {
                                        document.querySelector("#resetForm2 button").disabled = true
                                    }
                                    await bulkCreateDataDriving(formDataUpload1).then(async (response) => {
                                        if (response?.success) {
                                            toast.success("Successfully Data Saved!");
                                            document.getElementById("resetForm2").reset();
                                            if (document?.querySelector("#resetForm2 button")) {
                                                document.querySelector("#resetForm2 button").disabled = false
                                            }
                                            setBulkModel(isBulkModel ? false : true)
                                            setIsBulkCity("");
                                            setIsBulkState("");
                                            setIsBulkTags([]);
                                            await fetchUserList(paramsHistory);
                                        } else {
                                            toast.error(response?.message);
                                            if (document?.querySelector("#resetForm2 button")) {
                                                document.querySelector("#resetForm2 button").disabled = false
                                            }
                                        }

                                    })
                                }}>

                                    {/* <Col md="6">
                                        <FormGroup >
                                            <label className="form-label">State</label>
                                            <select
                                                className="form-control"
                                                name="states"
                                                required
                                                value={isBulkState}
                                                onChange={async (e) => {
                                                    setIsBulkState(e.target.value);
                                                }}
                                            >
                                                <option value="">Select</option>
                                                {
                                                    stateList?.map((n, it) => <option key={it} value={n?._id}>{n?.translations?.map((npf, nit) => nit === 0 ? npf?.name : "")}</option>)
                                                }
                                            </select>
                                        </FormGroup>
                                    </Col> */}
                                    <Col md="6">
                                        <FormGroup >
                                            <label className="form-label">City</label>
                                            <select
                                                className="form-control"
                                                name="cities"
                                                required
                                                value={isBulkCity}
                                                onChange={async (e) => {
                                                    setIsBulkCity(e.target.value);
                                                    const selectedStateBulk = (cityList?.filter((n) => n?._id === e?.target?.value))?.length === 1 ? ((cityList?.filter((n) => n?._id === e?.target?.value)))[0]?.state ? ((cityList?.filter((n) => n?._id === e?.target?.value)))[0]?.state : '' : ''
                                                    setIsBulkState(selectedStateBulk)
                                                }}
                                            >
                                                <option value="">Select</option>
                                                {
                                                    cityList?.map((n, it) => <option key={it} value={n?._id}>{n?.translations?.map((npf, nit) => nit === 0 ? npf?.name : "")}</option>)
                                                }
                                            </select>
                                        </FormGroup>
                                    </Col>

                                    <Col md="6">
                                        <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                            <label className="form-label">Choose Excel FIle</label>
                                            <br />
                                            <input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" className="form-control" type='file' required name='file' />
                                        </FormGroup>
                                    </Col>
                                    <Col size="12">
                                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                            <li>
                                                <Button color="primary" size="md" type="submit">
                                                    Create
                                                </Button>
                                            </li>
                                            <li>
                                                <a
                                                    href="#cancel"
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        setBulkModel(isBulkModel ? false : true)
                                                        setIsBulkCity("");
                                                        setIsBulkState("");
                                                        setIsBulkTags([]);
                                                    }}
                                                    className="link link-light"
                                                >
                                                    Cancel
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="Driving_School_Sample.xlsx"
                                                    download
                                                    className="link link-light"
                                                >
                                                    Download Sample File
                                                </a>
                                            </li>
                                        </ul>
                                    </Col>
                                </Form>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </Content>
        </React.Fragment>
    );
};
export default DrivingSchool;

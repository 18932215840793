import React from "react";
import UserAvatar from "../../../user/UserAvatar";
import Icon from "../../../icon/Icon";
import { DropdownItem, DropdownMenu, DropdownToggle, Progress, UncontrolledDropdown } from "reactstrap";
import { DataTableHead, DataTableItem, DataTableRow } from "../../../table/DataTable";
import moment from 'moment';

const RecentInvest = (props) => {
  const {investData} = props;
  return (
    <React.Fragment>
      {" "}
      <div className="card-inner border-bottom">
        <div className="card-title-group">
          <div className="card-title">
            <h6 className="title">Latest Subscriptions</h6>
          </div>
          <div className="card-tools">
            <a
              href="/subscriptions"
              onClick={(ev) => {
                ev.preventDefault();
              }}
              className="link"
            >
              View All
            </a>
          </div>
        </div>
      </div>
      <div className="nk-tb-list">
        <DataTableHead>
          <DataTableRow>
            <span>User</span>{" "}
          </DataTableRow>
          <DataTableRow size="sm">
            <span>Plan Type</span>
          </DataTableRow>
          <DataTableRow size="lg">
            <span>Date</span>
          </DataTableRow>
          <DataTableRow>
            <span>Amount</span>
          </DataTableRow>
          <DataTableRow size="sm">
            <span>Payment Status</span>
          </DataTableRow>
          <DataTableRow>
            <span>Active</span>
          </DataTableRow>
          <DataTableRow>
            <span>&nbsp;</span>
          </DataTableRow>
        </DataTableHead>
        {investData.map((item) => {
          return (
            <DataTableItem key={item.id}>
              <DataTableRow>
                <div className="align-center">
                  <UserAvatar size="sm" theme="light" text={(item.user?.name)?(item?.user?.name)[0]:""}></UserAvatar>
                  <span className="tb-sub ml-2">
                    {item?.user?.name?item?.user?.name:"NA"}
                  </span>
                </div>
              </DataTableRow>
              <DataTableRow size="sm">
                <div className="user-card">
                  <div className="user-name">
                    {
                      item?.plan?.translations?.map((n, index)=>
                        index === 0?
                        <span key={index} className="tb-lead">{n?.planTranslation?.label?n?.planTranslation?.label:'NA'}</span>
                        :null
                      )
                    }
                    
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="lg">
                <span className="tb-sub">{item?.createdAt?moment(item?.createdAt).format("YYYY-MM-DD, HH:mm A"):"NA"}</span>
              </DataTableRow>
              <DataTableRow>
                <span className="tb-sub tb-amount">
                  {item?.plan?.price?item?.plan?.price:0} <span>SEK</span>
                </span>
              </DataTableRow>
              <DataTableRow size="sm">
                {item?.payment?.status === "accepted" ? (
                  <span className="tb-sub text-success">Success</span>
                ) : (
                  <span className="tb-sub text-danger">{item?.payment?.status?item?.payment?.status:"NA"}</span>
                )}
              </DataTableRow>
              <DataTableRow size="sm">
                {item?.active? (
                  <span className="tb-sub text-success">Active</span>
                ) : (
                  <span className="tb-sub text-danger">Expired</span>
                )}
              </DataTableRow>
              <DataTableRow className="nk-tb-col-action">
                <UncontrolledDropdown>
                  <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-sm btn-icon btn-trigger">
                    <Icon name="chevron-right"></Icon>
                  </DropdownToggle>
                  <DropdownMenu right className="dropdown-menu-xs">
                    <ul className="link-list-plain">
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#dropdownitem"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          Invoice
                        </DropdownItem>
                      </li>
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </DataTableRow>
            </DataTableItem>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default RecentInvest;

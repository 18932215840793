import React, { useContext, useEffect, useRef, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import toast from 'react-hot-toast';
import parse from 'html-react-parser';
import {
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    UncontrolledDropdown,
    Modal,
    ModalBody,
    DropdownItem,
    Form,
} from "reactstrap";
import {
    Block,
    BlockBetween,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Icon,
    Row,
    Col,
    UserAvatar,
    PaginationComponent,
    Button,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableRow,
    DataTableItem,
} from "../../../components/Component";

import { deleteMethod, getMethod, postMethod, putMethod, setAuthorizationToken } from "../../../utils/apiServices";
import moment from "moment";
import { useLocation } from "react-router";
import { uploadDataAWS, bulkCreateData } from "../../../utils/action";
import { FullTextEditor } from "../../Editor/Editor";
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const QuestionLists = () => {
    const router = useQuery();
    const [sm, updateSm] = useState(false)
    const [data, setData] = useState([]);
    const [totals, setTotals] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [paramsHistory, setParamsHistory] = useState({});
    const [editId, setEditedId] = useState("");
    const [languageList, setLanguageList] = useState([]);
    const [chapterList, setChapterList] = useState([]);
    const [isBulkModel, setBulkModel] = useState(false);
    const [bulkTopic, setBulkTopic] = useState('');
    const imageRef1 = useRef();

    const [formData, setFormData] = useState({
        practiceTopic: "",
        image: "",
        questionType: "",
        isPractice: "",
        isFree: "",
        isPaid:"",
        practiceQuestionList: [
            {
                QuestionTranslation: "",
                content: "",
                language: "",
                options: [{ label: "", isAnswer: "" }],
                answerDescription: "",
            },
            {
                QuestionTranslation: "",
                content: "",
                language: "",
                options: [{ label: "", isAnswer: "" }],
                answerDescription: "",
            }
        ]
    });


    const [modal, setModal] = useState({
        edit: false,
        add: false,
    });


    const resetForm = () => {
        setEditedId("");
        setFormData({
            practiceTopic: "",
            image: "",
            questionType: "",
            isPractice: "",
            isFree: "",
            isPaid:"",
            practiceQuestionList: [

                {
                    QuestionTranslation: "",
                    content: "",
                    language: "",
                    options: [{ label: "", isAnswer: "" }],
                    answerDescription: "",
                },
                {
                    QuestionTranslation: "",
                    content: "",
                    language: "",
                    options: [{ label: "", isAnswer: "" }],
                    answerDescription: "",
                }
            ]
        });
        if (imageRef1?.current) {
            imageRef1.current.value = "";
        }
    };


    const onFormCancel = () => {
        setModal({ edit: false, add: false });
        resetForm();
    };


    const paginate = async (pageNumber) => {
        setPage(pageNumber);
        var updateParams = paramsHistory;
        updateParams = Object.assign(updateParams, { page: pageNumber });
        await fetchUserList(updateParams);
    };





    const deleteUser = async (id, index) => {
        await deleteMethod('admin/practice/questions/' + id).then((response) => {
            if (response?.success) {
                toast.success(response.message)
                data.splice(index, 1);
                setData([...data])
            } else {
                toast.error(response.message)
            }
        })
    }


    const fetchUserList = async (params) => {
        setParamsHistory(params);
        let updateParams = params;
        if (router?.get("id")) {
            updateParams.topics = router.get('id');
        }
        // setData([]);
        await getMethod('admin/practice/questions', updateParams).then((response) => {
            if (response?.success) {
                setTotals(response?.total ? response?.total : 0)
                setData(response.data);
            }
        })
    }

    function clean(obj) {
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined) {
                delete obj[propName];
            }
        }
        return obj
    }

    const onEditSubmit = async (e, submitData) => {
        e.preventDefault();
        if (!editId) {
            toast.error("Edit Id required!");
        }
        if (submitData) {
            var modified = submitData;
            modified = Object.assign(modified, {
                isPractice:modified?.isPractice === 'true'?true:false,
                isPaid:modified?.isPaid === 'true'?true:false,
                isFree: modified?.isFree === 'true'?true:false
            })
            await putMethod('admin/practice/questions/' + editId, (modified)).then(async (response) => {
                if (response?.success) {
                    setModal({ edit: false, add: false });
                    resetForm();
                    toast.success(response.message);
                    await fetchUserList(paramsHistory);
                } else {
                    toast.error(response.message)
                }
            })
        } else {
            toast.error("Required Feild missing!")
        }
    };

    const onAddSubmit = async (e, submitData) => {
        e.preventDefault();
        if (submitData) {
            var modified = submitData;
            modified = Object.assign(modified, {
                isPractice:modified?.isPractice === 'true'?true:false,
                isPaid:modified?.isPaid === 'true'?true:false,
                isFree: modified?.isFree === 'true'?true:false
            })
            await postMethod('admin/practice/questions', modified).then(async (response) => {
                if (response?.success) {
                    setModal({ edit: false, add: false });
                    resetForm();
                    toast.success(response.message);
                    await fetchUserList(paramsHistory);
                } else {
                    toast.error(response.message)
                }
            })
        } else {
            toast.error("Required Feild missing!")
        }
    };
    const handleUpdateDefault = (array, checkId, key) => {
        let findIndex = array?.findIndex((n) => n?.language === checkId);
        return array[findIndex]?.[key] ? array[findIndex]?.[key] : ""
    }

    const handleUpdateDefaultIndex = (array, index, key) => {
        let findIndex = array?.findIndex((n, i) => i === index);
        return array[findIndex]?.[key] ? array[findIndex]?.[key] : ""
    }

    useEffect(() => {
        setAuthorizationToken(true)
        fetchUserList({ page: page, limit: limit });
        getMethod('admin/languages').then((response) => {
            if (response?.success) {
                setLanguageList(response?.data?.length ? response?.data : [])
            }
        })


        getMethod('admin/practice/topics').then((response) => {
            if (response?.success) {
                setChapterList(response.data);
            }
        })
    }, [page, limit]);


    return (
        <React.Fragment>
            <Head title="Questions | Korteori Admin Panel"></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                Questions
                            </BlockTitle>
                            <BlockDes className="text-soft">
                                <p>You have total {totals} questions.</p>
                            </BlockDes>
                        </BlockHeadContent>

                        <BlockHeadContent>
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <Button
                                    className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                                    onClick={() => updateSm(!sm)}
                                >
                                    <Icon name="menu-alt-r"></Icon>
                                </Button>
                                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                                    <ul className="nk-block-tools g-3">
                                        <li className="nk-block-tools-opt">
                                            <Button color="primary" className="btn-icon" onClick={() => setModal({ add: true })}>
                                                <Icon name="plus"></Icon>
                                            </Button>
                                        </li>

                                        <li className="nk-block-tools-opt">
                                            <Button color="primary" className="btn-icon" onClick={() => setBulkModel(isBulkModel ? false : true)}>
                                                <Icon name="plus"></Icon> Bulk Import&nbsp;&nbsp;
                                            </Button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                <Block>
                    <DataTable className="card-stretch">

                        <DataTableBody>
                            <DataTableHead>

                                <DataTableRow size="mb" className="nk-tb-col-check">
                                    <span className="sub-text">Image</span>
                                </DataTableRow>
                                <DataTableRow size="mb">
                                    <span className="sub-text text-left">Name</span>
                                </DataTableRow>

                                <DataTableRow size="lg">
                                    <span className="sub-text text-left">Is Practice</span>
                                </DataTableRow>
                                <DataTableRow size="lg">
                                    <span className="sub-text text-left">Is Free</span>
                                </DataTableRow>
                                <DataTableRow size="lg">
                                    <span className="sub-text text-left">Is Paid</span>
                                </DataTableRow>
                                <DataTableRow size="md">
                                    <span className="sub-text text-center">Created Date</span>
                                </DataTableRow>

                                <DataTableRow size='mb' className="nk-tb-col-tools text-right">
                                    <span className="sub-text text-right">More</span>
                                </DataTableRow>
                            </DataTableHead>

                            {data.length > 0
                                ? data.map((item, indexm) => {
                                    return (
                                        <DataTableItem key={item?._id}>

                                            <DataTableRow size="mb" className="nk-tb-col-check">
                                                <div className="user-card">
                                                    {
                                                        item?.image ?
                                                            <img src={process.env.REACT_APP_IMG_URL + item?.image} className='img-fluid'></img>

                                                            : <span className="sub-text">NA</span>
                                                    }
                                                </div>
                                            </DataTableRow>
                                            <DataTableRow size="mb" className="text-left">
                                                {
                                                    item?.translations?.length ?
                                                        item?.translations?.map((n, i) =>
                                                            <span className={`text-left modifiedcontent`} style={{ fontSize: '11px' }} key={i}>

                                                                {i === 0 ? n?.content ? parse(n?.content) : "NA" : ""}
                                                                <br />
                                                            </span>
                                                        )
                                                        : null
                                                }

                                            </DataTableRow>

                                            <DataTableRow size="lg" className="text-left">
                                                <span className={`text-left text-warning`}>
                                                    {
                                                       item?.isPractice?'Yes':'No'
                                                    }
                                                    
                                                </span>
                                            </DataTableRow>
                                            <DataTableRow size="lg" className="text-left">
                                                <span className={`text-left text-warning`}>
                                                    {
                                                       item?.isFree?'Yes':'No'
                                                    }
                                                    
                                                </span>
                                            </DataTableRow>
                                            <DataTableRow size="lg" className="text-left">
                                                <span className={`text-left text-warning`}>
                                                    {
                                                       item?.isPaid?'Yes':'No'
                                                    }
                                                    
                                                </span>
                                            </DataTableRow>
                                        

                                            <DataTableRow className="text-center" size="md">
                                                <span>{item.createdAt ? moment(item?.createdAt).format("YYYY-MM-DD, HH:mm A") : "NA"}</span>
                                            </DataTableRow>

                                            <DataTableRow size='mb' className="nk-tb-col-tools">
                                                <ul className="nk-tb-actions gx-1">

                                                    <li>
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                                                <Icon name="more-h"></Icon>
                                                            </DropdownToggle>
                                                            <DropdownMenu right>
                                                                <ul className="link-list-opt no-bdr">
                                                                    <li onClick={() => {
                                                                        setEditedId(item?._id ? item?._id : "");
                                                                        var updateDataMap = ({
                                                                            practiceTopic: item?.topic ? item?.topic : "",
                                                                            image: item?.image ? item?.image : "",
                                                                            questionType: item?.questionType ? item?.questionType : "singleChoice",
                                                                            isPractice: item?.isPractice ? 'true' : 'false',
                                                                            isFree: item?.isFree ? 'true' : 'false',
                                                                            isPaid: item?.isPaid ? 'true' : 'false',
                                                                            practiceQuestionList: item?.translations?.length ?
                                                                                item?.translations?.map((np) => ({ QuestionTranslation: np?._id ? np?._id : "", content: np?.content ? np?.content : "", language: np?.language ? np?.language : "", options: np?.options?.length ? np?.options : [], answerDescription: np?.answerDescription ? np?.answerDescription : "" }))
                                                                                : []
                                                                        })
                                                                        setFormData(updateDataMap);
                                                                        setModal({ edit: true })
                                                                    }}>
                                                                        <DropdownItem
                                                                            tag="a"
                                                                            href="#edit"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                            }}
                                                                        >
                                                                            <Icon name="edit"></Icon>
                                                                            <span>Edit</span>
                                                                        </DropdownItem>
                                                                    </li>
                                                                    <li onClick={() => deleteUser(item?._id ? item?._id : "", indexm)}>
                                                                        <DropdownItem
                                                                            tag="a"
                                                                            href="#suspend"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                            }}
                                                                        >
                                                                            <Icon name="delete"></Icon>
                                                                            <span>Delete</span>
                                                                        </DropdownItem>
                                                                    </li>

                                                                </ul>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </li>
                                                </ul>
                                            </DataTableRow>
                                        </DataTableItem>
                                    );
                                })
                                : null}
                        </DataTableBody>
                        <div className="card-inner">
                            {totals > 0 ? (
                                <PaginationComponent
                                    itemPerPage={limit}
                                    totalItems={totals}
                                    paginate={async (n) => await paginate(n)}
                                    currentPage={page}
                                />
                            ) : (
                                <div className="text-center">
                                    <span className="text-silent">No data found</span>
                                </div>
                            )}
                        </div>
                    </DataTable>
                </Block>









                <Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
                    <ModalBody>
                        <a
                            href="#cancel"
                            onClick={(ev) => {
                                ev.preventDefault();
                                onFormCancel();
                            }}
                            className="close"
                        >
                            <Icon name="cross-sm"></Icon>
                        </a>
                        <div className="p-2">
                            <h5 className="title">Update Question</h5>
                            <div className="mt-4">
                                <Form className="row gy-4" onSubmit={(e) => onEditSubmit(e, formData)}>


                                    <Col md="12">
                                        <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                            <label className="form-label">Choose Topic</label>
                                            <select
                                                className="form-control"
                                                name="practiceTopic"
                                                required
                                                value={formData?.practiceTopic ? formData?.practiceTopic : ""}
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        practiceTopic: e.target.value
                                                    })
                                                }
                                            >
                                                <option value="">Select</option>
                                                {
                                                    chapterList?.map((n, it) =>
                                                        <option key={it} value={n?._id}>
                                                            {
                                                                n?.translations?.map((npf, nit) => ((n?.translations?.length - 1) === nit) ? npf?.name : npf?.name + " / ")
                                                            }

                                                        </option>
                                                    )
                                                }
                                            </select>
                                        </FormGroup>
                                    </Col>



                                    <Col md="12">
                                        <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                            <label className="form-label">Is Practice</label>
                                            <select
                                                className="form-control"
                                                name="isPractice"
                                                required
                                                value={formData?.isPractice === 'true'?'true':'false'}
                                                onChange={(e) =>{
                                                    setFormData({
                                                        ...formData,
                                                        isPractice: e?.target?.value === 'true'?'true':'false'
                                                    })
                                                }}
                                            >
                                                <option value="">Select</option>
                                                {
                                                    [{ label: "Yes", value: 'true'}, { label: "No", value: 'false' }]?.map((n, it) => <option key={it} value={n?.value}>{n?.label}</option>)
                                                }
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col md="12">
                                        <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                            <label className="form-label">Is Free</label>
                                            <select
                                                className="form-control"
                                                name="isFree"
                                                required
                                                value={formData?.isFree === 'true'?'true':'false'}
                                                onChange={(e) =>{
                                                    setFormData({
                                                        ...formData,
                                                        isFree: e?.target?.value === 'true'?'true':'false'
                                                    })
                                                }}
                                            >
                                                <option value="">Select</option>
                                                {
                                                    [{ label: "Yes", value: 'true'}, { label: "No", value: 'false' }]?.map((n, it) => <option key={it} value={n?.value}>{n?.label}</option>)
                                                }
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col md="12">
                                        <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                            <label className="form-label">Is Paid</label>
                                            <select
                                                className="form-control"
                                                name="isPaid"
                                                required
                                                value={formData?.isPaid === 'true'?'true':'false'}
                                                onChange={(e) =>{
                                                    setFormData({
                                                        ...formData,
                                                        isPaid: e?.target?.value==='true'?'true':'false'
                                                    })
                                                }}
                                            >
                                                <option value="">Select</option>
                                                {
                                                    [{ label: "Yes", value: 'true'}, { label: "No", value: 'false' }]?.map((n, it) => <option key={it} value={n?.value}>{n?.label}</option>)
                                                }
                                            </select>
                                        </FormGroup>
                                    </Col>


                                   
                        


                                    <Col md="12">
                                        <FormGroup style={{ flexDirection: 'column', display: "flex" }}>
                                            <label className="form-label">Image</label>
                                            {
                                                formData?.image ?
                                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <img src={process.env.REACT_APP_IMG_URL + formData?.image} className='img-fluid' alt='image' />
                                                <button title='Delete' style={{ border: 'solid #df4b0e 1px', width: '45px', height: '45px', position: 'absolute', borderRadius: '100%', background: '#df4b0e', color: '#fff' }} onClick={(e) => {
                                                    if (imageRef1?.current) {
                                                        imageRef1.current.value = "";
                                                    }
                                                    setFormData({ ...formData, image: '' });
                                                }}>
                                                    <Icon name="trash"></Icon>
                                                </button>
                                            </div>
                                                    : null
                                            }

                                            



                                            <br />
                                            <input
                                                className="form-control"
                                                type="file"
                                                name="image"
                                                ref={imageRef1}
                                                onChange={async (e) => {

                                                    let chooseFile = e?.target?.files[0] ? e?.target?.files[0] : false;
                                                    if (!chooseFile) {
                                                        toast.error("Please choose file!")
                                                    }
                                                    const formDataUpload = new FormData();
                                                    formDataUpload.append("file", chooseFile);
                                                    await uploadDataAWS(formDataUpload).then((response) => {
                                                        if (response?.success) {
                                                            setFormData({ ...formData, image: response?.path });
                                                            if (imageRef1?.current) {
                                                                imageRef1.current.value = "";
                                                            }
                                                        } else {
                                                            if (imageRef1?.current) {
                                                                imageRef1.current.value = "";
                                                            }
                                                            toast.error(response?.message);
                                                        }

                                                    })
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>



                                    <Col md="12" >
                                        {
                                            languageList?.length ?
                                                languageList?.map((listLang, i) =>
                                                    <div className="row" key={listLang?._id} style={{ margin: "15px 0px", padding: "15px" }}>
                                                        <br />
                                                        <h2 style={{ fontSize: '18px', fontWeight: '600', marginTop: '10px 0px', }}>{(i + 1)}. {listLang?.name ? listLang?.name : ""}</h2>
                                                        <Col md="12">
                                                            <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                                                <label className="form-label">Language</label>
                                                                {
                                                                    <select
                                                                        className="form-control"
                                                                        name="language"
                                                                        required

                                                                        value={handleUpdateDefault(formData?.practiceQuestionList, listLang?._id, "language")} //formData?.practiceList?.length?  ((formData?.practiceList)?.find((n) => n?.language === listLang?._id))?.name ? ((formData?.practiceList)?.find((n) => n?.language === listLang?._id))?.name : "" : ""}
                                                                        onChange={(e) =>
                                                                            setFormData({
                                                                                ...formData,
                                                                                practiceQuestionList: formData?.practiceQuestionList?.map((dataUpdate) =>
                                                                                    dataUpdate?.language === listLang?._id ?
                                                                                        ({ ...dataUpdate, language: e.target.value })
                                                                                        : dataUpdate
                                                                                )
                                                                            })
                                                                        }
                                                                    >
                                                                        <option value="">Select Language</option>
                                                                        {
                                                                            languageList?.map((n, it) => <option key={it} value={n?._id}>{n?.name ? n?.name : ""}</option>)
                                                                        }
                                                                    </select>
                                                                }

                                                            </FormGroup>

                                                            <br />
                                                        </Col>

                                                        <Col md="12">
                                                            <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                                                <label className="form-label">Question Content</label>
                                                                {

                                                                    <FullTextEditor editorValue={handleUpdateDefault(formData?.practiceQuestionList, listLang?._id, "content")} onChangeValue={(e) => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            practiceQuestionList: formData?.practiceQuestionList?.map((dataUpdate) =>
                                                                                dataUpdate?.language === listLang?._id ?
                                                                                    ({ ...dataUpdate, content: e })
                                                                                    : dataUpdate
                                                                            )
                                                                        });
                                                                    }} />
                                                                }

                                                            </FormGroup>
                                                            <br />
                                                        </Col>

                                                        <Col md="12">
                                                            <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                                                <label className="form-label">Answer Description</label>
                                                                {

                                                                    <FullTextEditor editorValue={handleUpdateDefault(formData?.practiceQuestionList, listLang?._id, "answerDescription")} onChangeValue={(e) => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            practiceQuestionList: formData?.practiceQuestionList?.map((dataUpdate) =>
                                                                                dataUpdate?.language === listLang?._id ?
                                                                                    ({ ...dataUpdate, answerDescription: e })
                                                                                    : dataUpdate
                                                                            )
                                                                        });
                                                                    }} />
                                                                }

                                                            </FormGroup>
                                                        </Col>


                                                        <Col md="12">
                                                            <br />
                                                            <label className="form-label">Options</label>
                                                            <div className="row justify-content-between" style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                                                <div className="col-12 text-right">
                                                                    <span onClick={(e) => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            practiceQuestionList: formData?.practiceQuestionList?.map((dataUpdate) =>
                                                                                dataUpdate?.language === listLang?._id ?
                                                                                    ({ ...dataUpdate, options: [...(dataUpdate?.options), ({ label: "", isAnswer: false })] })
                                                                                    : dataUpdate
                                                                            )
                                                                        });
                                                                    }} style={{ cursor: 'pointer', width: '100%', padding: '10px 15px', border: 'solid #ececec 1px', background: "#ececec", color: "red" }}>+Add Option</span>
                                                                </div>

                                                                {
                                                                    handleUpdateDefault(formData?.practiceQuestionList, listLang?._id, "options")?.length ?
                                                                        handleUpdateDefault(formData?.practiceQuestionList, listLang?._id, "options")?.map((opt, oi) =>
                                                                            opt ?
                                                                                <div className="row justify-content-between" key={oi} style={{ alignItems: 'center', margin: "15px 0px", width: '100%' }}>
                                                                                    <div className="col-md-5 col-12">
                                                                                        <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                                                                            <label className="form-label">Label</label>
                                                                                            {
                                                                                                <input
                                                                                                    className="form-control"
                                                                                                    name="label"
                                                                                                    value={opt?.label ? opt?.label : ""} //formData?.practiceList?.length?  ((formData?.practiceList)?.find((n) => n?.language === listLang?._id))?.name ? ((formData?.practiceList)?.find((n) => n?.language === listLang?._id))?.name : "" : ""}
                                                                                                    onChange={(e) => {
                                                                                                        var updateOptionsTrash = handleUpdateDefault(formData?.practiceQuestionList, listLang?._id, "options")?.length ? handleUpdateDefault(formData?.practiceQuestionList, listLang?._id, "options") : []
                                                                                                        const updateOptionsVAlue = [...updateOptionsTrash];
                                                                                                        updateOptionsVAlue[oi] = {
                                                                                                            ...updateOptionsVAlue[oi],
                                                                                                            label: e.target.value
                                                                                                        }
                                                                                                        setFormData({
                                                                                                            ...formData,
                                                                                                            practiceQuestionList: formData?.practiceQuestionList?.map((dataUpdate) =>
                                                                                                                dataUpdate?.language === listLang?._id ?
                                                                                                                    ({ ...dataUpdate, options: updateOptionsVAlue })
                                                                                                                    : dataUpdate
                                                                                                            )
                                                                                                        });
                                                                                                    }}
                                                                                                />
                                                                                            }

                                                                                        </FormGroup>
                                                                                    </div>
                                                                                    <div className="col-md-5 col-6">
                                                                                        <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                                                                            <label className="form-label">Answer</label>
                                                                                            {
                                                                                                <select
                                                                                                    className="form-control"
                                                                                                    name="answer"
                                                                                                    value={opt?.isAnswer} //formData?.practiceList?.length?  ((formData?.practiceList)?.find((n) => n?.language === listLang?._id))?.name ? ((formData?.practiceList)?.find((n) => n?.language === listLang?._id))?.name : "" : ""}
                                                                                                    onChange={(e) => {
                                                                                                        var updateOptionsTrash = handleUpdateDefault(formData?.practiceQuestionList, listLang?._id, "options")?.length ? handleUpdateDefault(formData?.practiceQuestionList, listLang?._id, "options") : []
                                                                                                        const updateOptionsVAlue = [...updateOptionsTrash];
                                                                                                        updateOptionsVAlue[oi] = {
                                                                                                            ...updateOptionsVAlue[oi],
                                                                                                            isAnswer: e.target.value
                                                                                                        }
                                                                                                        // console.log(updateOptionsVAlue)
                                                                                                        setFormData({
                                                                                                            ...formData,
                                                                                                            practiceQuestionList: formData?.practiceQuestionList?.map((dataUpdate) =>
                                                                                                                dataUpdate?.language === listLang?._id ?
                                                                                                                    ({ ...dataUpdate, options: updateOptionsVAlue })
                                                                                                                    : dataUpdate
                                                                                                            )
                                                                                                        });
                                                                                                    }}
                                                                                                >
                                                                                                    <option value="">Select</option>
                                                                                                    {
                                                                                                        [{ label: "Yes", value: true }, { label: "No", value: false }]?.map((npa, na) =>
                                                                                                            <option value={npa?.value} key={na}>{npa?.label}</option>
                                                                                                        )
                                                                                                    }
                                                                                                </select>
                                                                                            }

                                                                                        </FormGroup>
                                                                                    </div>
                                                                                    <div className="col-md-2 col-6" style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                                        <FormGroup style={{ width: '100%', padding: '5px', margin: '0px', padding: '0px', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                                            <span
                                                                                                onClick={(e) => {
                                                                                                    var updateOptionsTrash = handleUpdateDefault(formData?.practiceQuestionList, listLang?._id, "options")?.length ? handleUpdateDefault(formData?.practiceQuestionList, listLang?._id, "options") : []
                                                                                                    delete updateOptionsTrash[oi];
                                                                                                    setFormData({
                                                                                                        ...formData,
                                                                                                        practiceQuestionList: formData?.practiceQuestionList?.map((dataUpdate) =>
                                                                                                            dataUpdate?.language === listLang?._id ?
                                                                                                                ({ ...dataUpdate, options: updateOptionsTrash })
                                                                                                                : dataUpdate
                                                                                                        )
                                                                                                    });
                                                                                                }}

                                                                                                style={{ padding: '10px', border: 'solid #ececec 1px', background: "#ececec", color: "red", cursor: 'pointer' }}><Icon name="trash"></Icon></span>
                                                                                        </FormGroup>
                                                                                    </div>
                                                                                </div>
                                                                                : null

                                                                        )
                                                                        : null
                                                                }


                                                            </div>
                                                        </Col>
                                                    </div>
                                                )
                                                : null
                                        }
                                    </Col>

                                    <Col size="12">
                                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                            <li>
                                                <Button color="primary" size="md" type="submit">
                                                    Update
                                                </Button>
                                            </li>
                                            <li>
                                                <a
                                                    href="#cancel"
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        onFormCancel();
                                                    }}
                                                    className="link link-light"
                                                >
                                                    Cancel
                                                </a>
                                            </li>
                                        </ul>
                                    </Col>
                                </Form>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>



                <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
                    <ModalBody>
                        <a
                            href="#cancel"
                            onClick={(ev) => {
                                ev.preventDefault();
                                onFormCancel();
                            }}
                            className="close"
                        >
                            <Icon name="cross-sm"></Icon>
                        </a>
                        <div className="p-2">
                            <h5 className="title">Create Question</h5>
                            <div className="mt-4">
                                <Form className="row gy-4" onSubmit={(e) => onAddSubmit(e, formData)}>


                                    <Col md="12">
                                        <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                            <label className="form-label">Choose Topic</label>
                                            <select
                                                className="form-control"
                                                name="practiceTopic"
                                                required
                                                value={formData?.practiceTopic ? formData?.practiceTopic : ""}
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        practiceTopic: e.target.value
                                                    })
                                                }
                                            >
                                                <option value="">Select</option>
                                                {
                                                    chapterList?.map((n, it) =>
                                                        <option key={it} value={n?._id}>
                                                            {
                                                                n?.translations?.map((npf, nit) => ((n?.translations?.length - 1) === nit) ? npf?.name : npf?.name + " / ")
                                                            }
                                                        </option>
                                                    )
                                                }
                                            </select>
                                        </FormGroup>
                                    </Col>



                                   
                                    <Col md="12">
                                        <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                            <label className="form-label">Is Practice</label>
                                            <select
                                                className="form-control"
                                                name="isPractice"
                                                required
                                                value={formData?.isPractice === 'true'?'true':'false'}
                                                onChange={(e) =>{
                                                    setFormData({
                                                        ...formData,
                                                        isPractice: e?.target?.value === 'true'?'true':'false'
                                                    })
                                                }}
                                            >
                                                <option value="">Select</option>
                                                {
                                                    [{ label: "Yes", value: 'true'}, { label: "No", value: 'false' }]?.map((n, it) => <option key={it} value={n?.value}>{n?.label}</option>)
                                                }
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col md="12">
                                        <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                            <label className="form-label">Is Free</label>
                                            <select
                                                className="form-control"
                                                name="isFree"
                                                required
                                                value={formData?.isFree?'true':'false'}
                                                onChange={(e) =>{
                                                    setFormData({
                                                        ...formData,
                                                        isFree: e?.target?.value==='true'?'true':'false'
                                                    })
                                                }}
                                            >
                                                <option value="">Select</option>
                                                {
                                                    [{ label: "Yes", value: 'true'}, { label: "No", value: 'false' }]?.map((n, it) => <option key={it} value={n?.value}>{n?.label}</option>)
                                                }
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col md="12">
                                        <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                            <label className="form-label">Is Paid</label>
                                            <select
                                                className="form-control"
                                                name="isPaid"
                                                required
                                                value={formData?.isPaid?'true':'false'}
                                                onChange={(e) =>{
                                                    setFormData({
                                                        ...formData,
                                                        isPaid: e?.target?.value?'true':'false'
                                                    })
                                                }}
                                            >
                                                <option value="">Select</option>
                                                {
                                                    [{ label: "Yes", value: 'true'}, { label: "No", value: 'false' }]?.map((n, it) => <option key={it} value={n?.value}>{n?.label}</option>)
                                                }
                                            </select>
                                        </FormGroup>
                                    </Col>

                                


                                    <Col md="12">
                                        <FormGroup style={{ flexDirection: 'column', display: "flex" }}>
                                            <label className="form-label">Image</label>
                                            {
                                                formData?.image ?
                                                    <div style={{  position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <img src={process.env.REACT_APP_IMG_URL + formData?.image} className='img-fluid' alt='image' />
                                                        <button title='Delete' style={{ border: 'solid #df4b0e 1px', width: '45px', height: '45px', position: 'absolute', borderRadius: '100%', background: '#df4b0e', color: '#fff' }} onClick={(e) => {
                                                            if (imageRef1?.current) {
                                                                imageRef1.current.value = "";
                                                            }
                                                            setFormData({ ...formData, image: '' });
                                                        }}>
                                                            <Icon name="trash"></Icon>
                                                        </button>
                                                    </div>
                                                    : null
                                            }
                                            <br />
                                            <input
                                                className="form-control"
                                                type="file"
                                                name="image"
                                                ref={imageRef1}
                                                onChange={async (e) => {

                                                    let chooseFile = e?.target?.files[0] ? e?.target?.files[0] : false;
                                                    if (!chooseFile) {
                                                        toast.error("Please choose file!")
                                                    }
                                                    const formDataUpload = new FormData();
                                                    formDataUpload.append("file", chooseFile);
                                                    await uploadDataAWS(formDataUpload).then((response) => {
                                                        if (response?.success) {
                                                            setFormData({ ...formData, image: response?.path });
                                                            if (imageRef1?.current) {
                                                                imageRef1.current.value = "";
                                                            }
                                                        } else {
                                                            if (imageRef1?.current) {
                                                                imageRef1.current.value = "";
                                                            }
                                                            toast.error(response?.message);
                                                        }

                                                    })
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>



                                    <Col md="12" >
                                        {
                                            languageList?.length ?
                                                languageList?.map((listLang, i) =>
                                                    <div className="row" key={listLang?._id} style={{ margin: "15px 0px", padding: "15px" }}>
                                                        <br />
                                                        <h2 style={{ fontSize: '18px', fontWeight: '600', marginTop: '10px 0px', }}>{(i + 1)}. {listLang?.name ? listLang?.name : ""}</h2>
                                                        <Col md="12">
                                                            <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                                                <label className="form-label">Language</label>
                                                                {
                                                                    <select
                                                                        className="form-control"
                                                                        name="language"
                                                                        required
                                                                        value={handleUpdateDefaultIndex(formData?.practiceQuestionList, i, "language")} //formData?.practiceList?.length?  ((formData?.practiceList)?.find((n) => n?.language === listLang?._id))?.name ? ((formData?.practiceList)?.find((n) => n?.language === listLang?._id))?.name : "" : ""}
                                                                        onChange={(e) =>
                                                                            setFormData({
                                                                                ...formData,
                                                                                practiceQuestionList: formData?.practiceQuestionList?.map((dataUpdate, opt) =>
                                                                                    opt === i ?
                                                                                        ({ ...dataUpdate, language: e.target.value })
                                                                                        : dataUpdate
                                                                                )
                                                                            })
                                                                        }
                                                                    >
                                                                        <option value="">Select Language</option>
                                                                        {
                                                                            languageList?.map((n, it) => <option key={it} value={n?._id}>{n?.name ? n?.name : ""}</option>)
                                                                        }
                                                                    </select>
                                                                }

                                                            </FormGroup>

                                                            <br />
                                                        </Col>

                                                        <Col md="12">
                                                            <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                                                <label className="form-label">Question Content</label>
                                                                {

                                                                    <FullTextEditor editorValue={handleUpdateDefaultIndex(formData?.practiceQuestionList, i, "content")} onChangeValue={(e) => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            practiceQuestionList: formData?.practiceQuestionList?.map((dataUpdate, opt) =>
                                                                                opt === i ?
                                                                                    ({ ...dataUpdate, content: e })
                                                                                    : dataUpdate
                                                                            )
                                                                        });
                                                                    }} />
                                                                }

                                                            </FormGroup>
                                                            <br />
                                                        </Col>

                                                        <Col md="12">
                                                            <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                                                <label className="form-label">Answer Description</label>
                                                                {

                                                                    <FullTextEditor editorValue={handleUpdateDefaultIndex(formData?.practiceQuestionList, i, "answerDescription")} onChangeValue={(e) => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            practiceQuestionList: formData?.practiceQuestionList?.map((dataUpdate, opt) =>
                                                                                opt === i ?
                                                                                    ({ ...dataUpdate, answerDescription: e })
                                                                                    : dataUpdate
                                                                            )
                                                                        });
                                                                    }} />
                                                                }

                                                            </FormGroup>
                                                        </Col>


                                                        <Col md="12">
                                                            <br />
                                                            <label className="form-label">Options</label>
                                                            <div className="row justify-content-between" style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                                                <div className="col-12 text-right">
                                                                    <span onClick={(e) => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            practiceQuestionList: formData?.practiceQuestionList?.map((dataUpdate, opt) =>
                                                                                opt === i ?
                                                                                    ({ ...dataUpdate, options: [...(dataUpdate?.options), ({ label: "", isAnswer: false })] })
                                                                                    : dataUpdate
                                                                            )
                                                                        });
                                                                    }} style={{ cursor: 'pointer', width: '100%', padding: '10px 15px', border: 'solid #ececec 1px', background: "#ececec", color: "red" }}>+Add Option</span>
                                                                </div>

                                                                {
                                                                    handleUpdateDefaultIndex(formData?.practiceQuestionList, i, "options")?.length ?
                                                                        handleUpdateDefaultIndex(formData?.practiceQuestionList, i, "options")?.map((opt, oi) =>
                                                                            opt ?
                                                                                <div className="row justify-content-between" key={oi} style={{ alignItems: 'center', margin: "15px 0px", width: '100%' }}>
                                                                                    <div className="col-md-5 col-12">
                                                                                        <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                                                                            <label className="form-label">Label</label>
                                                                                            {
                                                                                                <input
                                                                                                    className="form-control"
                                                                                                    name="label"
                                                                                                    value={opt?.label ? opt?.label : ""} //formData?.practiceList?.length?  ((formData?.practiceList)?.find((n) => n?.language === listLang?._id))?.name ? ((formData?.practiceList)?.find((n) => n?.language === listLang?._id))?.name : "" : ""}
                                                                                                    onChange={(e) => {
                                                                                                        var updateOptionsTrash = handleUpdateDefaultIndex(formData?.practiceQuestionList, i, "options")?.length ? handleUpdateDefaultIndex(formData?.practiceQuestionList, i, "options") : []
                                                                                                        const updateOptionsVAlue = [...updateOptionsTrash];
                                                                                                        updateOptionsVAlue[oi] = {
                                                                                                            ...updateOptionsVAlue[oi],
                                                                                                            label: e.target.value
                                                                                                        }
                                                                                                        setFormData({
                                                                                                            ...formData,
                                                                                                            practiceQuestionList: formData?.practiceQuestionList?.map((dataUpdate, opt) =>
                                                                                                                opt === i ?
                                                                                                                    ({ ...dataUpdate, options: updateOptionsVAlue })
                                                                                                                    : dataUpdate
                                                                                                            )
                                                                                                        });

                                                                                                    }}
                                                                                                />
                                                                                            }

                                                                                        </FormGroup>
                                                                                    </div>
                                                                                    <div className="col-md-5 col-6">
                                                                                        <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                                                                            <label className="form-label">Answer</label>
                                                                                            {
                                                                                                <select
                                                                                                    className="form-control"
                                                                                                    name="answer"
                                                                                                    value={opt?.isAnswer} //formData?.practiceList?.length?  ((formData?.practiceList)?.find((n) => n?.language === listLang?._id))?.name ? ((formData?.practiceList)?.find((n) => n?.language === listLang?._id))?.name : "" : ""}
                                                                                                    onChange={(e) => {
                                                                                                        var updateOptionsTrash = handleUpdateDefaultIndex(formData?.practiceQuestionList, i, "options")?.length ? handleUpdateDefaultIndex(formData?.practiceQuestionList, i, "options") : []
                                                                                                        const updateOptionsVAlue = [...updateOptionsTrash];
                                                                                                        updateOptionsVAlue[oi] = {
                                                                                                            ...updateOptionsVAlue[oi],
                                                                                                            isAnswer: e.target.value
                                                                                                        }
                                                                                                        setFormData({
                                                                                                            ...formData,
                                                                                                            practiceQuestionList: formData?.practiceQuestionList?.map((dataUpdate, opt) =>
                                                                                                                opt === i ?
                                                                                                                    ({ ...dataUpdate, options: updateOptionsVAlue })
                                                                                                                    : dataUpdate
                                                                                                            )
                                                                                                        });
                                                                                                    }}
                                                                                                >
                                                                                                    <option value="">Select</option>
                                                                                                    {
                                                                                                        [{ label: "Yes", value: true }, { label: "No", value: false }]?.map((npa, na) =>
                                                                                                            <option value={npa?.value} key={na}>{npa?.label}</option>
                                                                                                        )
                                                                                                    }
                                                                                                </select>
                                                                                            }

                                                                                        </FormGroup>
                                                                                    </div>
                                                                                    <div className="col-md-2 col-6" style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                                        <FormGroup style={{ width: '100%', padding: '5px', margin: '0px', padding: '0px', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                                            <span
                                                                                                onClick={(e) => {
                                                                                                    var updateOptionsTrash = handleUpdateDefaultIndex(formData?.practiceQuestionList, i, "options")?.length ? handleUpdateDefaultIndex(formData?.practiceQuestionList, i, "options") : []
                                                                                                    delete updateOptionsTrash[oi];


                                                                                                    setFormData({
                                                                                                        ...formData,
                                                                                                        practiceQuestionList: formData?.practiceQuestionList?.map((dataUpdate, opt) =>
                                                                                                            opt === i ?
                                                                                                                ({ ...dataUpdate, options: updateOptionsTrash })
                                                                                                                : dataUpdate
                                                                                                        )
                                                                                                    });
                                                                                                }}

                                                                                                style={{ padding: '10px', border: 'solid #ececec 1px', background: "#ececec", color: "red", cursor: 'pointer' }}><Icon name="trash"></Icon></span>
                                                                                        </FormGroup>
                                                                                    </div>
                                                                                </div>
                                                                                : null

                                                                        )
                                                                        : null
                                                                }


                                                            </div>
                                                        </Col>
                                                    </div>
                                                )
                                                : null
                                        }
                                    </Col>

                                    <Col size="12">
                                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                            <li>
                                                <Button color="primary" size="md" type="submit">
                                                    Create
                                                </Button>
                                            </li>
                                            <li>
                                                <a
                                                    href="#cancel"
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        onFormCancel();
                                                    }}
                                                    className="link link-light"
                                                >
                                                    Cancel
                                                </a>
                                            </li>
                                        </ul>
                                    </Col>
                                </Form>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>


                <Modal isOpen={isBulkModel} toggle={() => setBulkModel(isBulkModel ? false : true)} className="modal-dialog-centered" size="lg">
                    <ModalBody>
                        <a
                            href="#cancel"
                            onClick={(ev) => {
                                ev.preventDefault();
                                document.getElementById("resetForm2").reset();
                                setBulkModel(isBulkModel ? false : true)
                            }}
                            className="close"
                        >
                            <Icon name="cross-sm"></Icon>
                        </a>
                        <div className="p-2">
                            <h5 className="title">Create Bulk Question</h5>
                            <div className="mt-4">
                                <Form className="row gy-4" id='resetForm2' onSubmit={async (e) => {
                                    e.preventDefault();
                                    const { topic, file } = e.target.elements;
                                    if (!file?.files?.length) {
                                        toast.error("File Required!");
                                        return false;
                                    }
                                    const formDataUpload1 = new FormData();
                                    formDataUpload1.append("file", file.files[0]);
                                    formDataUpload1.append("topic", topic.value);
                                    if (document?.querySelector("#resetForm2 button")) {
                                        document.querySelector("#resetForm2 button").disabled = true
                                    }

                                    await bulkCreateData(formDataUpload1).then(async (response) => {
                                        if (response?.success) {
                                            toast.success(response?.message);
                                            document.getElementById("resetForm2").reset();
                                            if (document?.querySelector("#resetForm2 button")) {
                                                document.querySelector("#resetForm2 button").disabled = false
                                            }
                                            setBulkModel(isBulkModel ? false : true)


                                            await fetchUserList(paramsHistory);
                                        } else {
                                            toast.error(response?.message);
                                            if (document?.querySelector("#resetForm2 button")) {
                                                document.querySelector("#resetForm2 button").disabled = false
                                            }
                                        }

                                    })
                                }}>
                                    <Col md="12">
                                        <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                            <label className="form-label">Choose Topic</label>
                                            <select
                                                className="form-control"
                                                name="topic"
                                                required

                                            >
                                                <option value="">Select</option>
                                                {
                                                    chapterList?.map((n, it) =>
                                                        <option key={it} value={n?._id}>
                                                            {
                                                                n?.translations?.map((npf, nit) => ((n?.translations?.length - 1) === nit) ? npf?.name : npf?.name + " / ")
                                                            }
                                                        </option>
                                                    )
                                                }
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col md="12">
                                        <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                                            <label className="form-label">Choose Excel FIle</label>
                                            <br />
                                            <input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" className="form-control" type='file' required name='file' />
                                        </FormGroup>
                                    </Col>
                                    <Col size="12">
                                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                            <li>
                                                <Button color="primary" size="md" type="submit">
                                                    Create
                                                </Button>
                                            </li>
                                            <li>
                                                <a
                                                    href="#cancel"
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        setBulkModel(isBulkModel ? false : true)
                                                    }}
                                                    className="link link-light"
                                                >
                                                    Cancel
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="Sample-Question-Excel-File.xlsx"
                                                    download
                                                    className="link link-light"
                                                >
                                                    Download Sample File
                                                </a>
                                            </li>
                                        </ul>
                                    </Col>
                                </Form>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </Content>
        </React.Fragment>
    );
};
export default QuestionLists;

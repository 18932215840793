import React, { useContext, useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import toast from 'react-hot-toast';
import {
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  DropdownItem,
  Form,
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  UserAvatar,
  PaginationComponent,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
} from "../../../components/Component";

import { deleteMethod, getMethod, postMethod, putMethod, setAuthorizationToken } from "../../../utils/apiServices";
import moment from "moment";
import { uploadDataAWS } from "../../../utils/action";
import { useRef } from "react";
import { slugify } from "../../../utils/Utils";

const ChaptersList = () => {
  const [sm, updateSm] = useState(false)
  const [data, setData] = useState([]);
  const [totals, setTotals] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [paramsHistory, setParamsHistory] = useState({});
  const [editId, setEditedId] = useState("");
  const [languageList, setLanguageList] = useState([]);
  const imageRef1 = useRef();

  const [formData, setFormData] = useState({
    icon: "",
    isIncludeForPaidTest:false,
    practiceList: [
      {
        PracticeChapterTranslation: "",
        name: "",
        slug:"",
        language: "",
      },
      {
        PracticeChapterTranslation: "",
        name: "",
        slug:"",
        language: "",
      }
    ]
  });


  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });


  const resetForm = () => {
    setEditedId("");
    setFormData({
      icon: "",
      isIncludeForPaidTest:false,
      practiceList: [
        {
          PracticeChapterTranslation: "",
          name: "",
          slug:"",
          language: "",
        },
        {
          PracticeChapterTranslation: "",
          name: "",
          slug:"",
          language: "",
        }
      ]
    });
    if (imageRef1.current) {
      imageRef1.current.value = "";
    }
  };


  const onFormCancel = () => {
    setModal({ edit: false, add: false });
    resetForm();
  };

  const updateAllSlug=(value, key, array)=>{
    for (let i = 0; i < array.length; i++) {
      if (array[i][key]) {
        array[i][key] = value;
      }
    }
    return array;
  }


  const paginate = async (pageNumber) => {
    setPage(pageNumber);
    var updateParams = paramsHistory;
    updateParams = Object.assign(updateParams, { page: pageNumber });
    await fetchUserList(updateParams);
  };





  const deleteUser = async (id, index) => {
    await deleteMethod('admin/practice/chapters/' + id).then((response) => {
      if (response?.success) {
        toast.success(response.message)
        data.splice(index, 1);
        setData([...data])
      } else {
        toast.error(response.message)
      }
    })
  }


  const fetchUserList = async (params) => {
    setParamsHistory(params);

    setData([]);
    await getMethod('admin/practice/chapters', params).then((response) => {
      if (response?.success) {
        setTotals(response?.total ? response?.total : 0)
        setData(response.data);
      }
    })
  }


  const onEditSubmit = async (e, submitData) => {
    e.preventDefault();
    if (!editId) {
      toast.error("Edit Id required!");
    }
    if (submitData) {
      var modifledData = {...submitData, isIncludeForPaidTest: submitData?.isIncludeForPaidTest === 'True'?true:false}
      await putMethod('admin/practice/chapters/' + editId, modifledData).then(async (response) => {
        if (response?.success) {
          setModal({ edit: false, add: false });
          resetForm();
          toast.success(response.message);
          await fetchUserList(paramsHistory);
        } else {
          toast.error(response.message)
        }
      })
    } else {
      toast.error("Required Feild missing!")
    }
  };

  const onAddSubmit = async (e, submitData) => {
    e.preventDefault();
    if (submitData) {
      var modifledData = {...submitData, isIncludeForPaidTest: submitData?.isIncludeForPaidTest === 'True'?true:false}
      await postMethod('admin/practice/chapters', modifledData).then(async (response) => {
        if (response?.success) {
          setModal({ edit: false, add: false });
          resetForm();
          toast.success(response.message);
          await fetchUserList(paramsHistory);
        } else {
          toast.error(response.message)
        }
      })
    } else {
      toast.error("Required Feild missing!")
    }
  };
  const handleUpdateDefault = (array, checkId, key) => {
    let findIndex = array?.findIndex((n) => n?.language === checkId);
    return array[findIndex]?.[key] ? array[findIndex]?.[key] : ""
  }

  const handleUpdateDefaultIndex = (array, index, key) => {
    let findIndex = array?.findIndex((n,i) => i === index);
    return array[findIndex]?.[key] ? array[findIndex]?.[key] : ""
  }
  
  useEffect(() => {
    setAuthorizationToken(true)
    fetchUserList({ page: page, limit: limit });
    getMethod('admin/languages').then((response) => {
      if (response?.success) {
        setLanguageList(response?.data?.length ? response?.data : [])
      }
    })
  }, [page, limit]);


  return (
    <React.Fragment>
      <Head title="User List - Regular"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Chapters
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {totals} chapters.</p>
              </BlockDes>
            </BlockHeadContent>

            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <Button color="primary" className="btn-icon" onClick={() => setModal({ add: true })}>
                        <Icon name="plus"></Icon>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <DataTable className="card-stretch">

            <DataTableBody>
              <DataTableHead>

                <DataTableRow size="mb" className="nk-tb-col-check">
                  <span className="sub-text">Icon</span>
                </DataTableRow>
                <DataTableRow size="mb">
                  <span className="sub-text text-left">Name</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text text-left">Slug</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text text-left">Is Included For Paid/Free Test</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text text-center">Created Date</span>
                </DataTableRow>

                <DataTableRow size='mb' className="nk-tb-col-tools text-right">
                  <span className="sub-text text-right">More</span>
                </DataTableRow>
              </DataTableHead>

              {data.length > 0
                ? data.map((item, indexm) => {
                  return (
                    <DataTableItem key={item?._id}>

                      <DataTableRow size="mb" className="nk-tb-col-check">
                        <div className="user-card">
                          {
                            item?.icon ?
                              <UserAvatar size="md" theme="light" text={''} image={process.env.REACT_APP_IMG_URL + item?.icon}></UserAvatar>

                              : <span className="sub-text">NA</span>
                          }
                        </div>
                      </DataTableRow>

                      <DataTableRow size="mb" className="text-left">
                        {
                          item?.translations?.length ?
                            item?.translations?.map((n, i) =>
                              <span className={`text-left`} key={i}>
                                {n?.name ? n?.name : "NA"}
                                <br />
                              </span>
                            )
                            : null
                        }

                      </DataTableRow>





                      <DataTableRow size="lg" className="text-left">
                        {
                          item?.translations?.length ?
                            item?.translations?.map((n, i) =>
                              <span className={`text-left`} key={i}>
                                {n?.slug ? n?.slug : "NA"}
                                <br />
                              </span>
                            )
                            : null
                        }
                      </DataTableRow>
                      <DataTableRow className="text-center" size="md">
                        <span>{item?.isIncludeForPaidTest ? 'Yes' : "No"}</span>
                      </DataTableRow>
                      
                      <DataTableRow className="text-center" size="md">
                        <span>{item.createdAt ? moment(item?.createdAt).format("YYYY-MM-DD, HH:mm A") : "NA"}</span>
                      </DataTableRow>

                      <DataTableRow size='mb' className="nk-tb-col-tools">
                        <ul className="nk-tb-actions gx-1">

                          <li>
                            <UncontrolledDropdown>
                              <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                <Icon name="more-h"></Icon>
                              </DropdownToggle>
                              <DropdownMenu right>
                                <ul className="link-list-opt no-bdr">
                                  <li onClick={() => {
                                    setEditedId(item?._id ? item?._id : "");
                                    var updateDataMap = ({
                                      icon: item?.icon ? item?.icon : "",
                                      isIncludeForPaidTest: item?.isIncludeForPaidTest?'True':'False',
                                      practiceList: item?.translations?.length ?
                                        item?.translations?.map((n) => ({ PracticeChapterTranslation: n?._id ? n?._id : "",slug:item?.translations[0]?.slug, name: n?.name ? n?.name : "", language: n?.language ? n?.language : "" }))
                                        : [
                                          {
                                            PracticeChapterTranslation: "",
                                            name: "",
                                            slug:"",
                                            language: "",
                                          },
                                          {
                                            PracticeChapterTranslation: "",
                                            name: "",
                                            slug:"",
                                            language: "",
                                          }
                                        ]
                                    })
                                    setFormData(updateDataMap);
                                    setModal({ edit: true })
                                  }}>
                                    <DropdownItem
                                      tag="a"
                                      href="#edit"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      <Icon name="edit"></Icon>
                                      <span>Edit</span>
                                    </DropdownItem>
                                  </li>
                                  <li onClick={() => deleteUser(item?._id ? item?._id : "", indexm)}>
                                    <DropdownItem
                                      tag="a"
                                      href="#suspend"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      <Icon name="delete"></Icon>
                                      <span>Delete</span>
                                    </DropdownItem>
                                  </li>
                                  <li>
                                    <DropdownItem
                                      tag="a"
                                      href={"/topics?id=" + item?._id}

                                    >
                                      <Icon name="eye"></Icon>
                                      <span>View Topics</span>
                                    </DropdownItem>
                                  </li>

                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </li>
                        </ul>
                      </DataTableRow>
                    </DataTableItem>
                  );
                })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {totals > 0 ? (
                <PaginationComponent
                  itemPerPage={limit}
                  totalItems={totals}
                  paginate={async (n) => await paginate(n)}
                  currentPage={page}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>





        <Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Update Chapter</h5>
              <div className="mt-4">
                <Form className="row gy-4" onSubmit={(e)=>onEditSubmit(e, formData)}>
                  <Col md="6">
                    <FormGroup >
                      <label className="form-label">Chapter Icon</label>
                      {
                        formData?.icon ?
                          <UserAvatar size="xl" theme="light" text={''} image={process.env.REACT_APP_IMG_URL + formData?.icon}></UserAvatar>
                          : null
                      }
                      <br />
                      <input
                        className="form-control"
                        type="file"
                        name="icon"
                        ref={imageRef1} 
                        onChange={async (e) => {

                          let chooseFile = e?.target?.files[0] ? e?.target?.files[0] : false;
                          if (!chooseFile) {
                            toast.error("Please choose file!")
                          }
                          const formDataUpload = new FormData();
                          formDataUpload.append("file", chooseFile);
                          await  uploadDataAWS(formDataUpload).then((response) => {
                            if (response?.success) {
                              setFormData({ ...formData, icon: response?.path });
                              if (imageRef1.current) {
                                imageRef1.current.value = "";
                            }
                            } else {
                              if (imageRef1.current) {
                                imageRef1.current.value = "";
                            }
                              toast.error(response?.message);
                            }
                           
                          })
                        }}
                      />
                    </FormGroup>
                  </Col>

                
                  <Col md="6">
                    <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                      <label className="form-label">Is Include For Paid/Free Test</label>

                      <select
                                  className="form-control"
                                  name="practiceCincludehapter"
                                  required
                                  value={formData?.isIncludeForPaidTest} //formData?.practiceList?.length?  ((formData?.practiceList)?.find((n) => n?.language === listLang?._id))?.name ? ((formData?.practiceList)?.find((n) => n?.language === listLang?._id))?.name : "" : ""}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      isIncludeForPaidTest:e.target.value
                                    })
                                  }
                                >
                                  {[{ value:'True', label:"Yes"},{value:'False',label:"No"}]?.map((fh, fi)=><option key={fi} value={fh.value}>{fh?.label}</option>)}
                                </select>
                    </FormGroup>
                  </Col>


                  <Col md="6">
                    <FormGroup >
                      <label className="form-label">Slug</label>
                      <br />
                      <input
                        className="form-control"
                        type="text"
                        name="slug"
                        required
                        value={formData?.practiceList[0]?.slug?formData?.practiceList[0]?.slug:""}
                        onChange={async (e) => {
                          setFormData({
                            ...formData,
                            practiceList: formData?.practiceList?.map((nd)=>({...nd, slug:slugify(e.target.value)}))   //updateAllSlug(slugify(e.target.value), 'slug', formData?.practiceList)
                          })
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    {
                      languageList?.length ?
                        languageList?.map((listLang, i) =>
                          <fieldset key={listLang?._id} style={{ margin: "15px 0px", display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                            <legend style={{ fontSize: '18px', fontWeight: '600' }}>{(i + 1)}. {listLang?.name ? listLang?.name : ""}</legend>
                            <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                              <label className="form-label">Name</label>
                              <textarea
                                className="form-control"
                                type="text"
                                name="name"
                                required
                                value={handleUpdateDefault(formData?.practiceList, listLang?._id, "name")} //formData?.practiceList?.length?  ((formData?.practiceList)?.find((n) => n?.language === listLang?._id))?.name ? ((formData?.practiceList)?.find((n) => n?.language === listLang?._id))?.name : "" : ""}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    practiceList: formData?.practiceList?.map((dataUpdate) =>
                                      dataUpdate?.language === listLang?._id ?
                                        ({ ...dataUpdate, name: e.target.value })
                                        : dataUpdate
                                    )
                                  })
                                }
                                placeholder={"Write..."}

                              ></textarea>
                              
                            </FormGroup>

                            <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                              <label className="form-label">Language</label>
                              {
                                <select
                                  className="form-control"
                                  name="language"
                                  required
                                  
                                  value={handleUpdateDefault(formData?.practiceList, listLang?._id, "language")} //formData?.practiceList?.length?  ((formData?.practiceList)?.find((n) => n?.language === listLang?._id))?.name ? ((formData?.practiceList)?.find((n) => n?.language === listLang?._id))?.name : "" : ""}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      practiceList: formData?.practiceList?.map((dataUpdate) =>
                                        dataUpdate?.language === listLang?._id ?
                                          ({ ...dataUpdate, language: e.target.value })
                                          : dataUpdate
                                      )
                                    })
                                  }
                                >
                                  <option value="">Select Language</option>
                                  {
                                    languageList?.map((n, it) => <option key={it} value={n?._id}>{n?.name ? n?.name : ""}</option>)
                                  }
                                </select>
                              }
                              {/* {errors?.language[i] && <span className="invalid">{errors?.language[i]?.message}</span>} */}
                            </FormGroup>
                          </fieldset>
                        )
                        : null
                    }
                  </Col>












                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                          Update
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>



        <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
        
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Create Chapter</h5>
              <div className="mt-4">
                <Form className="row gy-4" onSubmit={(e)=>onAddSubmit(e, formData)}>
                  <Col md="6">
                    <FormGroup >
                      <label className="form-label">Chapter Icon</label>
                      {
                        formData?.icon ?
                          <UserAvatar size="xl" theme="light" text={''} image={process.env.REACT_APP_IMG_URL + formData?.icon}></UserAvatar>
                          : null
                      }
                      <br />
                      <input
                        className="form-control"
                        type="file"
                        name="icon"
                        ref={imageRef1} 
                        onChange={async (e) => {

                          let chooseFile = e?.target?.files[0] ? e?.target?.files[0] : false;
                          if (!chooseFile) {
                            toast.error("Please choose file!")
                          }
                          const formDataUpload = new FormData();
                          formDataUpload.append("file", chooseFile);
                          await  uploadDataAWS(formDataUpload).then((response) => {
                            if (response?.success) {
                              setFormData({ ...formData, icon: response?.path });
                              if (imageRef1.current) {
                                imageRef1.current.value = "";
                            }
                            } else {
                              if (imageRef1.current) {
                                imageRef1.current.value = "";
                            }
                              toast.error(response?.message);
                            }
                           
                          })
                        }}
                      />
                    </FormGroup>
                  </Col>
   
                  <Col md="6">
                    <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                      <label className="form-label">Is Include For Paid/Free Test</label>

                      <select
                                  className="form-control"
                                  name="practiceCincludehapter"
                                  required
                                  value={formData?.isIncludeForPaidTest==='True'?true:false} //formData?.practiceList?.length?  ((formData?.practiceList)?.find((n) => n?.language === listLang?._id))?.name ? ((formData?.practiceList)?.find((n) => n?.language === listLang?._id))?.name : "" : ""}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      isIncludeForPaidTest:e.target.value === 'True'?true:false
                                    })
                                  }
                                >
                                  {[{ value:'True', label:"Yes"},{value:'False',label:"No"}]?.map((fh, fi)=><option key={fi} value={fh.value}>{fh?.label}</option>)}
                                </select>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup >
                      <label className="form-label">Slug</label>
                      <br />
                      <input
                        className="form-control"
                        type="text"
                        required
                        name="slug"
                        value={formData?.practiceList[0]?.slug?formData?.practiceList[0]?.slug:""}
                        onChange={async (e) => {
                          
                          setFormData({
                            ...formData,
                            practiceList: formData?.practiceList?.map((nd)=>({...nd, slug:slugify(e.target.value)}))   //updateAllSlug(slugify(e.target.value), 'slug', formData?.practiceList)
                          })
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    {
                      languageList?.length ?
                        languageList?.map((listLang, i) =>
                          <fieldset key={listLang?._id} style={{ margin: "15px 0px", display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                            <legend style={{ fontSize: '18px', fontWeight: '600' }}>{(i + 1)}. {listLang?.name ? listLang?.name : ""}</legend>
                            <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                              <label className="form-label">Name</label>
                              <textarea
                                className="form-control"
                                type="text"
                                name="name"
                                required
                                value={handleUpdateDefaultIndex(formData?.practiceList, i, "name")} //formData?.practiceList?.length?  ((formData?.practiceList)?.find((n) => n?.language === listLang?._id))?.name ? ((formData?.practiceList)?.find((n) => n?.language === listLang?._id))?.name : "" : ""}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    practiceList: formData?.practiceList?.map((dataUpdate, pracIndex) =>
                                      pracIndex === i?
                                        ({ ...dataUpdate, name: e.target.value })
                                        : dataUpdate
                                    )
                                  })
                                }
                                placeholder={"Write..."}

                              ></textarea>
                              
                            </FormGroup>

                            <FormGroup style={{ width: '100%', padding: '5px', margin: '0px' }}>
                              <label className="form-label">Language</label>
                              {
                                <select
                                  className="form-control"
                                  name="language"
                                  required
                                  
                                  value={handleUpdateDefaultIndex(formData?.practiceList, i, "language")} //formData?.practiceList?.length?  ((formData?.practiceList)?.find((n) => n?.language === listLang?._id))?.name ? ((formData?.practiceList)?.find((n) => n?.language === listLang?._id))?.name : "" : ""}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    practiceList: formData?.practiceList?.map((dataUpdate, pracIndex) =>
                                    pracIndex === i?
                                        ({ ...dataUpdate, language: e.target.value })
                                        : dataUpdate
                                    )
                                  })
                                }
                                >
                                  <option value="">Select Language</option>
                                  {
                                    languageList?.map((n, it) => <option key={it} value={n?._id}>{n?.name ? n?.name : ""}</option>)
                                  }
                                </select>
                              }
                              {/* {errors?.language[i] && <span className="invalid">{errors?.language[i]?.message}</span>} */}
                            </FormGroup>
                          </fieldset>
                        )
                        : null
                    }
                  </Col>












                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                          Create
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};
export default ChaptersList;

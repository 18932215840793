const menu = [
  {
    text: "Dashboard",
    subMenu: [
      {
        text: "Users",
        link: "/users"
      },
      {
        text: "Subscriptions",
        link: "/subscriptions",
      },
      {
        text: "Contact Us",
        link: "/contact-us",
      },
    ],
  },

  {
    text: "Practice",
    subMenu: [
      {
        text: "Chapters",
        link: "/chapters",
      },
      {
        text: "Topics",
        link: "/topics",
      },
      {
        text: "Questions",
        link: "/questions",
      },
    ],
  },
  {
    text: "Learning",
    subMenu: [
      {
        text: "Learning Chapters",
        link: "/learning-chapters"
      },
      {
        text: "Learning Topics",
        link: "/learning-topics"
      },
      {
        text: "Learning Words",
        link: "/learning-words"
      }
    ],
  },
  {
    text: "Tests",
    subMenu: [
      {
        text: "Paid Test",
        link: "/paid-tests"
      }
    ],
  },
  {
    text: "Driving Schools",
    subMenu: [
      {
        text: "States",
        link: "/states"
      },
      {
        text: "Cities",
        link: "/cities"
      },
      {
        text: "Tags",
        link: "/tags"
      },
      {
        text: "Driving Schools",
        link: "/driving-schools"
      }
    ],
  },
  {
    text: "Web Page Content",
    subMenu: [
      {
        text: "Faq's",
        link: "/faqs"
      },
      {
        text: "Testimonials",
        link: "/testimonials"
      },
      {
        text: "Languages",
        link: "/languages"
      },
      {
        text: "Test Format",
        link: '/test-formats'
      },
      {
        text: "Plans",
        link: "/plans"
      }
    ],
  },
];
export default menu;

import React, { useContext, useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import toast from 'react-hot-toast';
import jsPDF from 'jspdf';
import {
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledDropdown,
  DropdownItem,
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  UserAvatar,
  PaginationComponent,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
} from "../../../components/Component";

import { deleteMethod, getMethod, setAuthorizationToken } from "../../../utils/apiServices";
import moment from "moment";
import { useLocation } from "react-router";
import { invoice } from "./Invoice";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const SubscriptionListRegular = () => {
  const router = useQuery();
  const [data, setData] = useState([]);
  const [totals, setTotals] = useState(0);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(false);
  const [onSearchText, setSearchText] = useState("");

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [startDate, setStartDate] = useState(moment().startOf("month").format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [paramsHistory, setParamsHistory] = useState({});
  const toggle = () => setonSearch(!onSearch);




  const downloadInvoice = async(data) => {
    const returnHtml = invoice(data);
    const doc = new jsPDF({
			format: 'a5',
			unit: 'px',
		});
		doc.html(returnHtml, {
			async callback(doc) {
				await doc.save((((data?.user?.name).replaceAll(" ", "-")).toLowerCase())+'-invoice.pdf');
			},
		});
  }








  const paginate = async (pageNumber) => {
    setPage(pageNumber);
    var updateParams = paramsHistory;
    updateParams = Object.assign(updateParams, { page: pageNumber });
    await fetchUserList(updateParams);
  };


  const onFilterChange = async (e) => {
    if (e?.target.value) {
      setSearchText(e.target.value);
      await fetchUserList({ search: (e.target.value).toLowerCase(), page: page, limit: limit })
    } else {
      setSearchText("");
      await fetchUserList({ page: page, limit: limit });
    }
  };


  const deleteUser = async (id, index) => {
    await deleteMethod('admin/subscriptions/' + id + '/delete').then((response) => {
      if (response?.success) {
        toast.success(response.message)
        data.splice(index, 1);
        setData([...data])
      } else {
        toast.error(response.message)
      }
    })
  }


  const fetchUserList = async (params) => {
    setParamsHistory(params);

    let updateParams = params;

    if (router?.get("user")) {
      updateParams.user = router.get('user');
    }
    await getMethod('admin/subscriptions', updateParams).then((response) => {
      if (response?.success) {
        setTotals(response?.total ? response?.total : 0)
        setData(response.data);
      }
    })
  }





  useEffect(() => {
    setAuthorizationToken(true)
    let params = { page: page, limit: limit }
    if (router?.get("user")) {
      params.user = router.get('user');
    }
    fetchUserList(params);
  }, [page, limit]);


  return (
    <React.Fragment>
      <Head title="Subscriptions"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Subscription Lists
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {totals} subscriptions.</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <DataTable className="card-stretch">
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
                <div className="card-tools">

                </div>
                <div className="card-tools mr-n1">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <li>
                      <div className="toggle-wrap">
                        <Button
                          className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                          onClick={() => updateTableSm(true)}
                        >
                          <Icon name="menu-right"></Icon>
                        </Button>
                        <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                          <ul className="btn-toolbar gx-1">
                            <li className="toggle-close">
                              <Button className="btn-icon btn-trigger toggle" onClick={() => updateTableSm(false)}>
                                <Icon name="arrow-left"></Icon>
                              </Button>
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <div className="dot dot-primary"></div>
                                  <Icon name="filter-alt"></Icon>
                                </DropdownToggle>
                                <DropdownMenu
                                  right
                                  className="filter-wg dropdown-menu-xl"
                                  style={{ overflow: "visible" }}
                                >
                                  <div className="dropdown-head">
                                    <span className="sub-title dropdown-title">Filter</span>
                                    <div className="dropdown">
                                      <a
                                        href="#more"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                        className="btn btn-sm btn-icon"
                                      >
                                        <Icon name="more-h"></Icon>
                                      </a>
                                    </div>
                                  </div>
                                  <div className="dropdown-body dropdown-body-rg">
                                    <label>According Created</label>
                                    <Row className="gx-6 gy-3">
                                      <Col size="6">
                                        <FormGroup>
                                          <label className="overline-title overline-title-alt">Start Date</label>
                                          <input type='date' value={startDate} onChange={(e) => { setStartDate(e.target.value) }} placeholder="DD/MM/YYYY" />
                                        </FormGroup>
                                      </Col>
                                      <Col size="6">
                                        <FormGroup>
                                          <label className="overline-title overline-title-alt">End Date</label>
                                          <input type='date' value={endDate} onChange={(e) => { setEndDate(e.target.value) }} placeholder="DD/MM/YYYY" />
                                        </FormGroup>
                                      </Col>
                                      <Col size="12">
                                        <FormGroup className="form-group" style={{ justifyContent: 'space-between', alignItems: 'center', display: 'flex' }}>
                                          <button type="button" onClick={async (e) => {
                                            await fetchUserList({ startDate: startDate, endDate: endDate, page: page, limit: limit });
                                          }} className="btn btn-secondary">
                                            Filter
                                          </button>

                                          <a
                                            href="#reset"
                                            onClick={async (ev) => {
                                              ev.preventDefault();
                                              setSearchText("");

                                              setLimit(10);
                                              setPage(1);
                                              setStartDate("");
                                              setEndDate("");
                                              await fetchUserList({ search: "", page: 1, limit: 10, startDate: "", endDate: "" });
                                            }}
                                            className="clickable"
                                          >
                                            Reset Filter
                                          </a>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </div>

                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle color="tranparent" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <Icon name="setting"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right className="dropdown-menu-xs">
                                  <ul className="link-check">
                                    <li>
                                      <span>Show</span>
                                    </li>
                                    {[10, 50, 100, 500, 1000]?.map((n, index) =>
                                      <li key={index} className={limit === n ? "active" : ""}>
                                        <DropdownItem
                                          tag="a"
                                          href="#dropdownitem"
                                          onClick={async (ev) => {
                                            ev.preventDefault();
                                            setLimit(n);
                                            await fetchUserList({ page: page, limit: n })
                                          }}
                                        >
                                          {n}
                                        </DropdownItem>
                                      </li>
                                    )}
                                  </ul>

                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active"
                      onClick={() => {
                        setSearchText("");
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search by User name/Email"
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <DataTableBody>
              <DataTableHead>

                <DataTableRow size="mb">
                  <span className="sub-text">Subscription</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text text-left">User</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text text-center">Price</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text text-center">Payment Status</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text text-center">Start Date</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text text-center">End Date</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text text-center">Created Date</span>
                </DataTableRow>

                <DataTableRow size='mb' className="nk-tb-col-tools text-right">
                  <span className="sub-text text-right">More</span>
                </DataTableRow>
              </DataTableHead>

              {data.length > 0
                ? data.map((item, indexm) => {
                  return (
                    <DataTableItem key={item?._id}>

                      <DataTableRow size="mb">
                        <span
                          className={`text-center`} style={{ textTransform: 'capitalize' }}
                        >{
                            item?.plan?.translations?.length ?
                              item?.plan?.translations?.map((n, i) =>
                                i === 0 ?
                                  n?.planTranslation?.label ? n?.planTranslation?.label : "NA"
                                  : ""
                              )
                              : "NA"

                          }   <br /><small className={item?.active ? "text-success" : "text-danger"} style={{ fontSize: "11px", background: item?.active ? '#1ee0ac3b' : '#e8534761', padding: '2px 5px' }}>{item?.active ? "Active" : "Expired"}</small></span>

                      </DataTableRow>

                      <DataTableRow size="lg" className="text-left">

                        <div className="user-card">
                          <UserAvatar size="md" theme="light" text={(item?.user?.name) ? (item?.user?.name)[0] : ""}></UserAvatar>

                          <div className="user-info">
                            <span className="tb-lead">
                              {item?.user?.name}
                            </span>
                            {item?.user?.email ? <span className="currency">{item?.user?.email}</span> : "NA"}

                          </div>
                        </div>
                      </DataTableRow>

                      <DataTableRow size="lg">
                        <span className="sub-text text-center">{item?.plan?.price ? item.plan?.price : 0} SEK</span>
                      </DataTableRow>
                      <DataTableRow size="lg" className="text-center">
                        <span
                          className={`text-center text-${item.payment?.status === 'accepted' ?
                            "success"
                            : "info"}`}
                          style={{ textTransform: 'capitalize' }}
                        >{item.payment?.status === 'accepted' ? "Success" : item.payment?.status ? item.payment?.status : "NA"}</span>
                      </DataTableRow>



                      <DataTableRow className="text-center" size="lg">
                        <span className="sub-text">
                          <span
                            className={`text-center`}
                          >{item?.startDate ? moment(item?.startDate).format("YYYY-MM-DD HH:mm A") : "NA"}</span>
                        </span>
                      </DataTableRow>

                      <DataTableRow className="text-center" size="lg">
                        <span className="sub-text">
                          <span
                            className={`text-center`}
                          >{item?.endDate ? moment(item?.endDate).format("YYYY-MM-DD HH:mm A") : "NA"}</span>
                        </span>
                      </DataTableRow>

                      <DataTableRow className="text-center" size="md">
                        <span>{item.createdAt ? moment(item?.createdAt).format("YYYY-MM-DD, HH:mm A") : "NA"}</span>
                      </DataTableRow>

                      <DataTableRow size='mb' className="nk-tb-col-tools">
                        <ul className="nk-tb-actions gx-1">

                          <li>
                            <UncontrolledDropdown>
                              <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                <Icon name="more-h"></Icon>
                              </DropdownToggle>
                              <DropdownMenu right>
                                <ul className="link-list-opt no-bdr">
                                  <li onClick={async() => await downloadInvoice(item)}>
                                    <DropdownItem
                                      tag="a"
                                      href="#suspend"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      <Icon name="download"></Icon>
                                      <span>Download Invoice</span>
                                    </DropdownItem>
                                  </li>


                                  <li onClick={() => deleteUser(item?._id ? item?._id : "", indexm)}>
                                    <DropdownItem
                                      tag="a"
                                      href="#suspend"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      <Icon name="delete"></Icon>
                                      <span>Delete</span>
                                    </DropdownItem>
                                  </li>

                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </li>
                        </ul>
                      </DataTableRow>
                    </DataTableItem>
                  );
                })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {totals > 0 ? (
                <PaginationComponent
                  itemPerPage={limit}
                  totalItems={totals}
                  paginate={async (n) => await paginate(n)}
                  currentPage={page}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>

      </Content>
    </React.Fragment>
  );
};
export default SubscriptionListRegular;

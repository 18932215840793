import moment from "moment"
const invoice = (data) => {
    return (`
<!DOCTYPE html>
<html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
	xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
	<meta charset="utf-8">
	<meta name="viewport" content="width=device-width">
	<meta http-equiv="X-UA-Compatible" content="IE=edge">
	<meta name="x-apple-disable-message-reformatting">
	<title>Korteori</title>
	<link rel="preconnect" href="https://fonts.googleapis.com">
	<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
	<link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,500;0,600;1,400;1,500&display=swap"
		rel="stylesheet">
	<style>
		html,
		body {
			margin: 0 auto !important;
			padding: 0 !important;
			height: 100% !important;
			width: 100% !important;
			background: #f1f1f1;
		}

		* {
			-ms-text-size-adjust: 100%;
			-webkit-text-size-adjust: 100%;
		}

		div[style*="margin: 16px 0"] {
			margin: 0 !important;
		}

        /* .box-Tbody{
            border-left: 80px solid transparent;
            border-right: 80px solid transparent;
        } */

        .boxFlex{
            display: flex;
            align-items: center;
            padding-top: 10px;
        }
        .boxFlex2{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-top: 10px;
        }

        /* What it does: Stops Outlook from adding extra spacing to tables. */
		table,
		td {
			mso-table-lspace: 0pt !important;
			mso-table-rspace: 0pt !important;
		}

		/* What it does: Fixes webkit padding issue. */
		table {
			border-spacing: 0 !important;
			border-collapse: collapse !important;
			table-layout: fixed !important;
			margin: 0 auto !important;
		}

		/* What it does: Prevents Windows 10 Mail from underlining links despite inline CSS. Styles for underlined links should be inline. */
		a {
			text-decoration: none;
		}

        @media screen and (max-width: 767px) {
            .boxFlex{
                flex-direction: column;
                align-items: flex-start!important;
            }
            .boxFlex2{
                flex-direction: column;
                align-items: flex-end!important;               
            }
            .boxFlex2  p{
                display: flex!important;
                flex-direction: column;
            }
        }

        /* @media screen and (max-width: 575px) {
            .box-Tbody{
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
            }
        } */

        @media screen and (max-width: 425px) {
            .lastBox{
                display: flex;
                flex-direction: column;
                width: 100%;
            }
            .last2{
                text-align: left!important;
            }
        }

		/* What it does: Removes right gutter in Gmail iOS app: https://github.com/TedGoas/Cerberus/issues/89  */
		/* Create one of these media queries for each additional viewport size you'd like to fix */

		/* iPhone 4, 4S, 5, 5S, 5C, and 5SE */
		@media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
			u~div .email-container {
				min-width: 100% !important;
			}
		}

		/* iPhone 6, 6S, 7, 8, and X */
		@media only screen and (min-device-width: 375px) and (max-device-width: 413px) {
			u~div .email-container {
				min-width: 100% !important;
			}
		}

		/* iPhone 6+, 7+, and 8+ */
		@media only screen and (min-device-width: 414px) {
			u~div .email-container {
				min-width: 100% !important;
			}
		}
        @media screen and (max-device-width: 1078px) {
			.screen1 {
				max-width: 100% !important;
			}
		}

		@media screen and (max-device-width: 768px) {
			.screen1 {
				max-width: 100% !important;
			}
		}

        @media screen and (max-device-width: 466px) {
			.screen1 {
				max-width: 100% !important;
			}
		}

	</style>

	<!-- CSS Reset : END -->

	<!-- Progressive Enhancements : BEGIN -->
	<style>
		.primary {
			background: #f1c638;
		}

		.bg_white {
			background: #ffffff;
		}

		.bg_light {
			background: #fff;
		}

		.bg_black {
			background: #000000;
		}

		.bg_dark {
			background: rgba(0, 0, 0, .8);
		}

		.email-section {
			padding: 2.5em;
		}

		/*BUTTON*/
		.btn {
			padding: 5px 15px;
			display: inline-block;
		}

		.btn.btn-primary {
			border-radius: 5px;
			background: #f1c638;
			color: #000;
		}

		.btn.btn-white {
			border-radius: 5px;
			background: #ffffff;
			color: #000000;
		}

		.btn.btn-white-outline {
			border-radius: 5px;
			background: transparent;
			border: 1px solid #fff;
			color: #fff;
		}

		.btn.btn-black {
			border-radius: 5px;
			background: #000;
			color: #fff;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-family: 'Nunito Sans', sans-serif;
			color: #000000;
			margin-top: 0;
		}

		body {
			font-family: 'Nunito Sans', sans-serif;
			font-weight: 400;
			font-size: 15px;
			line-height: normal;
			color: rgba(0, 0, 0, .4);
		}

		a {
			color: #f1c638;
		}
	</style>


</head>

<body width="100%" style="margin: 0; padding: 0 !important; mso-line-height-rule: exactly; background-color: #fff;">
	<center style="width: 100%; background-color: #fff">
		<div class='screen1' style="max-width: 100%; margin: 0 auto;" class="email-container">
			<table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%"
				style="margin: auto;padding:0px;border-bottom: 30px solid transparent;">
				<tbody class="box-Tbody">
                    <tr>
                        <td valign="top" class="bg_white" style="padding-top: 0px;padding-bottom: 0px;">
                            <table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
                                <tr>
                                    <td style="
                                        border-left: 20px solid #fff;
                                        display: block;
                                        border-top: 30px solid #fff;
                                        color: #000000;
                                        font-size: 18px;
                                        font-weight: 600;
                                        text-align: center;
                                        line-height: normal;
                                        background: #fff!important;
                                        ">
                                        Volkommen till
                                    </td>
                                </tr>
                                <tr>
                                    <td style="
                                        border-left: 35px solid #fff;
                                        display: block;
                                        border-top: 5px solid #fff;
                                        border-bottom: 20px solid #fff;
                                        color: #000000;
                                        font-size: 16px;
                                        font-weight: 600;
                                        text-align: center;
                                        line-height: normal;
                                        background: #fff!important;
                                        ">
                                        <i>Korteori</i>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td style="padding-top: 0px;padding-bottom: 0px;background-color:transparent;">
                            <table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%" 
                                style="border-top: 15px solid transparent;
                                    border-bottom: 15px solid transparent;
                                    border-left: 15px solid transparent;
                                    border-right: 15px solid transparent;
                                    border-radius: 10px;
                                    background-color: #f7f9fa;">
                                <tr>
                                    <td valign="middle" colspan="1"
                                        style="padding: 1px;padding-bottom: 0px;padding-top: 0px;">
                                        <p style="
                                            display: block;
                                            color: #000000;
                                            font-size: 11px;
                                            font-weight: 600;
                                            line-height: normal;
                                            background: transparent!important;
                                            margin: 0;">
                                            ${data?.user?.name ? data?.user?.name : ""}
                                            <br/>
                                            ${data?.user?.email ? data?.user?.email : ""}
                                        </p>
                                        <div class="boxFlex">
                                            <p style="
                                            display: block;
                                            color: #757a73;
                                            font-size: 13px;
                                            font-weight: 450;
                                            line-height: normal;
                                            background: transparent!important;
                                            margin: 0;width: 50%;">
                                            Plan
                                            </p>
                                            <p style="
                                                display: block;
                                                color: #000000;
                                                font-size: 11px;
                                                font-weight: 450;
                                                line-height: normal;
                                                background: transparent!important;
                                                margin: 0;width: 50%;">
                                                ${data?.plan?.translations?.length ?
            data?.plan?.translations?.map((n, i) =>
                i === 0 ?
                    n?.planTranslation?.label ? n?.planTranslation?.label : "NA"
                    : ""
            )
            : "NA"
        }
                                            </p>  
                                        </div>
                                        <div class="boxFlex">
                                            <p style="
                                            display: block;
                                            color: #757a73;
                                            font-size: 13px;
                                            font-weight: 450;
                                            line-height: normal;
                                            background: transparent!important;
                                            margin: 0;width: 50%;">
                                            Purchased
                                            </p>
                                            <p style="
                                                display: block;
                                                color: #000000;
                                                font-size: 11px;
                                                font-weight: 450;
                                                line-height: normal;
                                                background: transparent!important;
                                                margin: 0;width: 50%;">
                                                ${moment(data?.createdAt).format("YYYY-MM-DD HH:mm A")}
                                            </p>  
                                        </div>
                                    </td>
                                    <td valign="middle" colspan="1"
                                        style="padding: 5px;padding-bottom: 0px;padding-top: 0px;text-align: end;">
                                        <p style="
                                            display: block;
                                            color: #000000;
                                            font-size: 13px;
                                            font-weight: 600;
                                            line-height: normal;
                                            background: transparent!important;
                                            margin: 0;">
                                            <i>Korteori</i>
                                        </p>
                                        <div class="boxFlex2">
                                            <p style="
                                            display: block;
                                            color: #757a73;
                                            font-size: 12px;
                                            font-weight: 450;
                                            line-height: normal;
                                            background: transparent!important;
                                            margin: 0;">
                                            Belalsatt:<p style="color: #000;font-size:9px">Swish</p>
                                            </p>  
                                        </div>
                                        <div class="boxFlex2">
                                            <p style="
                                            display: block;
                                            color: #757a73;
                                            font-size: 12px;
                                            font-weight: 450;
                                            line-height: normal;
                                            background: transparent!important;
                                            margin: 0;">
                                            Betanlningsref nr:<p style="color: #000;font-size:9px">#${data?.payment?.orderId ? (data?.payment?.orderId).substr(((data?.payment?.orderId)?.length - 4), (data?.payment?.orderId).length) : ""}</p>
                                            </p>  
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr style="border-top: 20px solid transparent">
                        <td style="padding-top: 0px;padding-bottom: 0px;background-color:transparent;">
                            <table class="table-responsive" role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%"
                                    style="border-top: 15px solid transparent;
                                        border-bottom: 15px solid transparent;
                                        border-left: 15px solid transparent;
                                        border-right: 15px solid transparent;
                                        border-radius: 10px;
                                        background-color: #f8ede8;">
                                <thead>
                                    <th colspan="1" width="50%" style="color: #757a73;
                                                font-size: 13px;
                                                font-weight: 400;
                                                line-height: normal;
                                                background: transparent!important;
                                                margin: 0;text-align: left;">
                                        Description
                                    </th>
                                    <th colspan="1" width="50%" style="color: #757a73;
                                            font-size: 13px;
                                            font-weight: 400;
                                            line-height: normal;
                                            background: transparent!important;
                                            margin: 0;text-align: center;">
                                        Quantity
                                    </th>
                                    <th colspan="1" width="50%" style="color: #757a73;
                                            font-size: 13px;
                                            font-weight: 400;
                                            line-height: normal;
                                            background: transparent!important;
                                            margin: 0;text-align: right;">
                                        Total
                                    </th>
                                </thead>
                                <tbody>
                                    <tr style="border-bottom: 1px solid #c8c5c6;">
                                        <td  colspan="1" width="50%" valign="middle" style="color: #393e43;
                                                font-size: 11px;
                                                font-weight: 600;
                                                text-align: left;
                                                padding-top: 10px;
                                                padding-bottom: 5px;">
                                            Korteori ${data?.plan?.translations?.length ?
            data?.plan?.translations?.map((n, i) =>
                i === 0 ?
                    n?.planTranslation?.label ? n?.planTranslation?.label : "NA"
                    : ""
            )
            : "NA"
        }
                                        </td>
                                        <td  colspan="1" width="50%" valign="middle" style="color: #393e43;
                                            font-size: 11px;
                                            font-weight: 600;
                                            text-align: center;
                                            padding-top: 10px;
                                            padding-bottom: 5px;">
                                            1
                                        </td>
                                        <td  colspan="1" width="50%" valign="middle" style="color: #393e43;
                                            font-size: 11px;
                                            font-weight: 600;
                                            text-align: right;
                                            padding-top: 10px;
                                            padding-bottom: 5px;">
                                             ${data?.plan?.price ? data?.plan?.price : 0} SEK
                                        </td>
                                    </tr>
                                    <tr style="border-bottom: 1px solid #c8c5c6;">
                                        <td valign="middle" colspan="2" style="color: #393e43;
                                                font-size: 13px;
                                                font-weight: 600;
                                                text-align: left;
                                                padding-top: 10px;
                                                padding-bottom: 50px;">
                                            Summa(inkl Moms 25%)
                                        </td>
                                        <td valign="middle"colspan="1" style="color: #393e43;
                                            font-size: 12px;
                                            font-weight: 600;
                                            text-align: right;
                                            padding-top: 10px;
                                            padding-bottom: 50px;">
                                            ${data?.plan?.price ? data?.plan?.price : 0} SEK
                                        </td>
                                    </tr>
                                    <tr class="lastBox">
                                        <td valign="middle" colspan="2" style="text-align: left;
                                                padding-top: 10px;">
                                            <p style="color: #393e43;
                                                font-size: 13px;
                                                font-weight: 600;margin: 0px;"><i>Korteori</i></p>
                                            <p style="color: #393e43;
                                                font-size: 11px;
                                                font-weight: 400;margin: 0px;">
                                                Murgronsgatan 4 554 50 Jonkoping
                                            </p>
                                            <p style="color: #393e43;
                                            font-size: 11px;
                                            font-weight: 400;margin: 0px;">
                                                Org: 559114-7433
                                            </p>
                                            <p style="color: #393e43;
                                            font-size: 11px;
                                            font-weight: 400;margin: 0px;">
                                                F Skatt: SE5599114743301
                                            </p>
                                        </td>
                                        <td class="last2" valign="middle" colspan="1" style="color: #393e43;
                                            font-size: 13px;
                                            font-weight: 600;
                                            text-align: right;
                                            padding-top: 10px;">
                                            <p style="color: #393e43;
                                            font-size: 13px;
                                            font-weight: 400;margin: 0px;"><i>Kontakt@korteori.se</i></p>
                                             <p style="color: #393e43;
                                             font-size: 11px;
                                             font-weight: 400;margin: 0px;"><i>0723 200 107</i></p>
                                              <p style="color: #393e43;
                                              font-size: 11px;
                                              font-weight: 400;margin: 0px;"><i>Allmannavillkor</i></p>
                                        </td>                                        
                                    </tr>
                                </tbody>
                            </table>                 
                        </td>
                    </tr>
                </tbody>
			</table>
		</div>
	</center>
</body>

</html>
`)
};

export {invoice}


import React, { useContext, useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import toast from 'react-hot-toast';
import {
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  DropdownItem,
  Form,
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  UserAvatar,
  PaginationComponent,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
} from "../../../components/Component";
import { useForm } from "react-hook-form";
import { deleteMethod, getMethod, putMethod, setAuthorizationToken } from "../../../utils/apiServices";
import moment from "moment";

const UserListRegularPage = () => {
  const [data, setData] = useState([]);
  const [totals, setTotals] = useState(0);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(false);
  const [onSearchText, setSearchText] = useState("");
  const [roleProvider, setRoleProvider] = useState("user");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [startDate, setStartDate] = useState(moment().startOf("month").format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [paramsHistory, setParamsHistory] = useState({});
  const toggle = () => setonSearch(!onSearch);
  const [editId, setEditedId] = useState("");


  const [formData, setFormData] = useState({
    name: "",
    email: "",
    provider: "",
    role: "",
    isVerified: "",
  });


  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });


  const resetForm = () => {
    setEditedId("");
    setFormData({
      name: "",
      email: "",
      role: "",
      provider: "",
      isVerified: "",
    });
  };


  const onFormCancel = () => {
    setModal({ edit: false, add: false });
    resetForm();
  };


  const paginate = async (pageNumber) => {
    setPage(pageNumber);
    var updateParams = paramsHistory;
    updateParams = Object.assign(updateParams, { page: pageNumber });
    await fetchUserList(updateParams);
  };


  const onFilterChange = async (e) => {
    if (e?.target.value) {
      setSearchText(e.target.value);
      await fetchUserList({ search: (e.target.value).toLowerCase(), page: page, limit: limit, roleProvider: roleProvider })
    } else {
      setSearchText("");
      await fetchUserList({ page: page, limit: limit, roleProvider: roleProvider });
    }
  };


  const deleteUser = async (id, index) => {
    await deleteMethod('admin/users/' + id + '/delete').then((response) => {
      if (response?.success) {
        toast.success(response.message)
        data.splice(index, 1);
        setData([...data])
      } else {
        toast.error(response.message)
      }
    })
  }


  const fetchUserList = async (params) => {
    setParamsHistory(params);

    setData([]);
    await getMethod('admin/users', params).then((response) => {
      if (response?.success) {
        setTotals(response?.total ? response?.total : 0)
        setData(response.data);
      }
    })
  }


  const onEditSubmit = async (submitData) => {
    if (!editId) {
      toast.error("Edit Id required!");
    }
    if (submitData) {
      await putMethod('admin/users/' + editId + '/edit', submitData).then(async (response) => {
        if (response?.success) {
          setModal({ edit: false, add: false });
          resetForm();
          toast.success(response.message);
          await fetchUserList(paramsHistory);
        } else {
          toast.error(response.message)
        }
      })
    } else {
      toast.error("Required Feild missing!")
    }
  };


  useEffect(() => {
    setAuthorizationToken(true)
    fetchUserList({ page: page, limit: limit, roleProvider: roleProvider });
  }, [page, limit, roleProvider]);

  const { errors, register, handleSubmit } = useForm();

  return (
    <React.Fragment>
      <Head title="User List - Regular"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Users Lists
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {totals} users.</p>
              </BlockDes>
            </BlockHeadContent>
            {/* <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                     <li>   later
                      <Button color="light" outline className="btn-white">
                        <Icon name="download-cloud"></Icon>
                        <span>Export</span>
                      </Button>
                    </li>
                    <li className="nk-block-tools-opt">
                      <Button color="primary" className="btn-icon" onClick={() => setModal({ add: true })}>
                        <Icon name="plus"></Icon>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent> */}
          </BlockBetween>
        </BlockHead>

        <Block>
          <DataTable className="card-stretch">
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
                <div className="card-tools">

                </div>
                <div className="card-tools mr-n1">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <li>
                      <div className="toggle-wrap">
                        <Button
                          className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                          onClick={() => updateTableSm(true)}
                        >
                          <Icon name="menu-right"></Icon>
                        </Button>
                        <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                          <ul className="btn-toolbar gx-1">
                            <li className="toggle-close">
                              <Button className="btn-icon btn-trigger toggle" onClick={() => updateTableSm(false)}>
                                <Icon name="arrow-left"></Icon>
                              </Button>
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <div className="dot dot-primary"></div>
                                  <Icon name="filter-alt"></Icon>
                                </DropdownToggle>
                                <DropdownMenu
                                  right
                                  className="filter-wg dropdown-menu-xl"
                                  style={{ overflow: "visible" }}
                                >
                                  <div className="dropdown-head">
                                    <span className="sub-title dropdown-title">Filter</span>
                                    <div className="dropdown">
                                      <a
                                        href="#more"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                        className="btn btn-sm btn-icon"
                                      >
                                        <Icon name="more-h"></Icon>
                                      </a>
                                    </div>
                                  </div>
                                  <div className="dropdown-body dropdown-body-rg">
                                    <Row className="gx-6 gy-3">
                                      <Col size="6">
                                        <FormGroup>
                                          <label className="overline-title overline-title-alt">Start Date</label>
                                          <input type='date' value={startDate} onChange={(e) => { setStartDate(e.target.value) }} placeholder="DD/MM/YYYY" />
                                        </FormGroup>
                                      </Col>
                                      <Col size="6">
                                        <FormGroup>
                                          <label className="overline-title overline-title-alt">End Date</label>
                                          <input type='date' value={endDate} onChange={(e) => { setEndDate(e.target.value) }} placeholder="DD/MM/YYYY" />
                                        </FormGroup>
                                      </Col>
                                      <Col size="12">
                                        <FormGroup className="form-group" style={{ justifyContent: 'space-between', alignItems: 'center', display: 'flex' }}>
                                          <button type="button" onClick={async (e) => {
                                            await fetchUserList({ startDate: startDate, endDate: endDate, page: page, limit: limit });
                                          }} className="btn btn-secondary">
                                            Filter
                                          </button>

                                          <a
                                            href="#reset"
                                            onClick={async (ev) => {
                                              ev.preventDefault();
                                              setSearchText("");
                                              setRoleProvider("");
                                              setLimit(10);
                                              setPage(1);
                                              setStartDate("");
                                              setEndDate("");
                                              await fetchUserList({ search: "", page: 1, limit: 10, roleProvider: "", startDate: "", endDate: "" });
                                            }}
                                            className="clickable"
                                          >
                                            Reset Filter
                                          </a>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </div>

                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle color="tranparent" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <Icon name="setting"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right className="dropdown-menu-xs">
                                  <ul className="link-check">
                                    <li>
                                      <span>Show</span>
                                    </li>
                                    {[10, 50, 100, 500, 1000]?.map((n, index) =>
                                      <li key={index} className={limit === n ? "active" : ""}>
                                        <DropdownItem
                                          tag="a"
                                          href="#dropdownitem"
                                          onClick={async (ev) => {
                                            ev.preventDefault();
                                            setLimit(n);
                                            await fetchUserList({ page: page, limit: n, roleProvider: roleProvider })
                                          }}
                                        >
                                          {n}
                                        </DropdownItem>
                                      </li>
                                    )}
                                  </ul>
                                  <ul className="link-check">
                                    <li>
                                      <span>Role & Provider</span>
                                    </li>
                                    {
                                      ['user', 'admin', 'email', 'facebook', 'google']?.map((n, index) =>

                                        <li key={index} className={roleProvider === n ? "active" : ""} style={{ textTransform: 'capitalize' }}>
                                          <DropdownItem
                                            tag="a"
                                            href="#dropdownitem"
                                            onClick={async (ev) => {
                                              ev.preventDefault();
                                              setRoleProvider(n)
                                              await fetchUserList({ page: page, limit: limit, roleProvider: n })
                                            }}
                                          >
                                            {n}
                                          </DropdownItem>
                                        </li>
                                      )
                                    }

                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active"
                      onClick={() => {
                        setSearchText("");
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search by user or email"
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <DataTableBody>
              <DataTableHead>

                <DataTableRow size="mb" className="nk-tb-col-check">
                  <span className="sub-text">Name</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text text-center">Role</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text text-center">Verified</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text text-center">Provider</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text text-center">Subscription</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text text-center">Created Date</span>
                </DataTableRow>

                <DataTableRow size='mb' className="nk-tb-col-tools text-right">
                  <span className="sub-text text-right">More</span>
                </DataTableRow>
              </DataTableHead>

              {data.length > 0
                ? data.map((item, indexm) => {
                  return (
                    <DataTableItem key={item?._id}>

                      <DataTableRow size="mb" className="nk-tb-col-check">

                        <div className="user-card">
                          <UserAvatar size="md" theme="light" text={(item?.name) ? (item?.name)[0] : ""}></UserAvatar>

                          <div className="user-info">
                            <span className="tb-lead">
                              {item.name}
                            </span>
                            {item.email ? <span className="currency">{item?.email}</span> : "NA"}

                          </div>
                        </div>
                      </DataTableRow>

                      <DataTableRow size="lg" className="text-center">
                        <span
                          className={`text-center`} style={{ textTransform: 'capitalize' }}
                        >{item?.role ? item?.role : "NA"}</span>
                      </DataTableRow>
                      <DataTableRow size="lg" className="text-center">
                        <span
                          className={`text-center text-${item.isVerifed ?
                            "success"
                            : "info"

                            }`}
                        >{item.isVerifed ? "YES" : "NO"}</span>
                      </DataTableRow>
                      <DataTableRow className="text-center text-info" size="lg">
                        <span style={{ textTransform: 'capitalize' }}>{item?.provider ? item?.provider : "NA"}</span>
                      </DataTableRow>
                      <DataTableRow className="text-center" size="md">
                        <span className="sub-text">
                          <span
                            className={`text-center text-${item.currentSubscription ?
                              "success"
                              : "secondary"

                              }`}
                          >{item?.currentSubscription ? "Active" : "Not Buy"}</span>
                        </span>
                      </DataTableRow>
                      <DataTableRow className="text-center" size="md">
                        <span>{item.createdAt ? moment(item?.createdAt).format("YYYY-MM-DD, HH:mm A") : "NA"}</span>
                      </DataTableRow>

                      <DataTableRow size='mb' className="nk-tb-col-tools">
                        <ul className="nk-tb-actions gx-1">

                          <li>
                            <UncontrolledDropdown>
                              <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                <Icon name="more-h"></Icon>
                              </DropdownToggle>
                              <DropdownMenu right>
                                <ul className="link-list-opt no-bdr">
                                  <li onClick={() => { setEditedId(item?._id ? item?._id : ""); setFormData({ name: item?.name ? item?.name : "", email: item?.email ? item?.email : "", role: item?.role ? item?.role : "", isVerified: item?.isVerifed ? item?.isVerifed : false, provider: item?.provider ? item?.provider : "emai" }); setModal({ edit: true }) }}>
                                    <DropdownItem
                                      tag="a"
                                      href="#edit"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      <Icon name="edit"></Icon>
                                      <span>Edit</span>
                                    </DropdownItem>
                                  </li>

                                  <li onClick={() => deleteUser(item?._id ? item?._id : "", indexm)}>
                                    <DropdownItem
                                      tag="a"
                                      href="#suspend"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      <Icon name="delete"></Icon>
                                      <span>Delete</span>
                                    </DropdownItem>
                                  </li>


                                  <li>
                                    <DropdownItem
                                      tag="a"
                                      href={"/paid-tests?user="+item?._id}
                                      
                                    >
                                      <Icon name="eye"></Icon>
                                      <span>View Paid Test</span>
                                    </DropdownItem>
                                  </li>
                                  <li>
                                    <DropdownItem
                                      tag="a"
                                      href={"/subscriptions?user="+item?._id}
                                      
                                    >
                                      <Icon name="list"></Icon>
                                      <span>View Subscriptions</span>
                                    </DropdownItem>
                                  </li>

                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </li>
                        </ul>
                      </DataTableRow>
                    </DataTableItem>
                  );
                })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {totals > 0 ? (
                <PaginationComponent
                  itemPerPage={limit}
                  totalItems={totals}
                  paginate={async (n) => await paginate(n)}
                  currentPage={page}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>




        <Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Update User</h5>
              <div className="mt-4">
                <Form className="row gy-4" onSubmit={handleSubmit(onEditSubmit)}>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        defaultValue={formData.name}
                        placeholder="Enter name"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Email</label>
                      <input
                        className="form-control"
                        type="text"
                        name="email"
                        defaultValue={formData.email}
                        placeholder="Enter email"
                        ref={register({
                          required: "This field is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address",
                          },
                        })}
                      />
                      {errors.email && <span className="invalid">{errors.email.message}</span>}
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Role</label>
                      <div className="form-control-wrap">
                        <select className="form-control" value={formData?.role} name='role' required onChange={(e) => setFormData({ ...formData, role: e.target.value })}
                          ref={register({
                            required: "This field is required",
                          })}

                        >
                          <option value="">Select</option>
                          {
                            [{ value: 'user', label: 'User' }, { value: 'admin', label: 'Admin' }]?.map((n, i) =>
                              <option key={i} value={n?.value}>{n?.label}</option>
                            )
                          }
                        </select>

                      </div>
                      {errors.role && <span className="invalid">{errors.role.message}</span>}
                    </FormGroup>
                  </Col>


                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Provider</label>
                      <div className="form-control-wrap">
                        <select className="form-control" value={formData?.provider} name='provider' required onChange={(e) => setFormData({ ...formData, provider: e.target.value })}
                          ref={register({
                            required: "This field is required",
                          })}
                        >
                          <option value="">Select</option>
                          {
                            [{ value: 'email', label: 'Email' }, { value: 'facebook', label: 'Facebook' }, { value: "google", label: "Google" }]?.map((n, i) =>
                              <option key={i} value={n?.value}>{n?.label}</option>
                            )
                          }
                        </select>
                      </div>
                      {errors.provider && <span className="invalid">{errors.provider.message}</span>}
                    </FormGroup>
                  </Col>


                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Verified</label>
                      <div className="form-control-wrap">

                        <select className="form-control" value={formData?.isVerified} name='isVerified' required onChange={(e) => setFormData({ ...formData, isVerified: e.target.value })}
                          ref={register({
                            required: "This field is required",
                          })}
                        >
                          <option value="">Select</option>
                          {
                            [{ value: true, label: 'Yes' }, { value: false, label: 'No' }]?.map((n, i) =>
                              <option key={i} value={n?.value}>{n?.label}</option>
                            )
                          }
                        </select>



                      </div>
                      {errors.isVerifed && <span className="invalid">{errors.isVerifed.message}</span>}
                    </FormGroup>
                  </Col>

                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                          Update User
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};
export default UserListRegularPage;

import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { toast } from 'react-hot-toast';
import { uploadDataAWS } from '../../utils/action';
const FullTextEditor = ({ editorValue, onChangeValue }) => {
    const editorRef = useRef(null);


    return (

        <Editor
            className='editorStyle'
            outputFormat='text'
            onInit={(evt, editor) => editorRef.current = editor}
            onEditorChange={(evt, editor) => {
                onChangeValue(editorRef.current.getContent());
            }}
            value={editorValue}
            apiKey={"h6jsequkdff8uz6fajsbo7c5x9byndnxychrjml77fd88bvp"}
            init={
                {
                    menubar: false,
                    branding: false,
                    draggable_modal: false,
                    statusbar: false,
                    toolbar_sticky: false,
                    paste_as_text: false,
                    statusbar: false,
                    image_title: true,
                    insertdatetime_element: false,
                    nonbreaking_force_tab: false,
                    automatic_uploads: true,
                    relative_urls: false,
                    visualchars_default_state: false,
                    width: '100%',
                    height: '300px',
                    skin: "naked",
                    icons: "thin",
                    selector: "textarea",
                    resize: 'both',
                    theme: 'silver',
                    toolbar_mode: 'wrap',
                    toolbar_location: 'bottom',
                    placeholder: 'Write...',
                    file_browser_callback_types: 'image media',
                    content_css: ["https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap"],
                    content_style: "*{font-family:Inter}",
                    plugins: [
                        'visualchars searchreplace insertdatetime nonbreaking table code advlist anchor autolink image imagetools lists link media preview searchreplace wordcount emoticons hr charmap preview'
                    ],
                    toolbar: 'formatselect fontselect fontsizeselect | bold italic underline strikethrough | superscript subscript forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | link image blockquote preview',
                    fontsize_formats: '8px 10px 12px 14px 16px 18px',
                    // lineheight_formats: '0.5 0.7 0.8 0.9 1 1.1 1.2 1.3 1.4 1.5 2',
                    block_formats: 'Paragraph=p;Header 1=h1;Header 2=h2;Header 3=h3;',
                    font_formats: 'Arial Black=arial black,avant garde;Inter=Inter;',


                    file_picker_callback: function (cb, value, meta) {
                        var input = document.createElement('input');
                        input.setAttribute('type', 'file');
                        input.setAttribute('accept', 'image/*');
                        input.onchange = function () {
                            var file = this.files[0];
                            var reader = new FileReader();
                            reader.onload = async function () {
                                var id = 'blobid' + (new Date()).getTime();
                                var blobCache = tinymce.activeEditor.editorUpload.blobCache;
                                var base64 = reader.result.split(',')[1];
                                var blobInfo = blobCache.create(id, file, base64);
                                blobCache.add(blobInfo);
                                const formData = new FormData();
                                formData.append('file', file);

                                await uploadDataAWS(formData).then(response => {
                                    if (response.success) {
                                        cb(process.env.REACT_APP_IMG_URL + response?.path, { title: response?.path.split('/')[(response?.path.split('/').length - 1)] });
                                        
                                    } else {
                                        toast.error(response?.message)
                                    }
                                })
                            };
                            reader.readAsDataURL(file);
                        };
                        input.click();
                    },
                    images_upload_handler: async function (blobInfo, success, failure) {
                        const formData = new FormData();
                        formData.append('file', blobInfo.blob());

                        await uploadDataAWS(formData).then(response => {
                            if (response.success) {
                                success(process.env.REACT_APP_IMG_URL + response.path)
                                
                            } else {
                                toast.error(response.message);
                            }
                        })
                    }
                }}
        />
    )
}

export { FullTextEditor }
